import React, { FC, useState, useRef, useEffect } from 'react';
import { SitesData, Userdata } from 'src/models/crypto_order';
import { useNavigate } from 'react-router';
import axios from 'axios';
interface Filters {
  isAdmin?: boolean | 'all';
}

const applyFilters = (data: SitesData[], filters: Filters): SitesData[] => {
  return data?.filter((user) => {
    let matches = true;
    return matches;
  });
};

const applyPagination = (data: SitesData[], page: number, limit: number): SitesData[] => {
  return data?.slice(page * limit, page * limit + limit);
};

const Common_Fun = (WrappedComponent: FC<any>, getApiData: string,addDataRoute: string,updateDataRoute:string,headkey,coloumnKey,slug) => {
  const EnhancedComponent= (props) => {
    const [openDialogue, setopenDialogue] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<string[]>([]);
    const [data, setData] = useState<SitesData[]>([]);
    const token = localStorage.getItem('token') || '';
    const selectedBulkActions = selected.length > 0;
    const [page, setPage] = useState<number>(0);
    const auth = useRef(false);
    const [deleteId, setDeleteId] = useState<string>('');
    const [answer, setAnswer] = useState<boolean>(false);
    const [limit, setLimit] = useState<number>(5);
    const [filters, setFilters] = useState<Filters>({ isAdmin: null });
    const selectedSomeData = selected.length > 0 && selected.length < data.length;
    const selectedAllData = selected?.length === data?.length;
    const navigate = useNavigate();

    const getAllData = async () => {
      setLoading(true);
      await axios.get(`${getApiData}`, {
        headers: {
          accept: '*/*',
          'Auth-Token': `${token}`,
        },
      }).then((response)=>{
        const data=response.data.data;
        if(slug == 'site'){
          setData(data);
        }else{
          const filteredData = data.data.filter((item)=>item.is_admin == 0)
          setData(filteredData)
        }
        setIsAuthenticated(false);
      }).catch((error)=>{
        setIsAuthenticated(true);
        auth.current = true;
      })
      setLoading(false);
    };
    
    useEffect(() => {
      getAllData();
    }, [answer]);

    const handleSelectAllData = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSelected(event.target.checked ? data.map((user) => user._id) : []);
    };

    const handleSelectOneData = (event: React.ChangeEvent<HTMLInputElement>, userId: string): void => {
      if (!selected.includes(userId)) {
        setSelected((prevSelected) => [
          ...prevSelected,
          userId,
        ]);
      } else {
        setSelected((prevSelected) =>
          prevSelected.filter((id) => id !== userId),
        );
      }
    };

    const handlePageChange = (event: any, newPage: number): void => {
      setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setLimit(parseInt(event.target.value));
    };

    const createData = async () => {
      await getAllData();
      if (auth.current === true) {
        setOpen(true);
      } else {
        navigate(`${addDataRoute}`);
      }
    };

    const deleteData = async (id: string) => {
      await getAllData();
      if (auth.current === true) {
        setOpen(true);
      } else {
        setDeleteId(id);
        setopenDialogue(true);
      }
    };

    const updateData = async (data: SitesData) => {
      await getAllData();
      if (auth.current === true) {
        setOpen(true)
      } else {
        navigate(`${updateDataRoute}`, { state: data });
      }
    };

    useEffect(() => {
      if (isAuthenticated) {
        setOpen(true)
      }
    }, [isAuthenticated]);

    const filteredData = applyFilters(data, filters);
    const paginatedData = applyPagination(filteredData, page, limit);
    return (
      <WrappedComponent {...props}
        open={open}
        slug={slug}
        setopenDialogue={setopenDialogue}
        openDialogue={openDialogue}
        coloumnKey={coloumnKey}
        headkey={headkey}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        data={paginatedData}
        handleSelectOneData={handleSelectOneData}
        filteredData={filteredData}
        setOpen={setOpen}
        deleteData={deleteData}
        updateData={updateData}
        getAllData={getAllData}
        page={page}
        filters={filters}
        limit={limit}
        selected={selected}
        setSelected={setSelected}
        selectedBulkActions={selectedBulkActions}
        selectedSomeData={selectedSomeData}
        selectedAllData={selectedAllData}
        handleSelectAllData={handleSelectAllData}
        handlePageChange={handlePageChange}
        handleLimitChange={handleLimitChange}
        createData={createData}
        isAuthenticated={isAuthenticated}
        loading={loading}
        answer={answer}
        setAnswer={setAnswer}
        auth={auth}
        navigate={navigate}
      />
    );
  };
  return EnhancedComponent;
}
  
  export default Common_Fun;