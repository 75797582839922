import  NavigationService  from "./NavigationService";

const checkLoginTime = () => {
    const loginTime = localStorage.getItem('backend_loginTime');

    if (loginTime) {
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - parseInt(loginTime);

        // 30 minutes in milliseconds
        const thirtyMinutes = 30 * 60 * 1000;
        if (timeDifference > thirtyMinutes) {
            // Remove the timestamp from local storage
            localStorage.removeItem('backend_loginTime');
            localStorage.removeItem('login_data');
            localStorage.removeItem('token');
            // Call the logout callback function
            NavigationService.navigate("/")
        }
    }
};

 const checklogoutService = () => {
    // Remove the timestamp from local storage
    localStorage.removeItem('backend_loginTime');
    localStorage.removeItem('login_data');
    localStorage.removeItem('token');
    // Call the logout callback function
    NavigationService.navigate("/")
};

export default { checklogoutService, checkLoginTime };