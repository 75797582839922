import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useEffect } from 'react';
import './css/Login.css';
import './css/Dashboard.css'
import DashboardAction from './action/DashboardAction';
import NavigationService from './action/NavigationService';
function App() {
  const content = useRoutes(router);
  const location = useLocation();
  const url = location?.pathname.split('/')[1];
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token') || url == "resetPassword") {
    }
    else {
      navigate("/")
    }
  }, [localStorage])

  useEffect(() => {
    DashboardAction.checkLoginTime();
  }, []);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
//  App;

const AppWithNavigation = () => {
  const navigation = useNavigate()
  useEffect(() => {
    NavigationService.setNavigateRef(navigation)
  }, [navigation])
  return <App />
}

export default AppWithNavigation


