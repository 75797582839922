export const token = localStorage.getItem('token');

export const config = {
    headers: {
      'accept': '*/*',
      'Auth-Token': `${token}`,
    },
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
 export const deleteApi= `${baseUrl}/user/delete/`;
 export const get_ALL_User_APi=`${baseUrl}/users`;

 export const userHeadKey=[
  'ID','USER NAME','EMAIL','STATUS','ACTIONS'
 ]
 export const siteColoumnKey=[
  'name','url','date'
  // 'user_Name','user_Email'
 ]

//  export const userHeadKey = [
//   { headerName: 'USER NAME', },
//   {  headerName: 'EMAIL', },
//   {  headerName: 'STATUS', },
//   { headerName: 'ACTIONS', },
//  ]
 export const siteHeadKey=[
  'ID','SITE NAME','SITE URL	','ONBOARD DATE',
  // 'USER NAME','USER EMAIL',
  'ACTIONS'
 ]

 export const userColoumnKey=[
  'name','email','status'
 ]

 export const currencies = [
  {
    value: 0,
    label: 'simple_user',
  },
  {
    value: 1,
    label: "is_admin",
  },
  
];