import { TextField,Button,InputLabel,Select,MenuItem, FormControl } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import PageTitleWrapper from 'src/components/PageTitleWrapper'
import PageHeader from 'src/content/dashboards/Crypto/PageHeader'
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { notify } from 'src/common/toastMesaage'
const baseUrl = process.env.REACT_APP_BASE_URL

const Add_Free_Text = () => {
  const [field,setField] = useState({
    target:"",
    keyword:""
  })
  const [loading,setLoading] = useState(false);

  const handleChange=(e)=>{
    const {name,value} = e.target;
    setField({...field,[name]:value})
  }
  const token = JSON.parse(localStorage.getItem('login_data'))?.data?.authToken;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    const headers = {
      accept: "*/*",
      "auth-Token": `${token}`,
    };
  
    axios.post(`${baseUrl}/sites/freetext`, field, { headers })
      .then((res) => {
        if(res.data.status == true){
          notify("Free Text Added","success")
        }
        setField({
          target: "",
          keyword: ''
        });
        setLoading(false)
      })
      .catch((error) => {
        console.log("Error occurred in adding data", error);
        notify("Error occurred in adding free text.","error");
        setLoading(false)
      });
     
  }

  const loggedin_Name=localStorage.getItem('login_data');
  const parsedData=JSON.parse(loggedin_Name)
      const user = {
        name: parsedData?.data?.name,
        avatar: '/static/images/avatars/1.jpg'
      };
  
  return <>
    <Helmet>
        <title>{user.name ? user.name : ""}</title>
    </Helmet>
   <section className="breadcrum-section" style={{marginTop:'20px',marginLeft:'20px'}}>
      <div role="presentation" style={{marginTop:'20px'}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/algorithm/freeText"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Algorithms
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Free Text mappings 
        </Typography>
      </Breadcrumbs>
    </div>
        </section>
  <PageTitleWrapper>
    <PageHeader title="Create Free text" />
  </PageTitleWrapper>
  <form onSubmit={handleSubmit}>
    <>
  <div style={{display:'flex',width:"95%",margin:'auto',gap:'10px',marginTop:'10px'}}>
    <FormControl style={{width:'50%'}}>
  <InputLabel id="demo-simple-select-label">Target</InputLabel>
  <Select
          labelId="demo-simple-select-helper-label"
          id="target"
          name='target'
          value={field.target}
          label="Target"
          
          onChange={handleChange}
        >
          <MenuItem value="">
            
          </MenuItem>
          <MenuItem value="about-us">About us</MenuItem>
          <MenuItem value="contact-us">Contact us</MenuItem>
        </Select>
        </FormControl>
  {/* <TextField id="outlined-basic" label="Target" variant="outlined" name='target' style={{width:'50%'}} value={field.target} onChange={handleChange} /> */}
  <TextField id="outlined-basic" label="Keyword" variant="outlined" name='keyword' style={{width:'50%'}} value={field.keyword} onChange={handleChange} />
  </div>
  <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'20px'}}>
  <Button
          className="common-black-btn"
          // variant="contained"
          // color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
         { loading ? 'Adding...' : `Add Free Text`}
        </Button>
  </div>
  </>
  </form>
  <ToastContainer />
  </>
}

export default Add_Free_Text
