import React, { useState, useEffect } from "react";
import { Button , Box} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "src/content/dashboards/Crypto/PageHeader";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColDef,
  GridToolbarDensitySelector,
  GridPagination,
} from "@mui/x-data-grid";
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { notify } from "src/common/toastMesaage";

const Bulk_Upload = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updatedData,setUpdatedData] = useState([]);
  const [filteredData,setFilteredData] = useState(false);
  const [dataByStatus,setDataByStatus] = useState([]);
  const [fileName,setFileName] = useState(``);
  const baseUrl = process.env.REACT_APP_BASE_URL


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  function previewFile(e) {
    const file = e.target.files[0];
    const name = e.target.name;
    const value = e.target.value;
    setField({ ...field, [e.target.name]: e.target.value });

    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const result = e.target.result;
        if (typeof result === "string") {
          const base64 = result.split(",")[1];

          setField(() => ({
            ...field,
            [name]: value,
            web_logo: base64, // Store the Base64-encoded image in the web_logo property
          }));

          setSelectedFile(file);
          setBase64String(base64);
        }
      };

      reader.readAsDataURL(file);
    }
  }
  const handleMultipleSend = () => {
    if (!file) {
      console.error("No file selected");
      return;
    }
    setUpdatedData([]);
    setLoading(true)
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("file", file);
    axios
      .post(
        `${baseUrl}/sites/uploadsites`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setMessage(res.data.message);
        notify(res.data.message, "success");
        setUserData(res?.data?.data?.results)
        setFileName(res?.data?.data?.file_name)
        document.getElementById(`uploadCsv`).value = "";
        setLoading(false)
      })
      .catch((error) => {
        console.error(
          "Error occurred while sending multiple files",
          error.response
        );
        notify("Error ocuured in uploading sites.", "error");
        setLoading(false)
      });
  };

  useEffect(() => {
    const storedLoginData = localStorage.getItem("login_data");
    if (storedLoginData) {
      const parsedData = JSON.parse(storedLoginData);
      setUserId(parsedData.data.user_id);
    }
  }, []);

  const renderStatusCell = (params) => {
    const user_status = params.row.user_status;
    const isValid = params.row.status
    if (isValid == "0") {
      return (
        <Button variant="contained" color="error" sx={{ padding: "5px" }}>
          {user_status}
        </Button>
      );
    } else {
      return (
        <Button variant="contained" color="success" sx={{ padding: "5px" }}>
          {user_status}
        </Button>
      );
    } 
  };

  const renderSiteStatusCell = (params) =>{
    const site_status = params.row.site_status;
    const isValid = params.row.status
    if (isValid == "0") {
      return (
        <Button variant="contained" color="error" sx={{ padding: "5px" }}>
          {site_status}
        </Button>
      );
    } else {
      return (
        <Button variant="contained" color="success" sx={{ padding: "5px" }}>
          {site_status}
        </Button>
      );
    } 
  }

  const columns = [
    { field: 'count', headerName: 'ID', width: 100 },
    {
      field: 'email',
      headerName: 'Email',
      width: 260,
      editable: false,
    },
    {
      field: 'url',
      headerName: 'Url',
      width: 260,
      editable: false,
    },
    {
      field: 'user_status',
      headerName: 'User Status',
      width: 260,
      renderCell: renderStatusCell,
      editable: false,
    },
    {
      field: 'site_status',
      headerName: 'Status',
      width: 360,
      renderCell: renderSiteStatusCell,
      editable: false,
    },
  ];

  useEffect(() => {
    if (userData.length > 0) {
      const sortedUserData = userData.slice().sort((a, b) => {
        if (a.user_status == 0 && b.user_status != 0) {
          return -1;
        } else if (a.user_status != 0 && b.user_status == 0) {
          return 1;
        } else {
          return 0;
        }
      });
  
      const indexedUserData = sortedUserData.map((item, index) => ({
        ...item,
        count: index + 1,
      }));
  
      setUpdatedData(indexedUserData);
      setFilteredData(false);
    }
  }, [userData]);
  

  // useEffect(()=>{
  //   if(userData.length > 0){
  //     const indexedUserData = userData.map((item, index) => ({
  //       ...item,
  //       index: index + 1,
  //     }));
  //     const sortedUserData = indexedUserData.sort((a, b) => {
  //       if (a.user_status === "Invalid Email" && b.user_status !== "Invalid Email") {
  //         return -1;
  //       } else if (a.user_status !== "Invalid Email" && b.user_status === "Invalid Email") {
  //         return 1;
  //       } else {
  //         return 0;
  //       }
  //     });
  //     setUpdatedData(sortedUserData);
  //     setFilteredData(false)
    
  //   }
  // },[userData]);
  

  const filterData = (dataStatus) =>{
    setFilteredData(true)
    const newData=[...updatedData];
    const getFilteredData = newData.filter((item)=>{
     return item.status == dataStatus
    });
    setDataByStatus(getFilteredData)
  }

  function CustomToolbar() {
    return (
      <>
        <div style={{ display: "inline-flex" }}>
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />

            <GridToolbarExport />
          </GridToolbarContainer>
        </div>
      </>
    );
  }
  
  return (
    <>

<section className="breadcrum-section" style={{marginTop:'0px',marginLeft:'20px'}}>
      <div role="presentation" style={{marginTop:'20px'}} >
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/site-management/getAllOnboardedSite"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Sites
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Bulk WIM upload 
        </Typography>
      </Breadcrumbs>
    </div>
        </section>
        <div style={{display:'flex',width:'98%',justifyContent:'space-between',alignItems:'center'}}>
        <PageTitleWrapper>
        <PageHeader title="Bulk WIM upload" />
      </PageTitleWrapper>
      <a href="/demo.csv" download style={{paddingRight:'10px'}}>
            <Button className="common-black-btn">
             Download Sample</Button>
             </a>
        </div>
     

      <div
        className=""
        id=""
        style={{
          background: "#fff",
          // height: "200px",
          // justifyContent: "center",
          // alignItems: "center",
          // display: "flex",
          width: "95%",
          margin: "auto",
          borderRadius: "20px",
          boxShadow: "0 0 52px #3d3d3d1f",
          border: "1px solid #dcdcdc",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column",alignItems:'center',justifyContent:'center',margin:'30px' }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <Button
              className="common-black-btn"
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Upload files 
              <input
                type="file"
                onChange={handleFileChange}
                accept=".csv"
                multiple
                id="uploadCsv"
                style={{ display: "none" }}
              />
            </Button>
            
          </div>
          <Button
            className="common-black-btn"
            sx={{ backgroundColor: loading ? "gray" : "#4287c5", marginTop: "10px" }}
            variant="contained"
            size="medium"
            disabled={loading}
            onClick={handleMultipleSend}
          >
            {`${loading ? "Loading..." : "Submit"}`}
          </Button>
          <ToastContainer />
        </div>
        <div style={{display:'flex',justifyContent:'center',marginBottom:'15px'}}>Please click on the upload button, choose a .csv file and hit the submit button</div>
      </div>
     
      {updatedData.length >0 &&
      <div style={{width:'95%',margin:'auto',marginTop:'20px',marginBottom:'20px'}}>
      <div className="bulk-wim-table-heading" style={{padding:'10px'}}>Your Excel Sheet has been imported. Please view the Valid and Invalid records</div>
      <div style={{backgroundColor:'white',}}>
        <Box sx={{ width: '100%' }}>
      <DataGrid slots={{ 
toolbar: () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px",
    }}
  >
    <CustomToolbar />

    <div
      className="custom-toolbar-button"
      style={{ display: "inline-flex", gap: "10px" }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginRight: "16px",
          fontWeight: 600,
          margin: "auto",
        }}
      >
        {fileName}
      </Typography>
    </div>
  </div>
),
       }}
        rows={updatedData && updatedData}
        columns={columns}
        getRowId={(row) => row.count}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        disableDensitySelector
        pageSizeOptions={[25, 30, 50]}
        disableRowSelectionOnClick
      />
    </Box>
        </div>
      </div>}
     
     
    </>
  );
};

export default Bulk_Upload;
