import React, { useEffect, useRef, useState } from 'react'
import { TextField, Button } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ToastContainer } from 'react-toastify'
import Box from "@mui/material/Box";
import { callApi, postApi, postData } from 'src/action/RestClient'
import { useNavigate, useParams } from 'react-router'
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { notify } from 'src/common/toastMesaage';
import CircularProgress from '@mui/material/CircularProgress';


const SiteRankingFields = () => {
    const params = useParams();
    const [formData, setFormData] = useState({
        rank: '',
        global_rank: '',
        country_rank: '',
        category_rank: '',
        avg_visit: '',
        total_visits: '',
        desktop: '',
        mobile_web: '',
        male: '',
        female: '',
        monthly_visit: { data: [''], date: [''] },
        gender: { m: '', f: '', o: '' }
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    console.log("errors", errors)

    const handleKeyPress = (event, flag) => {
        if (flag === "total_visits" || flag === "date" ) {
            return;
        } else {
            const charCode = event.charCode;
            // Allow digits and the decimal point
            if ((charCode < 48 || charCode > 57) && charCode !== 46) {
                event.preventDefault();
            }
        }

    };

    const handlePaste = (event, flag) => {
        if (flag === "total_visits" ) {
            return;
        } else {
            const paste = event.clipboardData.getData('text');
            if (!/^\d*\.?\d*$/.test(paste)) {
                event.preventDefault();
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // if(value?.length >=16){
        //     setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Value must be less than 15 digit' }));
        // }else{
        //     setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        // }
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        
    };

    const handleNestedChange = (e, parentKey, index) => {
        const { name, value } = e.target;
        // if(value?.length >=16){
        //     setErrors((prevErrors) => ({ ...prevErrors, [`${name}${index}`]: 'Value must be less than 15 digit' }));
        // }else{
        //     setErrors((prevErrors) => ({ ...prevErrors, [`${name}${index}`]: '' }));
        // }
        setErrors((prevErrors) => ({ ...prevErrors, [`${name}${index}`]: '' }));
        setFormData((prevData) => {
            const updatedParent = { ...prevData[parentKey] };
            updatedParent[name][index] = value;
            return {
                ...prevData,
                [parentKey]: updatedParent
            };
        });
       
    };

    const handleGenderChange = (e) => {
        const { name, value } = e.target;
        // if(value?.length >=16){
        //     setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Value must be less than 15 digit' }));
        // }else{
        //     setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        // }
        setFormData((prevData) => ({ ...prevData, gender: { ...prevData.gender, [name]: value } }));
        
    };

    const validateDate = (date) => {
        const regex = /^\d{4}-(0[1-9]|1[0-2])$/;
        return regex.test(date);
    };

    const validateForm = () => {
        let formErrors = {};
        Object.keys(formData).forEach((key) => {
            if (typeof formData[key] === 'object' && !Array.isArray(formData[key])) {
                Object.keys(formData[key]).forEach((nestedKey) => {
                    if (Array.isArray(formData[key][nestedKey])) {
                        formData[key][nestedKey].forEach((item, index) => {
                            if (!item || item <= 0) {
                                formErrors[`${nestedKey}${index}`] = 'Value must be greater than 0';
                            }
                            if (nestedKey === 'date' && !validateDate(item)) {
                                formErrors[`${nestedKey}${index}`] = 'Date must be in YYYY-MM(2024-01) format';
                            }
                        });
                    } else if (!formData[key][nestedKey] || formData[key][nestedKey] <= 0) {
                        formErrors[nestedKey] = 'Value must be greater than 0';
                    }
                });
            } else if (!formData[key] || formData[key] <= 0) {
                formErrors[key] = 'Value must be greater than 0';
            }
        });
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const data = { ...formData, website: params?.id }
            console.log("data",data)
            const url = '/update/manage-ranking';
            setLoading(true)
            const response = await postData(url, data);
            setLoading(false)
            if (response?.status) {
                notify(response?.message, "success");
            } else {
                notify(response?.error, "error");
            }
            // Clear errors if form is valid
            setErrors({});
        }
    };


    const isEmptyErrorObject = (error) => {
        if (Object.keys(error).length === 0) {
          return true;
        }
        for (const key in error) {
          if (error[key] !== "") {
            return false;
          }
        }
      
        return true;
      };
      
    //   const isEmpty = isEmptyErrorObject(errors);
    //   console.log(isEmpty); 

    const addMonthlyVisitField = () => {
        setFormData((prevData) => ({ ...prevData, monthly_visit: { data: [...prevData.monthly_visit.data, ''], date: [...prevData.monthly_visit.date, ''] } }));
    };

    const removeMonthlyVisitField = (index) => {
        setFormData((prevData) => {
            const updatedData = prevData.monthly_visit.data.filter((_, i) => i !== index);
            const updatedDate = prevData.monthly_visit.date.filter((_, i) => i !== index);
            return {
                ...prevData,
                monthly_visit: {
                    data: updatedData,
                    date: updatedDate
                }
            };
        });
    };

    const fillDummyData = () => {
        setFormData({
            rank: '9',
            global_rank: 25,
            country_rank: 40,
            category_rank: 6,
            avg_visit: 0.01402777778,
            total_visits: '98.09B',
            desktop: '78',
            female: '55',
            male: '45',
            mobile_web: '22',
            monthly_visit: {
                data: ['88.28', '107.90', '107.90', '117.71', '107.90', '98.09', '107.90', '117.71', '107.90', '98.09'],
                date: ['2024-02', '2024-01', '2023-12', '2023-11', '2023-10', '2023-09', '2023-08', '2023-07', '2023-06', '2023-05']
            },
            gender: { m: '63', f: '33.30', o: '3.70' }
        });
    };


    const getRankingData = async () => {
        setLoading(true)
        const response = await callApi('GET', `/get/manage-ranking-by-name/${params?.id}`, null, null);
        setLoading(false)
        const { data = null } = response?.data
        if (response?.data?.status && data) {
            const {
                rank = "",
                global_rank = "",
                country_rank = "",
                category_rank = "",
                monthly_visit = null,
                female = "",
                male = "",
                avg_visit = "",
                total_visits = "",
                desktop = "",
                mobile_web = "",
                gender = null,
            } = data
            setFormData({
                rank, global_rank, country_rank, category_rank, avg_visit, total_visits, desktop, mobile_web, male, female,
                monthly_visit: {
                    data: monthly_visit?.data || "",
                    date: monthly_visit?.date || ""
                },
                gender: { m: gender?.m, f: gender?.f, o: gender?.o }
            });
        }
        else {
            notify("Ranking record not found", "error");
        }
    }


    useEffect(() => {
        getRankingData();
    }, [params])


    return <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                            <div role="presentation" style={{ marginTop: '20px' }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href={params?.name === "top" ? "/site-management/getAllTopSite" : "/site-management/getAllUsersSite"}
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        site-management
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </section>
                        <div className="iq-card position-relative rounded overflow-hidden">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}><span> WebSite:- <span style={{ color: '#276b1f', fontSize: "2rem", fontWeight: 500 }}>{params?.id}</span></span> </h4>
                                </div>
                            </div>
                            <Box sx={{ width: "100%", background: "white", paddingBottom: '41px' }}>
                                <div className="FormsAreaNew container">
                                    <div className="tab-content ">
                                        <form
                                            onSubmit={handleSubmit}
                                        >
                                            <Box className='create_package_outer'
                                                component="div"
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <Box className='form-field-box flex-wrap d-flex'>
                                                    <div className="form-group col-lg-12">
                                                        <h4>Ranking</h4>
                                                    </div>
                                                    {['rank', 'global_rank', 'country_rank', 'category_rank', 'avg_visit', 'total_visits', 'desktop', 'mobile_web', 'male', 'female'].map((field) => (
                                                        <div className="form-group col-lg-4" key={field}>
                                                            <TextField
                                                                // type={field === 'total_visits' ? 'text' : 'number'}
                                                                type="text"
                                                                id={field}
                                                                className="form-control"
                                                                name={field}
                                                                label={field.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase())}
                                                                placeholder={`Enter ${field.replace(/_/g, ' ')}`}
                                                                required
                                                                autoComplete="off"
                                                                value={formData[field]}
                                                                maxLength="15"
                                                                onChange={handleChange}
                                                                // onKeyPress={(event) => handleKeyPress(event, field)}
                                                                // onPaste={(event) => handlePaste(event, field)}
                                                                error={!!errors[field]}
                                                                helperText={errors[field]}
                                                            />
                                                        </div>
                                                    ))}
                                                </Box>

                                                <Box className='form-field-box flex-wrap d-flex'>
                                                    <div className="form-group col-lg-12">
                                                        <h4>Gender</h4>
                                                    </div>
                                                    {['m', 'f', 'o'].map((field) => (
                                                        <div className="form-group col-lg-4" key={field}>
                                                            <TextField
                                                                type="text"
                                                                id={field}
                                                                className="form-control"
                                                                name={field}
                                                                label={field === 'm' ? 'Male' : field === 'f' ? 'Female' : 'Other'}
                                                                placeholder={`Enter ${field === 'm' ? 'male' : field === 'f' ? 'female' : 'other'}`}
                                                                required
                                                                autoComplete="off"
                                                                maxLength="15"
                                                                value={formData.gender[field]}
                                                                onChange={handleGenderChange}
                                                                // onKeyPress={(event) => handleKeyPress(event, field)}
                                                                // onPaste={(event) => handlePaste(event, field)}
                                                                error={!!errors[field]}
                                                                helperText={errors[field]}
                                                            />
                                                        </div>
                                                    ))}
                                                </Box>

                                                <Box className='form-field-box flex-wrap d-flex'>
                                                    <div className="form-group col-lg-12">
                                                        <h4>Monthly Visit</h4>
                                                    </div>
                                                    {formData?.monthly_visit?.data?.map((_, index) => (
                                                        <div key={index} className="form-group col-lg-4">
                                                            <TextField
                                                                // type="number"
                                                                type="text"
                                                                id={`Value${index}`}
                                                                className="form-control"
                                                                name="data"
                                                                label={`Value ${index + 1}`}
                                                                placeholder={`Enter value ${index + 1}`}
                                                                required
                                                                autoComplete="off"
                                                                maxLength="15"
                                                                value={formData?.monthly_visit?.data[index]}
                                                                onChange={(e) => handleNestedChange(e, 'monthly_visit', index)}
                                                                // onKeyPress={(event) => handleKeyPress(event, "month")}
                                                                // onPaste={(event) => handlePaste(event, "month")}
                                                                error={!!errors[`data${index}`]}
                                                                helperText={errors[`data${index}`]}
                                                            />
                                                            <TextField
                                                                type="text"
                                                                id={`date${index}`}
                                                                className="form-control"
                                                                name="date"
                                                                label={`Date ${index + 1}`}
                                                                placeholder={`Enter date ${index + 1}`}
                                                                required
                                                                autoComplete="off"
                                                                maxLength="15"
                                                                value={formData?.monthly_visit?.date[index]}
                                                                onChange={(e) => handleNestedChange(e, 'monthly_visit', index)}
                                                                // onKeyPress={(event) => handleKeyPress(event, "date")}
                                                                // onPaste={(event) => handlePaste(event, "date")}
                                                                error={!!errors[`date${index}`]}
                                                                helperText={errors[`date${index}`]}
                                                            />
                                                            <Button onClick={() => removeMonthlyVisitField(index)} variant="contained" color="secondary" className='mb-3 ms-5'>
                                                                Remove
                                                            </Button>
                                                        </div>
                                                    ))}
                                                    <div className="form-group col-lg-12">
                                                        <Button onClick={addMonthlyVisitField} variant="contained" color="primary" className='ms-5'>
                                                            Add Monthly Visit Field
                                                        </Button>
                                                    </div>
                                                </Box>




                                                <div className='text-center pt-4'>
                                                    {loading ?
                                                        <>
                                                            <Button className="common-black-btn" variant="contained" type='button'>
                                                                <Box gap-3 sx={{ color: "white", width: "100px" }}>
                                                                    <CircularProgress color="inherit" size={18} />
                                                                </Box>
                                                            </Button>
                                                        </>
                                                        :
                                                        <>
                                                            <Button className={!isEmptyErrorObject(errors) ? "common-black-btn-dis" : "common-black-btn"} variant="contained" type='submit' disabled={!isEmptyErrorObject(errors)}>
                                                                Submit
                                                            </Button>
                                                        </>
                                                    }


                                                    <Button
                                                        className="common-black-btn"
                                                        variant="contained"
                                                        onClick={fillDummyData}
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        Fill Dummy value
                                                    </Button>
                                                </div>
                                            </Box>
                                        </form>

                                    </div>
                                </div>

                            </Box>
                        </div >
                    </div >
                </div >
            </div >
        </div >
        <ToastContainer />
    </>
}

export default SiteRankingFields



// /update/manage-ranking  Post type 

// /get/manage-ranking-by-name/{name}  get type name is like youtube.com