const baseUrl = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem('token') || '';
import axios from "axios";
import  DashboardAction from "../DashboardAction";

const getToken = () => {
    return localStorage.getItem('token') || '';
  };

export const callApi = async (method, url, data = null, params = {}) => {
    try {
     const token = getToken();
      // Configure axios request based on the method
      const config = {
        method: method,
        url: baseUrl + url,
        headers: {
          accept: '*/*',
          'Auth-Token': token,
        },
        data: data,
        params: params
      };
      const response = await axios(config);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Log out the user if the response is unauthorized (401)
        DashboardAction.checklogoutService()
      }
      console.error('Error:', error);
      throw error;
    }
  };


  export const postApi = async (url, formData, params = {}) => {
    try {
        const token = getToken();
        const config = {
            method: 'post',
            url: baseUrl + url,
            headers: {
                accept: "*/*",
                'Auth-Token': token,
                'Content-Type': 'multipart/form-data'
            },
            data: formData,
            params: params
        };
        const response = await axios(config);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
          // Log out the user if the response is unauthorized (401)
          DashboardAction.checklogoutService()
        }
        console.error('Error:', error);
        throw error;
    }
};

export const postData = async (url, data, params = {}) => {
  try {
      const token = getToken();
      const config = {
          method: 'post',
          url: baseUrl + url,
          headers: {
              accept: "*/*",
              'Auth-Token': token,
              'Content-Type': 'application/json',
          },
          data: data,
          params: params
      };
      const response = await axios(config);
      return response.data;
  } catch (error) {
      if (error.response && error.response.status === 401) {
        // Log out the user if the response is unauthorized (401)
        DashboardAction.checklogoutService()
      }
      console.error('Error:', error);
      throw error;
  }
};