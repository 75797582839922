import React, { useEffect, useState } from 'react';

import { callApi, postData } from 'src/action/RestClient';

import { Paper, Tooltip, IconButton, Box, Typography, CircularProgress } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useLocation } from 'react-router';
import CommonTable from 'src/common/table/table';

const InvoiceDetails = () => {
  const location = useLocation();
  const userId = location?.state?.userId;
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [pageOfLinesOfInvoice, setPageOfLinesOfInvoice] = useState(0); // Current page index of Lines of invoices
  const [pageOfTaxesOfInvoice, setPageOfTaxesOfInvoice] = useState(0); // Current page index of texes of invoices
  const [rowsPerPageOfLinesOfInvoice, setRowsPerPageOfLinesOfInvoice] = useState(10);
  const [rowsPerPageOfTaxesOfInvoice, setRowsPerPageOfTaxesOfInvoice] = useState(10);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handlePageChangeOfLinesOfInvoice = (event, newPage) => {
    setPageOfLinesOfInvoice(newPage);
  };
  const handlePageChangeOfTaxesOfInvoice = (event, newPage) => {
    setPageOfTaxesOfInvoice(newPage);
  };

  const handleRowsPerPageChangeOfLinesOfInvoice = (newRowsPerPage) => {
    const data = parseInt(newRowsPerPage, 10);
    setRowsPerPageOfLinesOfInvoice(data);
    setPageOfLinesOfInvoice(0);
  };

  const handleRowsPerPageChangeOfTaxesOfInvoice = (newRowsPerPage) => {
    const data = parseInt(newRowsPerPage, 10);
    setRowsPerPageOfTaxesOfInvoice(data);
    setPageOfTaxesOfInvoice(0);
  };


  const postAPICalls = async () => {
    const url = `/invoice/${userId}`;
    setLoading(true);
    try {
      const response = await callApi('GET', url, null, null);
        if (response.status) {
          setInvoiceDetails(response?.data?.data);
        }
    } catch (error) {
        console.error('Error fetching subscription:', error);
    } finally {
        setLoading(false);
    }
};
  // const postAPICalls = async () => {
  //   // /invoice/{id}
  //   // const url = '/subscription-invoices/by-user';
  //   const url = `/invoice/${userId}`;
  //   // const data = { id: userId };
  //   const response = await callApi('GET', url, null, null);
  //   // const responce = await get(url, null);
  //   if (response.status) {
  //     setLoading(false);
      
  //   }
    
  // };
  useEffect(() => {
    postAPICalls();
  }, []);

  const column1 = [
    { field: 'amount', label: 'Amount', width: 240, align: 'left' },
    { field: `item`, label: 'Item', width: 240, align: 'left' },
  ];
  const column2 = [
    { field: 'taxAmount', label: 'Tax Amount', width: 240, align: 'left' },
    { field: 'taxId', label: 'Tax Id', width: 240, align: 'left' },
    { field: 'taxName', label: 'Tax Name', width: 240, align: 'left' },
    { field: 'taxPercentage', label: 'Tax Percentage', width: 240, align: 'left',},
  ];

  const loader = () => {
    return (
      <div className='w-100'>
        <Box className="align-items-center justify-content-center py-5 d-flex table-loader" style={{minHeight: "auto"}}>
          <CircularProgress />
        </Box>
      </div>
    );
  };

  return (
    <>
      <div
        className="content-page"
        style={{ fontFamily: 'sans-serif', fontSize: '15px' }}
      >
        <Paper>
          <div className="d-flex">
            <div className="col p-2">
              <Tooltip
                className="me-2"
                title={`Back`}
                arrow
                onClick={() => navigate(-1)}
              >
                <IconButton color="inherit" size="small">
                  <ArrowBackOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <div className="col text-center p-2">
              <Typography variant="h5" gutterBottom fontSize="medium">
                Invoice Details
              </Typography>
            </div>
            <div className="col"></div>
          </div>

          <div className="d-flex invoicesContainer">
            <div className="col p-2">
              <h6>User Info</h6>
              {loading ? (
                loader()
              ) : Object.keys(invoiceDetails).length === 0 ? (
                <div>
                  <div colSpan={10}>
                    <h4 className="align-items-center justify-content-center py-5 d-flex ">
                      {'No Records Found'}
                    </h4>
                  </div>
                </div>
              ) : (
                <table className="tableArea">
                  <tbody>
                    <tr>
                      <td style={{ width: '150px' }}>
                        {' '}
                        <strong>Name:</strong>{' '}
                      </td>
                      <td> {invoiceDetails?.memberName}</td>
                    </tr>
                    <tr>
                      <td>
                        {' '}
                        <strong>Business Name:</strong>{' '}
                      </td>
                      <td> {invoiceDetails?.businessName}</td>
                    </tr>

                    <tr>
                      <td>
                        {' '}
                        <strong>Address</strong>{' '}
                      </td>
                      <td> {invoiceDetails?.businessAddress} </td>
                    </tr>
                    <tr>
                      <td>
                        {' '}
                        <strong>Date Of Invoice:</strong>
                      </td>
                      <td> {invoiceDetails?.dateOfInvoice}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <div className="col  p-2">
              <h6>Payment Info</h6>
              {loading ? (
                loader()
              ) : Object.keys(invoiceDetails).length === 0 ? (
                <div>
                  <div colSpan={10}>
                    <h4 className="align-items-center justify-content-center py-5 d-flex ">
                      {'No Records Found'}
                    </h4>
                  </div>
                </div>
              ) : (
                <table className="tableArea">
                  <tbody>
                    <tr>
                      <td style={{ width: '180px' }}>
                        {' '}
                        <strong>Amount:</strong>{' '}
                      </td>
                      <td> {invoiceDetails?.totalAmount}</td>
                    </tr>
                    <tr>
                      <td>
                        {' '}
                        <strong>Tax:</strong>{' '}
                      </td>
                      <td> {invoiceDetails?.totalTax} </td>
                    </tr>
                    <tr>
                      <td>
                        {' '}
                        <strong>Total Amount With Tax :</strong>
                      </td>
                      <td> {invoiceDetails?.totalAmountWithTax}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Paper>
        <div className="row mt-3">
          <div className="col-md-4">
            {' '}
            <Paper>
              <>
                <div className="iq-card position-relative rounded overflow-hidden cardAreaTable">
                  <Box
                    sx={{
                      height: 400,
                      width: '100%',
                      background: 'white',
                      paddingBottom: '41px',
                    }}
                    className="shadow-none"
                  >
                    <CommonTable
                      data={invoiceDetails?.linesOfInvoice}
                      columns={column1}
                      // enablePagination
                      type={'linesOfInvoice'}
                      loading={loading}
                      page={pageOfLinesOfInvoice || 1}
                      count={invoiceDetails?.linesOfInvoice?.length || 1}
                      rowsPerPage={rowsPerPageOfLinesOfInvoice}
                      onPageChange={handlePageChangeOfLinesOfInvoice}
                      onRowsPerPageChange={
                        handleRowsPerPageChangeOfLinesOfInvoice
                      }
                    />
                  </Box>
                </div>
              </>
            </Paper>
          </div>
          <div className="col">
            {' '}
            <Paper>
              <>
                <div className="iq-card position-relative rounded overflow-hidden cardAreaTable">
                  <Box
                    sx={{
                      height: 400,
                      width: '100%',
                      background: 'white',
                      paddingBottom: '41px',
                    }}
                    className="shadow-none"
                  >
                    <CommonTable
                      data={invoiceDetails?.taxesOfInvoice}
                      columns={column2}
                      // enablePagination
                      type={'taxesOfInvoice'}
                      page={pageOfTaxesOfInvoice || 1}
                      count={invoiceDetails?.taxesOfInvoice?.length || 1}
                      rowsPerPage={rowsPerPageOfTaxesOfInvoice}
                      onPageChange={handlePageChangeOfTaxesOfInvoice}
                      onRowsPerPageChange={
                        handleRowsPerPageChangeOfTaxesOfInvoice
                      }
                    />
                  </Box>
                </div>
              </>
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvoiceDetails;
