import axios from 'axios'
import React, { useEffect, useState } from 'react'
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/content/dashboards/Crypto/PageHeader';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet-async';
import DashboardAction from 'src/action/DashboardAction';

const Framework = () => {

  const [frameWorkData, setFrameWorkData] = useState();
  const baseUrl = process.env.REACT_APP_BASE_URL
  const mockData = {
    data: [
      {
        cat: "Security and Privacy",
        questions: [
          {
            "title": "Does the website use encryption (e.g., SSL/TLS) to secure sensitive information during transmission and access?",
            "weight": 0.5
          }
        ]
      },
      {
        cat: "Performance and Technical",
        questions: [
          {
            "title": "Does the website work properly on different browsers like Chrome, Firefox, Safari?",
            "weight": 0.5
          },
          {
            "title": "Does search return results within 2 seconds?",
            "weight": 0.5
          },
          {
            "title": "Website page load speed for users (0) 8+ (1) 3-8 sec (2) 0-3 sec",
            "weight": 0.5
          }
        ]
      }
    ]
  }

  const token = localStorage.getItem('token') || '';

  const getQuestionData = async () => {
    await axios.get(`${baseUrl}/get/questions`, {
      headers: {
        accept: '*/*',
        'Auth-Token': `${token}`,
      },
    }).then((res) => {
      setFrameWorkData(res?.data?.data?.data)
    }).catch((error) => {
      if (error.response && error.response.status === 401) {
        DashboardAction.checklogoutService()
      }
      console.log("Error occured in fetching the frameWork", error)
    })
  }

  useEffect(() => {
    getQuestionData();
  }, [])

  const loggedin_Name = localStorage.getItem('login_data');
  const parsedData = JSON.parse(loggedin_Name)
  const user = {
    name: parsedData?.data?.name,
    avatar: '/static/images/avatars/1.jpg'
  };



  return <>
    <Helmet>
      <title>{user.name ? user.name : ""} </title>
    </Helmet>
    <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
      <div role="presentation" style={{ marginTop: '20px' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            // href="/configuration/addSetting"
            href="/"
          >
            <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Dashboard
            {/* Configuration */}
          </Link>
          <Typography
            sx={{ display: 'flex', alignItems: 'center' }}
            color="text.primary"
          >
            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            {/* Framework  */}
            Questionnaire
          </Typography>
        </Breadcrumbs>
      </div>
    </section>
    <PageTitleWrapper>
      <PageHeader title="Questionnaire" />
    </PageTitleWrapper>
    {/* <div style={{padding:'20px'}}>
      {frameWorkData?.data?.map((category, catIndex) => (
        <div key={catIndex} style={{margin:'10px',marginBottom:'20px'}}>
          <h2 style={{fontSize:'20px',fontWeight:'bold',marginLeft:'10px'}}>{category.cat}</h2>
          {category.questions.map((question, quesIndex) => (
            <div key={quesIndex} style={{background:'white',padding:'20px'}}>
              <div >{quesIndex + 1}. {question?.title}</div>
              <div style={{marginBottom:'10px',paddingTop:'15px',paddingLeft:'10px'}}>
              <input type="number" value={question?.weight} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div> */}

    <div className="accordion" id="accordionExample" style={{ width: '95%', margin: 'auto', marginTop: '20px' }}>
      {frameWorkData !== undefined && frameWorkData !== null && Object.keys(frameWorkData).length > 0 ?
        <>
          {Object.entries(frameWorkData).map(([category, questions], catIndex) => (
            <div className="accordion-item" key={catIndex}>
              <h2 className="accordion-header" id={`heading${catIndex}`}>
                <button
                  className={`accordion-button ${catIndex === 0 ? 'collapsed' : ''}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${catIndex}`}
                  aria-expanded={catIndex === 0 ? 'true' : 'false'}
                  aria-controls={`collapse${catIndex}`}
                >
                  {category}
                </button>
              </h2>
              <div
                id={`collapse${catIndex}`}
                className={`accordion-collapse collapse ${catIndex === 0 ? 'show' : ''}`}
                aria-labelledby={`heading${catIndex}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  {questions.map((question, quesIndex) => (
                    <div key={quesIndex}>
                      {quesIndex + 1}. {question.question}
                      <div style={{ marginBottom: '10px', paddingTop: '15px', paddingLeft: '10px' }}>
                        <input type="number" value={question.weight} readOnly />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </>
        : ""}

      {/* {frameWorkData?.data?.map((category, catIndex) => (
          <div className="accordion-item" key={catIndex}>
            <h2 className="accordion-header" id={`heading${catIndex}`}>
              <button
                className={`accordion-button ${catIndex === 0 ? 'collapsed' : ''}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${catIndex}`}
                aria-expanded={catIndex === 0 ? 'true' : 'false'}
                aria-controls={`collapse${catIndex}`}
              >
                {category.cat}
              </button>
            </h2>
            <div
              id={`collapse${catIndex}`}
              className={`accordion-collapse collapse ${catIndex === 0 ? 'show' : ''}`}
              aria-labelledby={`heading${catIndex}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                {category.questions.map((question, quesIndex) => (
                  <div key={quesIndex}>
                    {quesIndex + 1}. {question.title}
                    <div style={{ marginBottom: '10px', paddingTop: '15px', paddingLeft: '10px' }}>
                      <input type="number" value={question.weight} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))} */}
    </div>
  </>
}

export default Framework
