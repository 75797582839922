import * as React from "react";
import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "src/content/dashboards/Crypto/PageHeader";
import { GridToolbar, renderActionsCell } from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import axios from "axios";
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColDef,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
// import { Helmet } from "react-helmet-async"
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import CommonTable from "src/common/table/table";
import DashboardAction from "src/action/DashboardAction";


const Admin = () => {
  const loggedin_Name = localStorage.getItem("login_data");
  const navigate = useNavigate();
  const parsedData = JSON.parse(loggedin_Name);
  const user = {
    name: parsedData?.data?.name,
    avatar: "/static/images/avatars/1.jpg",
  };
  const [queueSites, setQueueSites] = React.useState([]);
  const [updatedData, setUpdatedData] = React.useState([]);
  const [userLoading, setUserLoading] = useState();
  const [originalData, setOriginalData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0); // Current page index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecoard, setTotalRecoard] = useState(0);
  const [sortby, setSortBy] = useState("created_at");
  const [sort, setSort] = useState("desc");
  const [searchTerm, setSearchTerm] = useState(null);
  
  
  const baseUrl =process.env.REACT_APP_BASE_URL
  const apiEndpoint = `${baseUrl}/users?type=1`;



  const updateAdmin = (id) => {
    navigate(`/admin-management/updateAdmin/${id}`)
  }

  const renderActionCell = (params) => {
    const id = params.row._id;
    return <Tooltip title="Edit Admin" arrow onClick={() => updateAdmin(id)}  >
      <IconButton color="inherit" size="small">
        <EditTwoToneIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  };



  const columns = [
    { field: 'name', label: 'Admin Name', width: 240, align: 'left' },
    { field: 'email', label: 'Admin Email', width: 240, align: 'left' },
    { field: 'actions', label: 'Actions', width: 240, renderCell: renderActionCell, align: 'left' },
  ];

  // const columns = [
  //   // { field: "index", headerName: "ID", width: 150, headerClassName: "bold-header" },
  //   { field: "name", headerName: "User Name", headerClassName: "bold-header", width: 400, align: "left", headerAlign: "left", editable: false },
  //   { field: "email", headerName: "User Email", headerClassName: "bold-header", width: 400, align: "left", headerAlign: "left", editable: false },
  //   {field: "action", headerName: "Action", headerClassName: "bold-header", width: 400, align: "left", renderCell: renderActionCell, headerAlign: "left", editable: false},
  // ];

  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: 20 },
    { id: 6, lastName: "Melisandre", firstName: "Sahil", age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];

  const token = localStorage.getItem(`token`);

  const getAdminData = () => {
    setUserLoading(true);
    axios.get(`${baseUrl}/admins`, {
      headers: {
        'accept': '*/*',
        'Auth-Token': `${token}`,
      },
    }).then((res) => {
      const admindata = res?.data?.data;
      setQueueSites(res?.data?.data)
      const indexedUserData = admindata.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      setOriginalData(indexedUserData);
      setTotalRecoard(indexedUserData?.length)
      setUserLoading(false);
    }).catch((error) => {
      if (error.response && error.response.status === 401) {
        DashboardAction.checklogoutService()
    }
      setUserLoading(false);
      // console.log("Error in getting queue Data", error)
    })

  }

  // React.useEffect(()=>{
  //   axios.get(apiEndpoint,  {headers: {
  //       'accept': '*/*',
  //       'Auth-Token': `${token}`,
  //     },}).then((res)=>{
  //       console.log("Queue Sites are",res?.data?.data?.data);
  //       setQueueSites(res?.data?.data?.data)
  //     }).catch((error)=>{
  //       console.log("Error in getting queue Data",error)
  //     })
  // },[])

  useEffect(() => {
    getAdminData();
  }, []);

  // useEffect(() => {
  //   getAdminData();
  // }, [page, rowsPerPage])

  // React.useEffect(() => {
  //   if (queueSites?.length > 0) {
  //     const newData = queueSites.filter((item) => item.is_admin == 1);
  //     setUpdatedData(newData);
  //   }
  // }, [queueSites]);

  // useEffect(() => {
  //   if (updatedData) {
  //     const indexedUserData = updatedData.map((item, index) => ({
  //       ...item,
  //       index: index + 1,
  //     }));
  //     setOriginalData(indexedUserData);
  //     setTotalRecoard(indexedUserData?.length)
  //   }
  // }, [updatedData]);



  const handleSort = (sortby, sort) => {
    setSortBy(sortby);
    setSort(sort);
  };

  const handleSearch = (search) => {
    setSearchTerm(search)
    setPage(0);
  };


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    const data = parseInt(newRowsPerPage, 10);
    setRowsPerPage(data);
    setPage(0);
  }


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Users Found</Box>
      </StyledGridOverlay>
    );
  }

  function createAdmin() {
    navigate(`/admin-management/createAdmin`)
  }

  //   React.useEffect(() => {
  //     if (queueSites) {
  //       const capitalizeFirstLetter = (status) => {
  //         return status == 1 ? "Completed" : "Awaited"
  //       };

  //       const newData = queueSites.map((item, index) => ({
  //         id: index + 1,
  //         name: item.name,
  //         email: item.url,
  //         status: capitalizeFirstLetter(item.status),
  //       }));

  //       setUpdatedData(newData);
  //     }
  //   }, [queueSites]);

  return (
    <>
      <Helmet>
        <title>{user.name ? user.name : ""} </title>
      </Helmet>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                <div role="presentation" style={{ marginTop: '20px' }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      sx={{ display: 'flex', alignItems: 'center' }}
                      color="inherit"
                      href="/"
                    >
                      <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      Management
                    </Link>
                    <Typography
                      sx={{ display: 'flex', alignItems: 'center' }}
                      color="text.primary"
                    >
                      <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      Administrator
                    </Typography>
                  </Breadcrumbs>
                </div>
              </section>
              {/* <PageTitleWrapper>
                          <PageHeader title="Administrator" />
                        </PageTitleWrapper> */}
              {/* <div style={{ width: '95%', margin: 'auto' }}> */}
              <div className="iq-card position-relative rounded overflow-hidden">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Administrator</h4>
                  </div>
                  <div
                    className="input-group w-auto"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <div
                      className="input-group w-auto"
                      style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        padding: "10px",
                      }}
                    >
                      <div
                        className="custom-toolbar-button"
                        style={{ display: "inline-flex", gap: "10px" }}
                      >
                        
                        <Button className="common-black-btn" onClick={createAdmin}>
                          Create New Admin
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <Box sx={{ height: 500, width: "100%", background: "white", paddingBottom: '41px' }}>
                  <CommonTable
                    data={originalData}
                    columns={columns}
                    enablePagination
                    type={"admin"}
                    page={page}
                    loading={userLoading}
                    count={totalRecoard}
                    rowsPerPage={rowsPerPage}
                    handleSort={handleSort}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </div>
              {/* </div > */}
            </div >
          </div >
        </div >
      </div >
      {/* <div style={{ width: '95%', margin: 'auto' }}>
        <div
          className="input-group w-auto"
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            padding: "10px",
          }}
        >
          <div
            className="custom-toolbar-button"
            style={{ display: "inline-flex", gap: "10px" }}
          >
            <Button variant="contained" onClick={createAdmin}>
              Create New Admin
            </Button>
          </div>
        </div>
        <Box sx={{ height: 500, width: "100%", background: "white", paddingBottom: '41px' }}>
          <CommonTable
            data={originalData}
            columns={columns}
            enablePagination
            type={"admin"}
            page={page}
            count={totalRecoard}
            rowsPerPage={rowsPerPage}
            handleSort={handleSort}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </div> */}


      {/* <div style={{width:'95%',margin:'auto'}}>
        <Box
          sx={{
            height: originalData?.length == 0 ? 400 : "",
            backgroundColor: "white",
          }}
        >
          <DataGrid  slots={{
              toolbar: () => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <CustomToolbar />
                  <div
                    className="custom-toolbar-button"
                    style={{ display: "inline-flex", gap: "10px" }}
                  >
                    <Button variant="contained" onClick={createAdmin}>
                      Create New Admin
                    </Button>
                  </div>
                </div>
              ),
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={originalData && originalData}
            columns={columns}
              getRowId={(originalData) => originalData?.index}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[25, 30, 50]}
            disableDensitySelector
            // checkboxSelection
            disableRowSelectionOnClick
            // disableDensitySelector
            // hideSortIcons // Add this line to hide sort icons
            // disableColumnMenu // Add this line to disable column menu
          />
        </Box>
      </div> */}
    </>
  );
};

export default Admin;
