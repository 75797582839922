import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import GrainIcon from '@mui/icons-material/Grain';
import HomeIcon from '@mui/icons-material/Home';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import ResponsiveDialog from "src/common/DeleteDialogue";
import CommonUserModal from "src/common/commonUserModal";
import Footer from "src/components/Footer";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { Userdata } from 'src/models/crypto_order';
import CommonTable from "src/common/table/table";
import moment from 'moment';
import { ToastContainer } from 'react-toastify'
import { callApi, postData } from "src/action/RestClient";


const SearchLogsSites = () => {
    const [originalData, setOriginalData] = useState([])
    const loggedin_Name = localStorage.getItem("login_data");
    const parsedData = JSON.parse(loggedin_Name);
    const [isAuth, setIsAuth] = useState();
    const user = {
        name: parsedData?.data?.name,
        avatar: "/static/images/avatars/1.jpg",
    };
    // NEW CHANGE STARTS FROM HERE


    const updateUserData = async (data) => {
        // await getAllData();
        if (auth.current === true) {
            setIsAuth(true)
        } else {
            navigate(`/management/createSite`, { state: data });
        }
    };


    const formatDateCell = (params) => {
        const dateString = params?.value;
        const date = moment(dateString);
        const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
        const timeAgo = date.fromNow();
        return (
            <div>
                <p className="fw-bold">{formattedDate} <br></br><small className="fw-normal">({timeAgo})</small></p>
            </div>
        );
    };



    const columns = [
        // { field: 'index', label: 'ID', width: 100, align: 'left' },
        { field: 'site_url', label: 'SITE URL', width: 240, align: 'left' },
        { field: 'user_ip_address', label: 'IP ADDRESS', width: 240, align: 'left' },
        { field: 'created_at', label: 'Date', width: 240, renderCell: formatDateCell, align: 'left' },
    ];



    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [userDeleteId, setUserDeleteId] = useState();
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [userAnswer, setUserAnswer] = useState(false);
    const auth = React.useRef(false);
    const [userLoading, setUserLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [page, setPage] = useState(0); // Current page index
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRecoard, setTotalRecoard] = useState(0);
    const [sortby, setSortBy] = useState(null);
    const [sort, setSort] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);


    const getAllData = async () => {
        setUserLoading(true)
        try {
            let options = {};
            if (page !== undefined) options.page = page + 1;
            if (rowsPerPage !== undefined) options.perpage = rowsPerPage;
            if (sort !== null) options.sort = sort;
            if (sortby !== null) options.sortBy = sortby;
            if (searchTerm != null) options.searchTerm = searchTerm;
            const response = await callApi('GET', '/get/site-logs', null, options);
            const data = response?.data?.data;
            setUserData(data?.data);
            setTotalRecoard(data?.total);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };


    useEffect(() => {
        if (initialLoad) {
            getAllData();
            setInitialLoad(false);
        }
    }, [initialLoad]);


    useEffect(() => {
        if (!initialLoad) {
            getAllData();
        }
    }, [page, rowsPerPage, sort, sortby,])



    const handleSort = (sortby, sort) => {
        setSortBy(sortby);
        setSort(sort);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPage(data);
        setPage(0);
    }

  
    const slicedData = filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Sites
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Search Logs
                                        </Typography>
                                    </Breadcrumbs>
                                </div>
                            </section>
                            {/* <PageTitleWrapper>
                                <PageHeader title="Sites Onboarded" />
                            </PageTitleWrapper> */}
                            <div className="iq-card position-relative rounded overflow-hidden">
                                {/* <div style={{ display: "flex", justifyContent: "end", width: "95%" }}>
                                    <Button sx={{ margin: 1 }} variant="contained" onClick={createData}>
                                        Add site
                                    </Button>
                                    </div> */}
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Search Logs</h4>
                                    </div>
                                </div>

                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={userData}
                                        columns={columns}
                                        enablePagination
                                        type={"site"}
                                        loading={userLoading}
                                        page={page}
                                        count={totalRecoard}
                                        rowsPerPage={rowsPerPage}
                                        handleSort={handleSort}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
           
            <Footer />
           
        </>
    );
};
export default SearchLogsSites;
