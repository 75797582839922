import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useLocation } from "react-router";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { parseISO, format } from 'date-fns';
import PageHeader from "src/content/dashboards/Crypto/PageHeader";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet-async";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CommonTable from "src/common/table/table";
import moment from 'moment';
import { notify } from "src/common/toastMesaage";

const Download_Reports = () => {
  const [updatedData, setUpdatedData] = React.useState([]);
  const [queueSites, setQueueSites] = React.useState([]);
  const [page, setPage] = useState(0); // Current page index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userLoading, setUserLoading] = useState();
  const [totalRecoard, setTotalRecoard] = useState(0);
  const [sortby, setSortBy] = useState(null);
  const [sort, setSort] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL
  const location = useLocation();
  const slug = location.pathname.replace(
    "/site-management/downloadReports/",
    ""
  );
  const actualSlug = slug.replace("#", "");
  const token = localStorage.getItem(`token`);


  const getData = async () => {
    await axios
      .get(
        `${baseUrl}/sites/queue?id=${actualSlug}`,
        {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        }
      )
      .then((res) => {
        setQueueSites(res.data?.data);
      })
      .catch((error) => {
        console.log("Error occured in Requesting sites", error);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  const handleDownloadReport = (id) => {
    axios.get(`${baseUrl}/reports/${id}`, {
      headers: {
        accept: "*/*",
        "Auth-Token": `${token}`,
      },
    }).then((res) => {
      const link = document.createElement("a");
      link.download = `https://api.webalytics.checkbrand.companydemo.in${res?.data?.data?.url}`;
      link.href = `https://api.webalytics.checkbrand.companydemo.in${res?.data?.data?.url}`;
      link.target = "_blank";
      link.click();
      notify(`Report Generated Sucessfully`, "success")
    }).catch((error) => {
      console.log("Error in fetching Reports data", error);
      notify(`Error occured in generating reports.`, "error")
    })
  }
  const renderStatusCell = (params) => {
    const status = params.row.status;
    if (status == "Done") {
      return (
        <div className="table-yes-badge">
          <p>{status}</p>
        </div>
      );
    } else if (status == "Pending") { 
      return (
        <div className="table-no-badge">
          <p >{status}</p>
        </div>
      )
    } else {
      return <span>{status}</span>;
    }
  };

  const renderDowanloadCell = (params) => {
    const status = params.row.processed;
    if (status) {
      return <Button
        variant="contained"
        color="primary"
        disabled={params.row.processed == 0}
        onClick={() => handleDownloadReport(params?.row?.user_id)}
      >
        {params.row.processed == 1 ? "Download" : "Pending"}
      </Button>
    }

  }

  function formatDate(params) {
    const dateString = params?.row?.end_date;
    const date = moment(dateString, "MMMM DD, YYYY [at] HH:mm:ss");
    const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
    const timeAgo = date.fromNow();
    return (
      <div>
        <p className="fw-bold">{formattedDate} <br></br><small className="fw-normal">({timeAgo})</small></p>
      </div>
    );
  }

  const columns = [
    // { field: 'id', label: 'ID', width: 120 },
    { field: "name", label: <strong>Site Name</strong>, width: 250, align: "left", headerAlign: "left", editable: false, },
    { field: "url", label: <strong>Site Url</strong>, width: 250, align: "left", headerAlign: "left", editable: false, },
    { field: "status", label: <strong>Status</strong>, width: 250, align: "left", headerAlign: "left", renderCell: renderStatusCell, editable: false, },
    { field: "download", label: <strong>Download Report</strong>, width: 200, align: "left", headerAlign: "left", renderCell: renderDowanloadCell },
    { field: "end_date", label: <strong>Report Date</strong>, width: 350, align: "left", headerAlign: "left", editable: false, renderCell: formatDate },
  ];

  const columnss = [
    { field: 'id', headerName: 'ID', width: 120 },
    { field: "name", headerName: <strong>Site Name</strong>, width: 250, align: "left", headerAlign: "left", editable: false, },
    { field: "url", headerName: <strong>Site Url</strong>, width: 250, align: "left", headerAlign: "left", editable: false, },
    { field: "status", headerName: <strong>Status</strong>, width: 250, align: "left", headerAlign: "left", renderCell: renderStatusCell, editable: false, },
    {
      field: "download", headerName: <strong>Download Report</strong>, width: 200, align: "left", headerAlign: "left",
      renderCell: (params) => (<Button
        variant="contained"
        color="primary"
        disabled={params.row.processed == 0}
        onClick={() => handleDownloadReport(params?.row?.user_id)}
      >
        {params.row.processed == 1 ? "Download" : "Pending"}
      </Button>
      ),
    },
    { field: "end_date", headerName: <strong>Report Date</strong>, width: 350, align: "left", headerAlign: "left", editable: false, },
  ];


  React.useEffect(() => {
    if (queueSites) {
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      const convertEndDate = (string) => {
        try {
          const date = new Date(string);

          const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          };

          const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

          return formattedDate;
        } catch (error) {
          console.error("Error converting start date:", error);
          return "No Date found";
        }
      };

      const convertStartDate = (string) => {
        try {
          const date = new Date(string);

          const options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          };

          const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

          return formattedDate;
        } catch (error) {
          console.error("Error converting start date:", error);
          return "No Date found";
        }
      };



      const newData = queueSites.map((item, index) => ({
        id: index + 1,
        start_date: convertStartDate(item.start_at),
        end_date: convertEndDate(item.end_at),
        name: item.name,
        url: item.url,
        status: capitalizeFirstLetter(item.status),
        processed: item.processed,
        user_id: item?._id?.$oid
      }));

      setUpdatedData(newData);
    }
  }, [queueSites]);

  const loggedin_Name = localStorage.getItem("login_data");
  const parsedData = JSON.parse(loggedin_Name);
  const user = {
    name: parsedData?.data?.name,
    avatar: "/static/images/avatars/1.jpg",
  };

  const handleSort = (sortby, sort) => {
    setSortBy(sortby);
    setSort(sort);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    const data = parseInt(newRowsPerPage, 10);
    setRowsPerPage(data);
    setPage(0);
  }



  return (
    <>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                <div role="presentation" style={{ marginTop: '20px' }} >
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      sx={{ display: 'flex', alignItems: 'center' }}
                      color="inherit"
                      href="/site-management/getAllOnboardedSite"
                    >
                      <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      Sites
                    </Link>
                    <Link
                      underline="hover"
                      sx={{ display: 'flex', alignItems: 'center' }}
                      color="inherit"
                      href="/site-management/getAllOnboardedSite"
                    >
                      <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      Sites Onboarded
                    </Link>
                    <Typography
                      sx={{ display: 'flex', alignItems: 'center' }}
                      color="text.primary"
                    >
                      <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      Download Report
                    </Typography>
                  </Breadcrumbs>
                </div>
              </section>
              <div className="iq-card position-relative rounded overflow-hidden">
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}> Download Report </h4>
                  </div>
                </div>

                <Box sx={{ height: "auto", width: "100%", background: "white", paddingBottom: '41px' }}>
                  <CommonTable
                    data={updatedData}
                    columns={columns}
                    // enablePagination
                    type={"DownloadReport"}
                    page={page}
                    count={totalRecoard}
                    rowsPerPage={rowsPerPage}
                    handleSort={handleSort}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </div >
            </div >
          </div >
        </div >
      </div >
      {/* <Helmet>
        <title>{user.name} | National Web Quality Index</title>
      </Helmet>
      <section className="breadcrum-section" style={{marginTop:'20px',marginLeft:'20px'}}>
      <div role="presentation" style={{marginTop:'20px'}} >
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/site-management/getAllOnboardedSite"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Sites
        </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/site-management/getAllOnboardedSite"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Sites Onboarded
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Download Report
        </Typography>
      </Breadcrumbs>
    </div>
        </section>
        <section style={{marginBottom:'10px'}}>
      <PageTitleWrapper>
        <PageHeader title="DOWNLOAD REPORT" />
      </PageTitleWrapper>
      </section>
      <div
        style={{
          display: "block",
          width: "95%",
          margin: "auto",
        }}
      >
        <div
          className="tab-pane fade show active"
          id="content1"
          style={{
            background: "#fff",
            margin: "auto",
            width: "95%",
            margin: "auto",
            borderRadius: "20px",
            boxShadow: "0 0 52px #3d3d3d1f",
            border: "1px solid #dcdcdc",
          }}
        >
          <Box sx={{ height: 400, backgroundColor: "white" }} >
            <DataGrid slots={{ toolbar: GridToolbar }}
              rows={updatedData}
              columns={columnss}
              getRowId={(row) => row.id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              disableDensitySelector
              // checkboxSelection
              // disableRowSelectionOnClick
              // hideSortIcons // Add this line to hide sort icons
              disableColumnMenu // Add this line to disable column menu
            />
          </Box>
        </div>
      </div>
      <ToastContainer /> */}
    </>
  );
};

export default Download_Reports;
