import React, { useEffect, useState } from 'react';
import { Box,TextField,Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';

const About_us = () => {
  const columns = [
    { field: 'id', headerName: 'ID', },
    { field: 'Name', headerName: 'First name', },
  ];
  
  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];

  
  const [field,setField] = useState({
    name:''
  })
  const navigate = useNavigate();
  const [data,setData] = useState([])

  const [error, setError] = useState({
    nameError: false,
  });
  const handleChange=(e)=>{
    const {name,value} = e.target;
    setField({...field,[name]:value});

    setError((prevError) => ({
      ...prevError,
      nameError: name === "name" && value.length < 3,
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if there are any errors before posting the data
    if (
      error.nameError
    ) {
      console.log("Please fill in all fields correctly");
    } else {
      const newData = [...data, field];
      setData(newData);
      localStorage.setItem('nic_backend_about', JSON.stringify(newData));
      setField({
        name:''
      })
      navigate('/getAllAbout')
    }
  };
  useEffect(()=>{
    if(localStorage.getItem(`nic_backend_about`)){
      setData(JSON.parse(localStorage.getItem(`nic_backend_about`)))
    }
  },[])
  return <>
  <Box
        component="form"
        sx={{
          backgroundColor: "white",
          display: "flex",
          width: "90%",
          marginTop: "100px",
          marginLeft: "auto",
          marginRight: "auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          padding: "1rem",
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <h1>Add about us</h1>
        </div>
        <Box sx={{ display: "flex", gap: "2rem", width: "100%" }}>
          <TextField
            id="standard-multiline-flexible"
            placeholder="Enter your name"
            label="Name"
            multiline
            name="name"
            value={field.name}
            maxRows={4}
            variant="outlined"
            sx={{ flex: 1 }}
            onChange={handleChange}
            error={error.nameError}
            helperText={
              error.nameError ? "Name must be at least 3 characters long" : ""
            }
          />
         
        </Box>
        <Button onClick={handleSubmit}
          variant="contained"
          sx={{ margin: "30px" }}
          type="submit"
          disabled={
            error.nameError
          }
        >
          Add about
        </Button>

        {data.length>0 &&
      <>
      <div style={{ height: 400, width: '100%' }}>
      <DataGrid slots={{ toolbar: GridToolbar }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
      </>
      }
      </Box>
      
  </>
}

export default About_us
