import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo2 from '../../src/assets/images/new-logo-03.png';
import { ToastContainer } from "react-toastify";
import { notify } from "src/common/toastMesaage";



function ForgotPassword() {
    const navigate = useNavigate();
    const [login, setLogin] = useState({
        email: "",
        type: "A",
    });
    const [loading, setLoading] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [error, setError] = useState({
        emailError: "",
    });
   
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "email") {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(value)) {
                setError({ ...error, emailError: "Invalid email address" });
            } else {
                setError({ ...error, emailError: "" });
            }
        }
        setLogin({ ...login, [name]: value });
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        console.log("email", login?.email);
        let data = {
            email: login?.email,
          }
        setLoading(true)
        try {
            const response = await axios.post(`${baseUrl}api/forgot/password`,data);
            const res = response?.data;
            setLoading(false);
            if(res?.status){
                notify(res?.message, "success");
                navigate("/")
            }
            else{
                notify(res?.error, "error");
                errorToast(res?.error)
            }
          } catch (error) {
            notify("Error in email send:", "error");
            setLoading(false);
          }
    }


    return (
        <>
            <div className="bg-common-dark">
                <div className="FromArea">
                    <form className="form_container" onSubmit={handleSubmit}>
                        <div className="logo_container">
                            <img
                                src={Logo2} width={350}
                                alt="Logo"
                                loading="lazy"
                            />
                            {/* <h1 style={{fontWeight:'bold',fontSize:'0.85rem',padding:'10px',paddingBottom:'0px',color:'#222'}}>National Web Quality Index</h1> */}
                        </div>
                        <div className="title_container">
                            <p className="title">Find Your Account</p>
                            {/* <span>Find Your Account.</span> */}
                        </div>
                        <br />
                        <div className="input_container">
                            <label className="input_label" htmlFor="email">
                                Please enter email address to search for your account.
                            </label>
                            <i className="fa fa-envelope icon"></i>
                            <input
                                placeholder="name@mail.com"
                                title="Inpit title"
                                name="email"
                                type="text"
                                className="input_field"
                                id="email"
                                value={login.email}
                                onChange={handleChange}
                            />
                        </div>
                        {error.emailError && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "start",
                                    width: "100%",
                                    color: "red",
                                }}
                            >
                                <p>{error.emailError}</p>
                            </div>
                        )}
                        <button
                            title="Sign In"
                            type="submit"
                            className="sign-in_btn mt-3"
                            disabled={
                                !login?.email ||
                                error.emailError ||
                                loading
                            }
                            style={{
                                backgroundColor:
                                    (!login?.email || error.emailError || loading) && "gray",
                            }}
                        >
                            <span>{loading ? `Loding...` : "Submit"}</span>
                        </button>
                        <div className="bottomDesign">
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default ForgotPassword;
