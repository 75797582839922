import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate, useParams } from "react-router";
import CommonTable from "src/common/table/table";
import moment from "moment";
import { Box, Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { callApi, postData } from "src/action/RestClient";
import StreetviewIcon from '@mui/icons-material/Streetview';
import MySwitchComponent from "./switchComonent";
import HistoryIcon from '@mui/icons-material/History';
import { ToastContainer } from 'react-toastify'
import { notify } from "src/common/toastMesaage";

const MileStoneList = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [milestonelist, setMileStoneList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);


    const getMilestoneData = async () => {
        const id = params?.id ? params?.id : milestonelist[0]?.site_id?.$oid.
            setLoading(true)
        const response = await callApi('GET', `/getMilestones/${params?.id}`, null, null);
        setLoading(false)
        const data = response?.data?.data;
        setMileStoneList(data)
    }

    useEffect(() => {
        getMilestoneData();
    }, [params])


    const formatDateCell = (params) => {
        const dateString = params?.value;
        const date = moment(dateString);
        const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
        const timeAgo = date.fromNow();
        return (
            <div>
                <p className="fw-bold">{formattedDate} <br></br><small className="fw-normal">({timeAgo})</small></p>
            </div>
        );
    };


    const renderActionsCell = (params) => {
        const user = params.row;
        return (
            <div>
                <Tooltip
                    title={`MileStone Report`}
                    arrow
                    onClick={() => handleMileStoneReport(user)}
                >
                    <IconButton color="inherit" size="small">
                        <StreetviewIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={`MileStone History`}
                    arrow
                    onClick={() => handleMileStoneHistory(user)}
                >
                    <IconButton color="inherit" size="small">
                        <HistoryIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const handleMileStoneReport = (user) => {
        navigate(`/site-management/mileStoneReport`, { state: { data: user, flag: "update" } })
    }

    const handleMileStoneHistory = (user) => {
        navigate(`/site-management/mileStoneHistory`, { state: user?.milestone?.history })
    }

    const formatStatusCell = (params) => {
        const user = params.row;
        return (
            <div>
                <MySwitchComponent data={user} getMilestoneData={getMilestoneData} />
            </div>
        );
    }

    const columns = [
        { field: 'name', label: 'Site NAME', width: 240, align: 'left' },
        { field: 'description', label: 'DESCRIPTION', width: 240, align: 'left' },
        { field: 'url', label: 'WEBSITE', width: 240, align: 'left' },
        { field: 'created_at', label: 'MILESTONE CREATED DATE', width: 240, renderCell: formatDateCell, align: 'left' },
        { field: 'status', label: 'status', width: 240, renderCell: formatStatusCell, align: 'left' },
        { field: 'actions', label: 'Actions', width: 240, renderCell: renderActionsCell, align: 'left' },
    ];

    const handleCreate = async (id) => {

        const data = { siteId: params?.id }
        const url = '/createMilestones';
        try {
            setCreateLoading(true)
            const response = await postData(url, data);
            setCreateLoading(false)
            if (response?.status) {
                const sitedata = response?.data
                navigate(`/site-management/mileStoneReport`, { state: { data: sitedata, flag: "create" } })
            } else {
                notify(response?.error, "error");
            }
        } catch (error) {
            setCreateLoading(false)
            console.error('Error:', error);
        }
    }

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href={params?.name === "top" ? "/site-management/getAllTopSite" : "/site-management/getAllUsersSite" }
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            site-management
                                        </Link>
                                    </Breadcrumbs>
                                </div>
                            </section>
                            <div className="iq-card position-relative rounded overflow-hidden">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Milestone List </h4>
                                    </div>
                                    <div className="align-items-center custom-toolbar-button d-flex gap-3 justify-content-end"  >
                                        {/* {createLoading ? <> {"..loading"} </> : */}
                                        <Button className="common-black-btn"
                                            onClick={() => handleCreate(params?.id)}
                                        >
                                            {createLoading ?
                                                <Box  gap-3 sx={{ color: "white", width: "100px"}}>
                                                    <CircularProgress color="inherit" size={18} />
                                                </Box>
                                                :
                                                "Create MileStone"}
                                        </Button>
                                        {/* } */}
                                    </div>

                                </div>


                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={milestonelist}
                                        columns={columns}
                                        // enablePagination
                                        type={"managePackages"}
                                        loading={loading}
                                    // page={page}
                                    // count={totalRecoard}
                                    // rowsPerPage={rowsPerPage}
                                    // handleSort={handleSort}
                                    // onPageChange={handlePageChange}
                                    // onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
            <ToastContainer />
        </>
    );
};
export default MileStoneList;
