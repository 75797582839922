export const  getTitle = (dashboardIdentifier) => {
  switch (dashboardIdentifier) {
    case "dashboard":  return "Dashboard";
    case "systemHealth":  return "System Health";
    case "lastLoggedIn": return "Last Logged In" ;
    case "userAccounts":  return "User Accounts";
    case "agencyAccounts":  return "Agency Accounts";
    case "pendingAgencyAccounts":  return "Pending Agency Accounts";
    case "requestAgencyAccounts":  return "Request Agency Accounts";
    case "updateRequestedAgencyAccount":  return "Update Requested Agency Account";
    case "processedHistory":  return "Processed History";
    case "upcomingQueue":  return "Upcoming Queue";
    case "manualProcessing":  return "Manual Processing";
    case "performance":  return "Performance";
    case "allReports":  return "All Reports";
    case "overview":  return "Over View";
    case "managePackages":  return "Manage Packages";
    case "managePackage":  return "Manage Packages";
    case "upcomingInvoices":  return "Upcoming Invoices";
    case "paidInvoices":  return "Paid Invoices";
    case "delayedInvoices":  return "Delayed Invoices";
    case "questionnaire":  return "Questionnaire";
    case "questions":  return "Questions";
    case "scoring":  return "Scoring";
    case "rolesAndPermissions":  return "Roles And Permissions";
    case "configuration":  return "Configuration";
    case "admin":  return "Administrator";
    case "updateAdmin":  return "Administrator";
    case "createAdmin":  return "Administrator";
    case "getAllUsers":  return "Users";
    case "userProfile":  return "Users";
    case "getSearchLogs":  return "Search Logs";
    case "createUser":  return "Users";
    case "getAllTopSite":  return "Top Sites";
    case "getAllUsersSite":  return "Users Sites";
    case "getAllOnboardedSite":  return "Sites";
    case "createSite":  return "Sites";
    case "updateSite":  return "Sites";
    case "bulkUpload":  return "Bulk Upload";
    case "currentQueueSites":  return "Upcoming Queue";
    case "hisoricalQueueSites":  return "Processed History";
    case "freeText":  return "Free Text Mappings";
    case "addSetting":  return "Add Setting";
    case "framework":  return "Questionnaire";
    case "downloadReports":  return "Download Reports";
    case "userQueueList":  return "User Queue List";
    case "mileStoneReportList":  return "MileStone ReportList";
    case "mileStoneReport":  return "MileStone Report";
    case "mileStoneHistory":  return "MileStone History";
    case "createMileStoneReport":  return "MileStone Report";
    case "uncompletedPurchases":  return "UnCompleted Purchases";
    case "uncompletedPurchaseDetails":  return "UnCompleted Purchase";
    case "unCompletedPurchasesDetails" : return "UnCompleted Purchases";
    case "manageRanking":  return "Manage Ranking";
    case "manageTaxes" : return "Taxes";
    case "manageTax" : return "Taxes";
    case "updateQuestionnaire" : return "Update Questionnaire";
    case "userSubscriptionDetail" : return "Subscription Detail";
    case "paymentDetails" : return "Payment Details";
    case "invoiceDetailsList" : return "Invoices";
    case "invoiceDetails" : return "Invoice Details";
    case "customPackage" : return "Custom Packages";userAssignDetail
    case "userAssignDetail" : return "User Assign packages Detail";
    default:  return "Dashboard";
  }
};

