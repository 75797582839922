import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { postData } from 'src/action/RestClient';


export const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '1000ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const MySwitchComponent = (params) => {
    const data = params?.data
    const [isChecked, setIsChecked] = useState(false);


    const toggleButton = (status, queueId) => {
        setIsChecked((prev) => !prev);
        statusChange(status, queueId)
    };

    useEffect(() => {
        if (data?.milestone?.status == "active") {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }
    }, [data?.milestone?.status]);


    const statusChange = async (status, queueId) => {
        const data = {
            status: status,
            queueId: queueId
        }
        try {
            const url = '/updateMilestoneStatus';
            const response = await postData(url, data);
            if (response?.status) {
                params?.getMilestoneData();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <div>
            <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }}
                     checked={isChecked}
                    // defaultChecked={isChecked}
                    onChange={() => toggleButton(data?.milestone?.status == "active" ? "inActive" : "active", data?.queue_id)}
                />}
            />
        </div>
    )
};

export default MySwitchComponent;