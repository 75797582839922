import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "src/content/dashboards/Crypto/PageHeader";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from '@mui/material';
import { ToastContainer } from "react-toastify";
import { postData } from 'src/action/RestClient';
import { notify } from 'src/common/toastMesaage';
const CreateAdmin = () => {
    const loggedin_Name = localStorage.getItem("login_data");
    const parsedData = JSON.parse(loggedin_Name);
    const [data,setData] = useState();
    const [sucessStatus,setSucessStatus] = useState('');
    const [errorStatus,setErrorStatus] = useState(``);
    const user = {
      name: parsedData?.data?.name,
      avatar: "/static/images/avatars/1.jpg",
    };
    const [adminField,setAdminField] = useState({
        name:'',
        email:'',
        password:'',
        password_confirmation:"",
        is_admin:1
    })
    const [error, setError] = useState({
        nameError: false,
        emailError: false,
        passwordError: false,
        passwordConfirmationError: false,
      });
      const [loading,setLoading] = useState(false)

      const baseUrl =process.env.REACT_APP_BASE_URL
    const handleChange = (e) =>{
        const { name, value} = e.target;
        setAdminField({...adminField,[name]: value});

        setError((prevError) => ({
            ...prevError,
            nameError: name === "name" && value.length < 3,
          }));
      
          // Validate email using regex
          setError((prevError) => ({
            ...prevError,
            emailError:
              name === "email" &&
              !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value),
          }));
      
          if (name === "password") {
            setError((prevError) => ({
              ...prevError,
              passwordError:
                value.length > 0 && (value.length < 6 || value.length > 20),
            }));
          }
      
          if (name === "password_confirmation") {
            setError((prevError) => ({
              ...prevError,
              passwordConfirmationError: value !== adminField.password,
            }));
          }
    }

    const postAdminData = async() =>{
      setLoading(true)
      try {
        const data = adminField
         const url = '/register/admins';
         const res = await postData(url, data);
         setData(res?.data?.data)
        setLoading(false)
        if(res?.data?.status == true){
                notify("Admin Created Sucessfully","success");
                setAdminField({
                    name:'',
                    email:'',
                    password:'',
                    password_confirmation:"",
                    is_admin:1
                })
            }
            else if(res?.error?.email[0] == 'The email has already been taken.'){
                notify("Email Already Registered","error")
            }
            else{
                notify(res?.error?.email[0],'error');
            }
    } catch (error) {
        console.error('Error:', error);
        setLoading(false)
    }
    }
    const handleSubmit = (e) =>{
        e.preventDefault();
        if (
            error.nameError ||
            error.emailError ||
            error.passwordError ||
            error.passwordConfirmationError
          ) {
            console.log("Please fill correct data")
          } else {
            postAdminData();
          }
    }

    React.useEffect(()=>{
       
    },[data])
  return <>
  <Helmet>
        <title>{user.name ? user.name : ""} </title>
      </Helmet>
      <section className="breadcrum-section" style={{marginTop:'20px',marginLeft:'20px'}}>
      <div role="presentation" style={{marginTop:'20px'}}>
      <Breadcrumbs aria-label="breadcrumb">
        {/* <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/management/getAllUsers"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Management
        </Link> */}
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/admin-management/admin"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Admin
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Create New Admin 
        </Typography>
      </Breadcrumbs>
    </div>
        </section>
      <PageTitleWrapper>
        <PageHeader title="Create New Admin" />
      </PageTitleWrapper>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" , width:'95%',margin:'auto' ,marginTop:"20px", backgroundColor:'white',padding:'40px'}}>
      <TextField
        label="Name"
        name="name"
        value={adminField.name}
        variant="outlined"
        onChange={handleChange}
        error={error.nameError}
        helperText={
          error.nameError ? "Name must be at least 3 characters long" : ""
        }
      />
      <TextField
        label="Email"
        name="email"
        value={adminField.email}
        variant="outlined"
        onChange={handleChange}
        error={error.emailError}
        helperText={error.emailError ? "Invalid email format" : ""}
      />
       <TextField
        label="Password"
        name="password"
        value={adminField.password}
        variant="outlined"
        onChange={handleChange}
        error={error.passwordError}
        helperText={
          error.passwordError ? "Password must be 6 characters long" : ""
        }
      />
      <TextField
        label="Password Confirmation"
        name="password_confirmation"
        value={adminField.password_confirmation}
        variant="outlined"
        onChange={handleChange}
        error={error.passwordConfirmationError}
        helperText={error.passwordConfirmationError ? "Password mismatch" : ""}
      />
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
       <Button
          onClick={handleSubmit}
          className="common-black-btn"
          variant={"contained"}
          // sx={{
          //   marginTop: "2rem",
          //   backgroundColor: "#4CAF50", // Add your desired button color
          //   color: "white", // Add your desired text color
          // }}
          disabled={
            !adminField.name ||
            !adminField.email ||
            !adminField.password ||
            !adminField.password_confirmation ||
            error.nameError ||
            error.emailError ||
            error.passwordError || 
            error.passwordConfirmationError
          }
        >
          {loading ? `Creating...` : `Create Admin`}
        </Button>
        </div>
    </Box>
    <ToastContainer />

  </>
}

export default CreateAdmin
