import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import CommonTable from "src/common/table/table";
import { useLocation, useNavigate } from "react-router";
import { postApi, postData } from "src/action/RestClient";
import { notify } from "src/common/toastMesaage";
import { ToastContainer } from 'react-toastify'


const UpdateQuestion = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedQuestion, setSelectedQuestion] = useState({
        question_id: "",
        question: "",
        weight: "",
        recommendation_pass: "",
        business_Implication_pass: "",
        recommendation_fail: "",
        business_Implication_fail: "",
        status: ""
    });
    const [selctedQuestionId, setSelctedQuestionId] = useState();
    const [selectCategory, setSelectCategory] = useState();
    const [index, setIndex] = useState();
    const [loading, setLoading] = useState(false);

    const handleChangeField = (e) => {
        const { name, value } = e.target;
        setSelectedQuestion({ ...selectedQuestion, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = {
                category: selectCategory,
                question_id: selectedQuestion?.question_id,
                question: selectedQuestion?.question,
                weight: selectedQuestion?.weight,
                recommendation_pass: selectedQuestion?.recommendation_pass,
                business_Implication_pass: selectedQuestion?.business_Implication_pass,
                id: selctedQuestionId,
                recommendation_fail: selectedQuestion?.recommendation_fail,
                business_Implication_fail: selectedQuestion?.business_Implication_fail,
                status: selectedQuestion?.status || "active",
            };
            setLoading(true);
            const url = '/update/questions';
            const response = await postData(url, data);
    
            if (response?.status) {
                notify("Question updated successfully", "success");
                setTimeout(() => {
                    navigate(`/algorithm/questionnaire`, { state: { selectedIndex: index } });
                }, 2000); 
                // navigate(`/algorithm/questionnaire`, { state: { selectedIndex: index } });
                // setSelectedQuestion("");
                // getQuestionData();
                // changeIndex(index);
                // onClose();
            } else {
                notify("Question couldn't be updated", "error");
            }
        } catch (error) {
            console.error('Error:', error);
            notify("Question couldn't be updated", "error");
        } finally {
            setLoading(false);
        }
    };
    

    const handleClose = () => {
        setSelectedQuestion("")
        onClose();
    }

    useEffect(() => {
        if (location.state) {
            const question = location?.state?.question;
        setSelectedQuestion({
            question_id: question?.question_id,
            question: question?.question ? question?.question : "",
            weight: question?.weight ? question?.weight : "",
            recommendation_pass: question?.recommendation_pass ? question?.recommendation_pass : "",
            business_Implication_pass: question?.business_Implication_pass ? question?.business_Implication_pass : "",
            recommendation_fail: question?.recommendation_fail ? question?.recommendation_fail : "",
            business_Implication_fail: question?.business_Implication_fail ? question?.business_Implication_fail : "",
            status: question?.status ? question?.status : "",
        });
        setSelctedQuestionId(location?.state?.questionId);
        setSelectCategory(location?.state?.category);
        setIndex(location?.state?.catIndex)
        // delete location.state;
        }
    }, [location.state])

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/algorithm/questionnaire"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Questions
                                        </Link>

                                    </Breadcrumbs>
                                </div>
                            </section>

                            <div className="iq-card position-relative rounded overflow-hidden">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Update Question</h4>
                                    </div>
                                    <div className="align-items-center custom-toolbar-button d-flex gap-3 justify-content-end"  >
                                    </div>
                                </div>
                                <Box sx={{ width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <div className="FormsAreaNew container">
                                        <div className="tab-content ">
                                            <form onSubmit={handleSubmit} >
                                                <Box className='create_package_outer'
                                                    component="div"
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <Box className='form-field-box flex-wrap d-flex'>
                                                    <TextField
                                                            className='modalField'
                                                            label="Question name"
                                                            placeholder="Question name"
                                                            multiline
                                                            name="question"
                                                            value={selectedQuestion?.question}
                                                            maxRows={4}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                         <TextField
                                                            className='modalField'
                                                            label="Question weight"
                                                            placeholder="Enter question weight"
                                                            multiline
                                                            name="weight"
                                                            value={selectedQuestion?.weight}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                        <TextField
                                                            className='modalField'
                                                            label="Recommendation for pass"
                                                            placeholder="Enter recommendation for pass"
                                                            multiline
                                                            name="recommendation_pass"
                                                            value={selectedQuestion?.recommendation_pass}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                        <TextField
                                                            className='modalField'
                                                            label="Recommendation for fail"
                                                            placeholder="Enter recommendation for fail"
                                                            multiline
                                                            name="recommendation_fail"
                                                            value={selectedQuestion?.recommendation_fail}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                        <TextField
                                                            className='modalField'
                                                            label="Business implication for pass"
                                                            placeholder="Enter business implication for pass"
                                                            multiline
                                                            name="business_Implication_pass"
                                                            value={selectedQuestion?.business_Implication_pass}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                        
                                                        <TextField
                                                            className='modalField'
                                                            label="Business implication for fail"
                                                            placeholder="Enter business implication for fail"
                                                            multiline
                                                            name="business_Implication_fail"
                                                            value={selectedQuestion?.business_Implication_fail}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                    </Box>
                                                    <div className='text-center pt-4'>
                                                        {loading ?
                                                            <Button
                                                                className="common-black-btn"
                                                                variant="contained"
                                                                type='button'
                                                            >
                                                                <Box gap-3 sx={{ color: "white", width: "100px" }}>
                                                                    <CircularProgress color="inherit" size={18} />
                                                                </Box>
                                                            </Button>
                                                            :
                                                            <Button
                                                                className="common-black-btn"
                                                                variant="contained"
                                                                type="submit"
                                                            >
                                                                Save changes
                                                            </Button>
                                                        }
                                                    </div>
                                                </Box>
                                            </form>

                                        </div>
                                    </div>

                                </Box>

                            </div >
                        </div >
                    </div >
                </div >
            </div >
            <ToastContainer />
        </>
    );
};
export default UpdateQuestion;
