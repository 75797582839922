import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import CommonTable from "src/common/table/table";
import { useLocation } from "react-router";
import { callApi, postApi } from "src/action/RestClient";
import { notify } from "src/common/toastMesaage";
import { ToastContainer } from 'react-toastify'


const CreateManageTax = () => {
    const location = useLocation();
    const [updateTaxData, setUpdateTaxData] = useState({});
    const [userLoading, setUserLoading] = useState();
    const [page, setPage] = useState("create");
    const [updateId, setUpdateId] = useState();
    const [status, setStatus] = useState();
    const [formData, setFormData] = useState({
        name: '',
        percentage: '',
        description: '',
    });
    const [error, setError] = useState({
        nameError: false,
        percentageError: false,
        descriptionError: false,
    });
    const [initialLoad, setInitialLoad] = useState(true);
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsState, setSuggestionsState] = useState([]);
    const [countryName, setCountryName] = useState("");
    const [stateName, setStateName] = useState("");
    const [countryId, setCountyId] = useState("");
    const [stateId, setStateId] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [stateCode, setStateCode] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "country_Name") {
            filteredCountrie(value);
        }
        if (name === "stateName") {
            filteredState(value);
        }

    };

    const filteredCountrie = (value) => {
        setCountryName(value);
        const countryName = countryData?.filter((country) =>
            country?.country_name?.toLowerCase().includes(value.toLowerCase())
        ).slice(0, 5);
        setSuggestions(countryName)
    }

    const filteredState = (value) => {
        setStateName(value);
        const stateName = stateData?.filter((country) =>
            country?.name?.toLowerCase().includes(value.toLowerCase())
        ).slice(0, 5);
        setSuggestionsState(stateName)
    }


    const getStateName = async (id) => {
        setUserLoading(true)
        try {
            const response = await callApi('GET', `/get-all-states-by-country/${id}`, null, null);
            const data = response?.data?.data;
            setStateData(data);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };


    const handleSelect = (country) => {
        setCountryName(country?.country_name);
        setCountryCode(country?.country_code)
        setCountyId(country?._id);
        getStateName(country?._id);
        setSuggestions([]);
        setStateName("");
        setStateCode("")
        setStateId("");
        setSuggestionsState([]);
    };

    const handleStateSelect = (state) => {
        setStateName(state?.name);
        setStateCode(state?.code)
        setStateId(state?._id);
        setSuggestionsState([]);
    }
    const handleChangeField = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        if (name === 'name') {
            if (value.length < 3 || value.length > 30) {
                setError((prevError) => ({ ...prevError, nameError: true }));
            } else {
                setError((prevError) => ({ ...prevError, nameError: false }));
            }
        }
        if (name === 'percentage') {
            if (value <= 0 || value.toString().length > 15) {
                setError((prevError) => ({ ...prevError, percentageError: true }));
            } else {
                setError((prevError) => ({ ...prevError, percentageError: false }));
            }
        }
        if (name === 'description') {
            if (value <= 0) {
                setError((prevError) => ({ ...prevError, descriptionError: true }));
            } else {
                setError((prevError) => ({ ...prevError, descriptionError: false }));
            }
        }
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
       
    //     let data = {
    //         name: formData?.name,
    //         percentage: formData?.percentage,
    //         description: formData?.description,
    //         inclusive: "false",
    //         status: "active",
    //         country: countryCode,
    //         countryId: countryId,
    //         taxType: 'all',
    //     };
    //     if (stateCode && stateId) {
    //         data = { ...data, state: stateCode, stateId: stateId , taxType: 'state' };
    //     }
    //     if (page == "create") {
    //         console.log("data",data)
    //         const url = '/create/tax';
    //         setLoading(true);
    //         const response = await postApi(url, data);
    //         if (response?.status) {
    //             notify("Tax Created Sucessfully", "success");
    //             setFormData({ name: "", description: "", percentage: "" });
    //             setLoading(false);
    //         }
    //         else {
    //             notify(response?.error, "error");
    //             setLoading(false);
    //         }
    //     }
    //     else {
    //         const updateData = { ...data, status: updateTaxData?.status, id: updateTaxData?._id, stripeTaxId: updateTaxData?.stripeTaxId }
    //         const url = `/update/tax`;
    //         setLoading(true);
    //         const response = await postApi(url, updateData);
    //         if (response?.status) {
    //             notify("Tax Update Sucessfully", "success");
    //             setLoading(false);
    //         }
    //         else {
    //             notify(response?.error, "error");
    //             setLoading(false);
    //         }
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        let data = {
          name: formData?.name,
          percentage: formData?.percentage,
          description: formData?.description,
          inclusive: "false",
          status: "active",
          country: countryCode,
          countryId: countryId,
          taxType: 'all',
        };
      
        if (stateCode && stateId) {
          data = { ...data, state: stateCode, stateId: stateId, taxType: 'state' };
        }
      
        setLoading(true);
        try {
          let response;
          const url = page === "create" ? '/create/tax' : '/update/tax';
          const payload = page === "create" ? data : { ...data, status: updateTaxData?.status, id: updateTaxData?._id, stripeTaxId: updateTaxData?.stripeTaxId };
      
          response = await postApi(url, payload);
      
          if (response?.status) {
            notify(`Tax ${page === "create" ? "Created" : "Updated"} Successfully`, "success");
            if (page === "create") {
              setFormData({ name: "", description: "", percentage: "" });
            }
          } else {
            throw new Error(response?.error);
          }
        } catch (error) {
          notify(error.message || "An error occurred", "error");
        } finally {
          setLoading(false);
        }
      };
      


    useEffect(() => {
        if (location.state) {
            const stateData = location?.state?.data;
            console.log("stateData", stateData)
            setUpdateTaxData(stateData);
            setPage(location?.state?.type);
            setStateCode(stateData?.state)
            setStateId(stateData?.stateId);
            setCountryCode(stateData?.country)
            setCountyId(stateData?.countryId);
            setFormData({
                name: stateData.name || "",
                percentage: stateData.percentage || "",
                description: stateData.description || "",
            });
            delete location.state;
        }
    }, [location.state]);


    const getAllCountry = async () => {
        setUserLoading(true)
        try {
            const response = await callApi('GET', "/get-all-countries", null, null);
            const data = response?.data?.data;
            setCountryData(data);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };


    useEffect(() => {
        if (initialLoad) {
            getAllCountry();
            setInitialLoad(false);
        }
    }, [initialLoad]);

    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/billingAndSubscriptions/manageTaxes"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Manage Taxeses
                                        </Link>

                                    </Breadcrumbs>
                                </div>
                            </section>

                            <div className="iq-card position-relative rounded overflow-hidden manage-taxes-card">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Manage Taxes</h4>
                                    </div>
                                    <div className="align-items-center custom-toolbar-button d-flex gap-3 justify-content-end"  >
                                    </div>
                                </div>
                                <Box sx={{ width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <div className="FormsAreaNew container">
                                        <div className="tab-content ">
                                            <form onSubmit={handleSubmit} >
                                                <Box className='create_package_outer'
                                                    component="div"
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    {page == "create" ? 
                                                    <Box className='form-field-box m-0'>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <h4 className="card-title fw-bold text-nowrap me-2" style={{ fontSize: "1.1rem" }}> {"Select County Name :"}</h4>
                                                                    <div className="form-group position-relative">
                                                                        <input
                                                                            id="country_Name"
                                                                            type="text"
                                                                            className="form-control form-control-lg"
                                                                            name="country_Name"
                                                                            placeholder="Country Name"
                                                                            required="required"
                                                                            value={countryName}
                                                                            onChange={handleChange}
                                                                            autoComplete="off"
                                                                        />
                                                                        {countryName && suggestions.length > 0 && (
                                                                            <div className="suggestion-boxState w-100">
                                                                                {/* Render suggestions here */}
                                                                                <ul>
                                                                                    {suggestions.map((country, index) => (
                                                                                        <li key={index} onClick={() => handleSelect(country)}>
                                                                                            {country.country_name}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group ">
                                                                    <h4 className="card-title fw-bold text-nowrap me-2" style={{ fontSize: "1.1rem" }}> {"Select State Name :"}</h4>
                                                                    <div className="form-group position-relative">
                                                                        <input
                                                                            id="stateName"
                                                                            type="text"
                                                                            className="form-control form-control-lg"
                                                                            name="stateName"
                                                                            placeholder="State Name"
                                                                            // required="required"
                                                                            value={stateName}
                                                                            onChange={handleChange}
                                                                            autoComplete="off"
                                                                        />
                                                                        {stateName && suggestionsState.length > 0 && (
                                                                            <div className="suggestion-boxState w-100">
                                                                                <ul>
                                                                                    {suggestionsState.map((country, index) => (
                                                                                        <li key={index} onClick={() => handleStateSelect(country)}>
                                                                                            {country?.name}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Box>
                                                    : ""}
                                                    <Box className='form-field-box m-0'>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <TextField
                                                                    placeholder="Enter your tax Name"
                                                                    label="Tax Name"
                                                                    name="name"
                                                                    type="taxt"
                                                                    className="form-control mb-3"
                                                                    value={formData.name}
                                                                    variant="outlined"
                                                                    sx={{ flex: 1 }}
                                                                    onChange={handleChangeField}
                                                                    error={error.nameError}
                                                                    helperText={
                                                                        error.nameError
                                                                            ? " Taxt name must be at gratet than 3 and less than 30 character long"
                                                                            : ""
                                                                    }
                                                                />

                                                            </div>
                                                            <div className="col-md-6">
                                                                <TextField
                                                                placeholder="Enter your tax percentage"
                                                                label="Tax Percentage"
                                                                type="number"
                                                                className="form-control mb-3"
                                                                name="percentage"
                                                                value={formData.percentage}
                                                                variant="outlined"
                                                                sx={{ flex: 1 }}
                                                                onChange={handleChangeField}
                                                                error={error.percentageError}
                                                                helperText={
                                                                    error.percentageError
                                                                        ? " percentage must be grater than 0 and less than 15 digit"
                                                                        : ""
                                                                }
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <TextField
                                                                placeholder="Enter your tax description"
                                                                label="Tax Description"
                                                                type="taxt"
                                                                className="form-control mb-3"
                                                                name="description"
                                                                value={formData.description}
                                                                variant="outlined"
                                                                sx={{ flex: 1 }}
                                                                onChange={handleChangeField}
                                                                error={error.descriptionError}
                                                                helperText={
                                                                    error.descriptionError
                                                                        ? "Please enter description"
                                                                        : ""
                                                                }
                                                                />
                                                            </div>
                                                        </div>
                                                    </Box>
                                                    <div className='text-center pt-4'>
                                                        {loading ?
                                                            <Button
                                                                className="common-black-btn"
                                                                variant="contained"
                                                                type='button'
                                                            >
                                                                <Box gap-3 sx={{ color: "white", width: "100px" }}>
                                                                    <CircularProgress color="inherit" size={18} />
                                                                </Box>
                                                            </Button>
                                                            :
                                                            <Button
                                                                className="common-black-btn"
                                                                variant="contained"
                                                                type='submit'
                                                                disabled={
                                                                    !formData.name ||
                                                                    !formData.description ||
                                                                    !formData.percentage ||
                                                                    error.nameError ||
                                                                    error.percentageError ||
                                                                    error.descriptionError
                                                                }
                                                            >
                                                                {page == "update" ? "Update Tax Packages" : "Create Tax Packages"}
                                                            </Button>
                                                        }
                                                    </div>
                                                </Box>
                                            </form>

                                        </div>
                                    </div>

                                </Box>

                            </div >
                        </div >
                    </div >
                </div >
            </div >
            <ToastContainer />
        </>
    );
};
export default CreateManageTax;
