import { useContext, useEffect, useRef, useState } from 'react';

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {
  Box,
  IconButton,
  Tooltip,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { SidebarContext } from 'src/contexts/SidebarContext';

import HeaderUserbox from './Userbox';
import { useLocation } from 'react-router-dom';
import { getTitle }from './getTitle';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        transition: padding 0.3s ease, height 0.3s ease, box-shadow 0.3s ease;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const location = useLocation();
  const [border, showBorder] = useState(true);
  const theme = useTheme();
  const headerRef = useRef(null);
  const currentUrl = location.pathname;
  
  const dashboardIdentifier = currentUrl.split('/')[2];

  const title = getTitle(dashboardIdentifier);
  

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        showBorder(false);
      } else {
        showBorder(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs once on mount


  return (
    <HeaderWrapper id='header'
      display="flex"
      alignItems="center"
      sx={{ background: border ? '#f2f5f9' : '#fff',
      paddingTop: border ? '10px' : "0px",
      height: border ? '80px' : "60px",
      // borderRadius: border ? '20px': "0px",
        boxShadow: border ? 'none' : '0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)'
          // theme.palette.mode === 'dark'
          //   ? `0 1px 0 ${alpha(
          //       lighten(theme.colors.primary.main, 0.7),
          //       0.15
          //     )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
          //   : `0px 2px 8px -3px ${alpha(
          //       theme.colors.alpha.black[100],
          //       0.2
          //     )}, 0px 5px 22px -4px ${alpha(
          //       theme.colors.alpha.black[100],
          //       0.1
          //     )}`
      }}
    >
      {/* <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <HeaderMenu />
      </Stack> */}
        <h1 className="title">{title}</h1>
      <Box display="flex" alignItems="center" sx={{height:'60px !important'}} className='height'>
        {/* <HeaderButtons /> */}
        <HeaderUserbox  />
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
