import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "src/content/dashboards/Crypto/PageHeader";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "@mui/material";
import axios from "axios";
import { Stack } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { useNavigate } from "react-router";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import DashboardAction from "src/action/DashboardAction";
// import PageHeader from "src/content/dashboards/Crypto/PageHeader";
// import PageTitleWrapper from "src/components/PageTitleWrapper";

const Detailed_Site = () => {
  const [open, setOpen] = React.useState(false);
  const slug = location.pathname.replace(`/site-management/siteDetails/`, "");
  const actualSlug = slug.replace("#", "");
  const [userData, setUserData] = useState([]);
  const handleModal = (item) => {
    setMdalItem(item);
    setOpen(true);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalItem, setMdalItem] = React.useState("");
  const [data, setData] = React.useState([]);
  const [updatedData, setUpdatedData] = React.useState([]);
  const [queueData, setQueueData] = React.useState([]);
  const token = localStorage.getItem("token") || "";
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const userByIDApi = `${baseUrl}/user/get`;
  const siteByIDApi = `${baseUrl}/sites`;

  const getData = async () => {
    await axios
      .get(
        // http://192.168.1.48/webalyticsinternation/Backend/API/public/api/sites
        // `https://api.admin.websiteranking.companydemo.in/api/sites/queue?id=${actualSlug}`,
        `${siteByIDApi}/queue?id=${actualSlug}`,
        {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        }
      )
      .then((res) => {
        setQueueData(res.data?.data);
        setData(res.data?.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          DashboardAction.checklogoutService()
        }
        console.log("Error occured in Requesting sites", error);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (queueData) {
      const newData = queueData.map((item, index) => ({
        id: index + 1,
        name: item.name,
        url: item.url,
        status: item.status,
        download: "Download",
      }));

      setUpdatedData(newData);
    }
  }, [queueData]);

  const columns = [
    { field: "id", headerName: <strong>ID</strong>, width: 100 },
    {
      field: "name",
      headerName: <strong>Site Name</strong>,
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "url",
      headerName: <strong>Site Url</strong>,
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "status",
      headerName: <strong>Status</strong>,
      width: 250,
      align: "left",
      headerAlign: "left",
      editable: false,
    },
    {
      field: "download",
      headerName: <strong>Download Report</strong>,
      width: 200,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          disabled={params.row.status.toLowerCase() === "pending"}
          onClick={() => handleDownloadReport(params.row)}
        >
          Download
        </Button>
      ),
    },
  ];

  const handleDownloadReport = (row) => {
    // Add logic to handle download action based on row data
    console.log(`Download clicked for ID ${row.id}`);
    // Add your download logic here
  };

  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: 20 },
    { id: 6, lastName: "Melisandre", firstName: "Sahil", age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleOnboarding = (id) => {
    // Handle Onboarding button click
    console.log(`Onboarding clicked for ID ${id}`);
  };

  const handleRequested = (id) => {
    // Handle Requested button click
    console.log(`Requested clicked for ID ${id}`);
  };

  const formattedLastLogin = (time) => {
    const dateString = time;
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const lastUpdated = (timestamp) => {
    if (timestamp) {
      const date = new Date(+timestamp);
      const options = { day: "numeric", month: "long", year: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);
      return formattedDate;
    } else {
      return "Invalid date";
    }
  };

  const [userId, setUserId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [siteModalData, setSiteModalData] = useState([]);

  const [siteUserData, setSiteUserData] = useState("");

  useEffect(() => {
    axios
      .get(
        // http://192.168.1.48/webalyticsinternation/Backend/API/public/api/sites
        // `https://api.admin.websiteranking.companydemo.in/api/sites/byid/${actualSlug}`,
      `${siteByIDApi}/byid/${actualSlug}`,

        {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        }
      )
      .then((res) => {
        setSiteModalData(res.data?.data);
        setSiteId(res.data.data._id);
        setUserId(res.data.data.user_id);
      })
      .catch((error) => {
        console.log("Error in Site Id", error);
      });
  }, []);

  useEffect(() => {}, [siteId]);

  useEffect(() => {
    if (userId) {
      axios
        .get(
          // http://192.168.1.48/webalyticsinternation/Backend/API/public/api/user/get
          // `https://api.admin.websiteranking.companydemo.in/api/user/get/${userId}`,
          `${userByIDApi}/${userId}`,
          {
            headers: {
              accept: "*/*",
              "Auth-Token": `${token}`,
            },
          }
        )
        .then((res) => {
          setSiteUserData(res.data.data);
        })
        .catch((error) => {
          console.log("Error in Site Id", error);
        });
    }
  }, [userId]);

  const [listData, setListData] = useState([
    { id: 1, title: "Queue" },
    { id: 2, title: "Download" },
  ]);
  const [activeTab, setActiveTab] = React.useState("");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const loggedin_Name = localStorage.getItem("login_data");
  const parsedData = JSON.parse(loggedin_Name);
  const user = {
    name: parsedData?.data?.name,
    avatar: "/static/images/avatars/1.jpg",
  };

  const navigate = useNavigate();


  return (
    <>
     <Helmet>
        <title>{user.name ? user.name : ""} </title>
      </Helmet>
      <section className="breadcrum-section" style={{marginTop:'20px',marginLeft:'20px'}}>
      <div role="presentation" style={{marginTop:'20px'}} >
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/site-management/getAllOnboardedSite"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Sites
        </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/site-management/getAllOnboardedSite"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Sites Onboarded
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {siteModalData?.name}
        </Typography>
      </Breadcrumbs>
    </div>
        </section>
      <PageTitleWrapper>
        <PageHeader title="SITE DETAILS" />
      </PageTitleWrapper>
      {/* <div
        className=""
        style={{ width: "90%", margin: "auto", display: "flex", gap: "10px" ,flexDirection:'column'}}
      >
        <div style={{display:'flex'}}>
        <div
          style={{
            marginBottom: "10px",
            width: "50%",
          }}
        >
          <div
            className="col-lg-12 col-md-12 mb-4 mb-xl-0 mb-lg-4 mb-md-0"
            style={{
              backgroundColor: "white",
              height: "200px",
              margin: "25px",
              width: "95%",
              boxShadow: "10px 10px 10px 10px #e8eef3",
              borderRadius: "0.25rem",
              padding: "25px",
              gap: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h3"
              component="h2"
              style={{ marginBottom: "10px" }}
            >
              WIM User:
            </Typography>
            <div>
              <h5
                className="card-title f-15 f-w-500 text-darkest-grey mb-0"
                style={{ fontWeight: "medium",color:'gray' }}
              >
                {" "}
                {siteModalData?.name}
              </h5>
            </div>
            <div>
              <p
                className="card-title f-15 f-w-500 text-darkest-grey mb-0"
                style={{ color: "gray", fontWeight: 600 }}
              >
                {siteUserData?.name} ({siteUserData?.email})
              </p>
            </div>
            <div style={{ color: "gray", fontWeight: 600 }}>
              Onboarded on {formattedLastLogin(siteModalData?.created_at)}
            </div>
            <div></div>
          </div>
        </div>
        <div
          style={{
            marginBottom: "10px",
            width: "50%",
          }}
        >
          <div
            className="col-lg-12 col-md-12 mb-4 mb-xl-0 mb-lg-4 mb-md-0"
            style={{
              backgroundColor: "white",
              height: "200px",
              margin: "25px",
              width: "95%",
              boxShadow: "10px 10px 10px 10px #e8eef3",
              borderRadius: "0.25rem",
              padding: "25px",
              gap: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography id="modal-modal-title" variant="h3" component="h2" style={{marginBottom:'10px'}}>
              Audit:
            </Typography>
            <div>
              <p
                className="card-title f-15 f-w-500 text-darkest-grey mb-0"
                style={{ fontWeight: "bold",color:'gray' }}
              >
                {" "}
                Last Generated Report at{" "}
                {lastUpdated(
                  queueData[queueData?.length - 1]?.updated_at?.$date
                    ?.$numberLong
                )}
              </p>
            </div>
            <div></div>
            <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{ fontWeight: "bold",color:'gray' }}>Reports Processed: {queueData[0]?.processed}</p>
            <div></div>
          </div>
        </div>
        </div>
       
      </div> */}
      <div style={{backgroundColor:'#f2f4f8'}}>
<div className='col-lg-12 col-md-12 mb-4 mb-xl-0 mb-lg-4 mb-md-0' style={{backgroundColor:'white',height:'fit-content',margin:'25px',width:'95%',boxShadow: "10px 10px 10px 10px #e8eef3",borderRadius: "0.25rem",padding:'30px',gap:'5px',display:'flex',flexDirection:'column'}}>
    <div>
    <h5 className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{fontWeight:'bold'}}>WIM User</h5>
    </div>
    <div>
    <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{color:'gray',fontWeight:600}}>{siteModalData?.name}</p>
    </div>
    <div>
    <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{color:'gray',fontWeight:600}}>{siteUserData?.name} ({siteUserData?.email})</p>
    </div>
    <div>
    <p className="card-title f-15 f-w-500 text-darkest-grey mb-0" style={{color:'gray',fontWeight:600}}> Onboarded on {formattedLastLogin(siteModalData?.created_at)}</p>
    </div>

</div>
<div className='col-lg-12 col-md-12 mb-4 mb-xl-0 mb-lg-4 mb-md-0' style={{backgroundColor:'white',height: '150px' ,margin:'25px',width:'95%',boxShadow: "10px 10px 10px 10px #e8eef3",borderRadius: "0.25rem",padding:'30px',gap:'5px',display:'flex',flexDirection:'column'}}>
    <div style={{width: '100%' }}>
    <h5 className="card-title f-13 f-w-500 text-darkest-grey" style={{fontWeight:600,margin:'0px',marginBottom:'0px',fontSize:'1.25rem'}}>
                Audit</h5>
                 <div>
    <p style={{fontSize:'15px',marginTop:'9px',fontWeight:600,color:'gray',marginBottom:'0px'}}>Last Generated Report at{" "}
                {lastUpdated(
                  queueData[queueData?.length - 1]?.updated_at?.$date
                    ?.$numberLong
                )}</p>
    </div>
    <div>
    <p style={{fontSize:'15px',marginTop:'0px',fontWeight:600,color:'gray'}}>Reports Processed: {queueData[0]?.processed}</p>
    </div>
    </div>
</div>
 </div>
 <div style={{ width: "95%", margin: "auto", display: "flex", gap: "20px",marginTop:'10px' }}>
        <ul
          className="nav nav-tabs"
          style={{
            borderBottom: 0,
            marginTop: "10px",
            width: "100%",
            margin: "auto",
            display: "flex",
          }}
          id="myTabs"
        >
          <li className="nav-item" style={{ width: "50%" }}>
            <a
              className={`nav-link `}
              onClick={() =>
                navigate(`/site-management/userQueueList/${actualSlug}`)
              }
              style={{
                borderRadius: "10px",
                marginRight: "10px",
                padding: "10px",
                height: "70px",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                cursor: "pointer",
                boxShadow:
                  "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
              }}
            >
              Queue List
            </a>
          </li>
          <li className="nav-item" style={{ width: "50%" }}>
            <a
              className={`nav-link `}
              onClick={() =>
                navigate(`/site-management/downloadReports/${actualSlug}`)
              }
              style={{
                borderRadius: "10px",
                marginRight: "10px",
                padding: "10px",
                height: "70px",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                cursor: "pointer",
                boxShadow:
                  "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
              }}
            >
              Download Reports
            </a>
          </li>
        </ul>
      </div>
    

      <div className="tab-content mt-2">
        {listData?.map((item, index) => {
          return (
            <>
              <div
                onClick={() => setActiveTab(item.title)}
                key={index}
                style={{
                  display: item.title == activeTab ? "block" : "none",
                  width: "95%",
                  margin: "auto",
                }}
              >
                <div
                  className="tab-pane fade show active"
                  id="content1"
                  style={{
                    background: "#fff",
                    margin: "auto",
                    width: "95%",
                    margin: "auto",
                    borderRadius: "20px",
                    boxShadow: "0 0 52px #3d3d3d1f",
                    border: "1px solid #dcdcdc",
                  }}
                >
                  <Box
                    sx={{
                      height: 400,
                      backgroundColor: "white",
                    }}
                  >
                    <DataGrid
                      slots={{ toolbar: GridToolbar }}
                      rows={updatedData}
                      columns={index == 0 ? columns.slice(0, 4) : columns}
                      getRowId={(updatedData) => updatedData?.name}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      // checkboxSelection
                      disableRowSelectionOnClick
                      // hideSortIcons // Add this line to hide sort icons
                      disableColumnMenu // Add this line to disable column menu
                    />
                  </Box>
                </div>
              </div>{" "}
            </>
          );
        })}
      </div>
    </>
  );
};

export default Detailed_Site;
