import React, { useEffect, useRef, useState } from 'react'
import { TextField, Button } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ToastContainer } from 'react-toastify'
import Box from "@mui/material/Box";
import { callApi, postApi, postData } from 'src/action/RestClient'
import { useNavigate } from 'react-router'
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { notify } from 'src/common/toastMesaage';



const UpdateRequestedAgencyAccount = () => {
    const location = useLocation();
    const [userData, setUserData] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [field, setField] = useState({ packages: "", agencyName: "", companyName: "", countryName: "", countryPin: "", gstNumber: "", address: "" })
    const [error, setError] = useState({
        agencyNameError: false,
        companyNameError: false,
        countryNameError: false,
        countryPinError: false,
        gstNumberError: false,
        addressError: false,
    });


    //validation function for url check
    const isValidUrl = (url) => {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    };


    //fill inputfield function for error or field value
    const handleChangeField = (e) => {
        const { name, value } = e.target;
        setField({ ...field, [name]: value });
        setError((prevError) => ({ ...prevError, agencyNameError: name === "agencyName" && value.length < 3, }));
        setError((prevError) => ({ ...prevError, companyNameError: name === "companyName" && value.length < 3, }));
        setError((prevError) => ({ ...prevError, countryNameError: name === "countryName" && value.length < 3, }));
        setError((prevError) => ({ ...prevError, countryPinError: name === "countryPin" && value.length < 1, }));
        setError((prevError) => ({ ...prevError, gstNumberError: name === "gstNumber" && value.length < 1, }));
        setError((prevError) => ({ ...prevError, addressError: name === "address" && value.length < 1, }));

    };

    //On form submit when create and update 
    const handleSubmit = async (e) => {
        e.preventDefault();
        const details = location?.state;
        try {
            const data = {
                agency_Name: field.agencyName,
                company_Name: field.companyName,
                country_Name: field.countryName,
                country_Pin: field.countryPin,
                gst_Number: field.gstNumber,
                address: field.address,
                userId: details?.userId,
                requestedId: details?._id?.$oid,
                packageId: field.packages,
                url:details?.websiteUrl,
            };
            console.log("data", data);
             const url = '/upgrade/user';
             const response = await postData(url, data);
             console.log("response", response)
            if (response?.status) {
                notify("Agency Request Update Sucessfully", "success");
                setField({ packages: "", agencyName: "", companyName: "", countryName: "", countryPin: "", gstNumber: "", address: "" });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const getAllData = async () => {
        try {
            const response = await callApi('GET', '/get/packages', null,);
            const data = response?.data?.data;
            setUserData(data);
            return data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (initialLoad) {
            getAllData();
            setInitialLoad(false);
        }
    }, [initialLoad]);


    return <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                            <div role="presentation" style={{ marginTop: '20px' }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/users/requestAgencyAccounts"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Request Agency Accounts
                                    </Link>
                                    <Typography
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="text.primary"
                                    >
                                        <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Update Request Agency Accounts
                                    </Typography>
                                </Breadcrumbs>
                            </div>
                        </section>
                        <div className="iq-card position-relative rounded overflow-hidden">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>  Update Request Agency Accounts </h4>
                                </div>
                            </div>
                            <Box sx={{ width: "100%", background: "white", paddingBottom: '41px' }}>
                                <div className="FormsAreaNew container">
                                    <div className="tab-content ">
                                        <form onSubmit={handleSubmit} >
                                            <Box className='create_package_outer'
                                                component="div"
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <Box className='form-field-box flex-wrap d-flex'>
                                                <FormControl>
                                                        <InputLabel id="demo-simple-select-label">Packages</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-helper-label"
                                                            id="packages"
                                                            name='packages'
                                                            value={field.packages}
                                                            label="packages"
                                                            onChange={handleChangeField}
                                                        >
                                                            <MenuItem value="">
                                                            </MenuItem>
                                                            {userData?.map((item, index) => {
                                                                return <MenuItem value={item?._id} key={index}>{item.title}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                    <TextField
                                                        label="Agency Name"
                                                        placeholder="Enter Agency Name"
                                                        multiline
                                                        name="agencyName"
                                                        value={field?.agencyName}
                                                        variant="outlined"
                                                        sx={{ flex: 1 }}
                                                        onChange={handleChangeField}
                                                        error={error.agencyNameError}
                                                        helperText={
                                                            error.agencyNameError
                                                                ? "Agency name must be at least 3 characters long"
                                                                : ""
                                                        }
                                                    />
                                                    <TextField
                                                        placeholder="Enter Company Name"
                                                        label="Company Name"
                                                        name="companyName"
                                                        value={field.companyName}
                                                        variant="outlined"
                                                        sx={{ flex: 1 }}
                                                        onChange={handleChangeField}
                                                        error={error.companyNameError}
                                                        helperText={
                                                            error.companyNameError
                                                                ? "Company name must be at least 3 characters long"
                                                                : ""
                                                        }
                                                    />
                                                    <TextField
                                                        id="standard-multiline-flexible"
                                                        placeholder="Enter Country Name"
                                                        label="Country Name"
                                                        name="countryName"
                                                        value={field.countryName}
                                                        variant="outlined"
                                                        sx={{ flex: 1 }}
                                                        onChange={handleChangeField}
                                                        error={error.countryNameError}
                                                        helperText={
                                                            error.countryNameError
                                                                ? "Country name must be at least 3 characters long"
                                                                : ""
                                                        }
                                                    />
                                                    <TextField
                                                        placeholder="Enter Country Pin"
                                                        label="Country Pin"
                                                        name="countryPin"
                                                        type="number"
                                                        value={field.countryPin}
                                                        variant="outlined"
                                                        sx={{ flex: 1 }}
                                                        onChange={handleChangeField}
                                                        error={error.countryPinError}
                                                        helperText={
                                                            error.countryPinError
                                                                ? "Country Pin must be required"
                                                                : ""
                                                        }
                                                    />
                                                    <TextField
                                                        id="standard-multiline-flexible"
                                                        placeholder="Enter GST Number"
                                                        label="GST Number"
                                                        name="gstNumber"
                                                        value={field.gstNumber}
                                                        variant="outlined"
                                                        sx={{ flex: 1 }}
                                                        onChange={handleChangeField}
                                                        error={error.gstNumberError}
                                                        helperText={
                                                            error.gstNumberError
                                                                ? "GST Number must be required"
                                                                : ""
                                                        }
                                                    />
                                                      <TextField
                                                        placeholder="Enter Address"
                                                        label="Address"
                                                        name="address"
                                                        value={field.address}
                                                        variant="outlined"
                                                        sx={{ flex: 1 }}
                                                        onChange={handleChangeField}
                                                        error={error.addressError}
                                                        helperText={
                                                            error.addressError
                                                                ? "Address must be required"
                                                                : ""
                                                        }
                                                    />

                                                </Box>
                                                <div className='text-center pt-4'>
                                                    <Button
                                                        className="common-black-btn"
                                                        variant="contained"
                                                        type='subit'
                                                        disabled={
                                                            !field.packages ||
                                                            !field.agencyName ||
                                                            !field.companyName ||
                                                            !field.countryName ||
                                                            !field.countryPin ||
                                                            !field.gstNumber ||
                                                            !field.address
                                                        }
                                                    >
                                                        Update Request Agency Accounts
                                                    </Button>
                                                </div>
                                            </Box>
                                        </form>

                                    </div>
                                </div>

                            </Box>
                        </div >
                    </div >
                </div >
            </div >
        </div >
        <ToastContainer />
    </>
}

export default UpdateRequestedAgencyAccount
