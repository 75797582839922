import React, { useEffect, useRef, useState } from 'react'
import { TextField, Button, Select, MenuItem, FormControl } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ToastContainer } from 'react-toastify'
import Box from "@mui/material/Box";
import { callApi, postApi, postData } from 'src/action/RestClient'
import { useNavigate } from 'react-router'
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { notify } from 'src/common/toastMesaage';
import CircularProgress from '@mui/material/CircularProgress';



const CreateCountryState = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [initialLoad, setInitialLoad] = useState(true);
    const [countryData, setCountryData] = useState([]);
    const [userLoading, setUserLoading] = useState(false);
    const [field, setField] = useState({ name: "", code: "", })
    const [statesType, setStatesType] = useState("create");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        codeError: false,
        nameError: false,
    });
    const [suggestions, setSuggestions] = useState([]);
    const [countryName, setCountryName] = useState("");
    const [countryId, setCountyId] = useState("");
    const [stateId, setStateId] = useState("");
    

    const handleChange = (e) => {
        const { value } = e.target;
        filteredCountrie(value);
    };

    const filteredCountrie = (value) => {
        // setInputValue(value);
        setCountryName(value);
        const countryName = countryData?.filter((country) =>
            country?.country_name?.toLowerCase().includes(value.toLowerCase())
        ).slice(0, 5);
        setSuggestions(countryName)
    }


    const handleSelect = (country) => {
        setCountryName(country?.country_name);
        setCountyId(country?._id)
        setSuggestions([]);
    };



    const getAllCountry = async () => {
        setUserLoading(true)
        try {
            const response = await callApi('GET', "/get-all-countries", null, null);
            const data = response?.data?.data;
            setCountryData(data);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };


    useEffect(() => {
        if (initialLoad) {
            getAllCountry();
            setInitialLoad(false);
        }
    }, [initialLoad]);

    //fill inputfield function for error or field value
    const handleChangeField = (e) => {
        const { name, value } = e.target;
        setField({ ...field, [name]: value });
        if (name === "name") {
            setError((prevError) => ({ ...prevError, nameError: !value || value.length < 2 || value.length > 30 }));
        }
        if (name === "code") {
            setError((prevError) => ({ ...prevError, codeError: !value || value.length < 2 || value.length > 30, }));
        }
    };


    //On form submit when create and update 
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let data = {
                name: field?.name ? field?.name.charAt(0).toUpperCase() + field?.name.slice(1) : '',
                code: field?.code,
                countryId: countryId,
                status: "active"

            };
            const formData = new FormData(); // Create a new FormData object
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            if (statesType === "create") {
                const url = '/create-states';
                setLoading(true);
                const response = await postApi(url, formData);
                setLoading(false);
                if (response?.status) {
                    notify("State Create Sucessfully", "success");
                    setField({ name: "", code: "", });
                }
                else {
                    notify(response?.error, "error");
                }
            } else {
                const url = `/update-states/${stateId}`;
                setLoading(true);
                const response = await postApi(url, formData);
                setLoading(false);
                if (response?.status) {
                    notify("State Updates Sucessfully", "success");
                    setField({ description: "", allowWebsites: "", });
                }
                else {
                    notify(response?.error, "error");
                }
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };


    useEffect(() => {
        if (location.state) {
            const stateData = location?.state?.data;
            setCountyId(stateData?.countryId);
            setCountryName(location?.state?.countryName)
            setStatesType(location?.state?.type);
            setStateId(stateData._id);
            setField({
                name: stateData.name || "",
                code: stateData.code || "",
            });
            delete location.state;
        }
    }, [location.state]);


    return <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                            <div role="presentation" style={{ marginTop: '20px' }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        onClick={() => navigate(-1)}
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        users
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </section>
                        <div className="iq-card position-relative rounded overflow-hidden">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}> {statesType == "update" ? "Update States" : "Create New States"} </h4>
                                </div>

                            </div>

                            <Box sx={{ width: "100%", background: "white", padding: '30px 0' }}>
                                <div className="FormsAreaNew container">
                                    <div className="tab-content ">
                                        <form onSubmit={handleSubmit} >

                                            <Box className='create_package_outer'
                                                component="div"
                                                noValidate
                                                autoComplete="off"
                                            >
                                                    {/* <div className="form-group col-lg-12 d-flex align-items-center justify-content-center"> */}
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-6">
                                                            <div className="form-group mb-3">
                                                                <h4 className="card-title fw-bold text-nowrap me-2" style={{ fontSize: "1.1rem" }}> {statesType == "update" ? "County Name :" : "Select County Name :"}</h4>
                                                                <div className="form-group">
                                                                    <input
                                                                        id="country_Name"
                                                                        type="text"
                                                                        className="form-control form-control-lg "
                                                                        name="country_Name"
                                                                        placeholder="Enter Country Name"
                                                                        required="required"
                                                                        value={countryName}
                                                                        onChange={handleChange}
                                                                        autoComplete="off"
                                                                        disabled={statesType == "update"}
                                                                    />
                                                                    {countryName && statesType == "create" && suggestions.length > 0 && (
                                                                        <div className="suggestion-boxState">
                                                                            {/* Render suggestions here */}
                                                                            <ul>
                                                                                {suggestions.map((country, index) => (
                                                                                    <li key={index} onClick={() => handleSelect(country)}>
                                                                                        {country.country_name}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/* </div> */}

                                                <Box className='form-field-box flex-wrap d-flex align-items-center justify-content-center'>

                                                    <TextField
                                                        // id="standard-textarea"
                                                        label="State name"
                                                        placeholder="Enter new state name"
                                                        multiline
                                                        name="name"
                                                        value={field?.name}
                                                        variant="outlined"
                                                        sx={{ flex: 1 }}
                                                        onChange={handleChangeField}
                                                        error={error.nameError}
                                                        helperText={
                                                            error.nameError
                                                                ? "state name must be between 2 and 30 characters long."
                                                                : ""
                                                        }
                                                        disabled={!countryName}
                                                    />
                                                </Box>
                                                <Box className='form-field-box flex-wrap d-flex align-items-center justify-content-center'>

                                                    <TextField
                                                        placeholder="Enter State code"
                                                        label="State code"
                                                        name="code"
                                                        value={field.code}
                                                        variant="outlined"
                                                        sx={{ flex: 1 }}
                                                        onChange={handleChangeField}
                                                        error={error.codeError}
                                                        helperText={
                                                            error.codeError
                                                                ? "State code must be between 2 and 20 characters long."
                                                                : ""
                                                        }
                                                        disabled={!countryName}
                                                    />

                                                </Box>

                                                <div className='text-center pt-4'>
                                                    {loading ?
                                                        <Button
                                                            className="common-black-btn"
                                                            variant="contained"
                                                            type='button'
                                                        >
                                                            <Box gap-3 sx={{ color: "white", width: "100px" }}>
                                                                <CircularProgress color="inherit" size={18} />
                                                            </Box>
                                                        </Button>
                                                        :
                                                        <Button
                                                            className="common-black-btn"
                                                            variant="contained"
                                                            type='subit'
                                                            disabled={
                                                                !field.name ||
                                                                !field.code ||
                                                                error.nameError ||
                                                                error.codeError
                                                            }
                                                        >
                                                            {statesType === "update" ? "Update States" : "Create State"}
                                                        </Button>
                                                    }
                                                </div>
                                            </Box>
                                        </form>

                                    </div>
                                </div>

                            </Box>
                        </div >
                    </div >
                </div >
            </div >
        </div >
        <ToastContainer />
    </>
}

export default CreateCountryState
