import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import moment from 'moment';
import { callApi } from "src/action/RestClient";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import CommonTable from "src/common/table/table";
import { useNavigate } from "react-router";
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import UnCompletedPurchasesModal from "./unCompletedpurchasesModal";


const UnCompletedPurchases = () => {
    const navigate = useNavigate();
    const [initialLoad, setInitialLoad] = useState(true);
    const [userLoading, setUserLoading] = useState();
    const [originalData, setOriginalData] = useState([]);
    const [page, setPage] = useState(0); // Current page index
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRecoard, setTotalRecoard] = useState(0);
    const [sortby, setSortBy] = useState(null);
    const [sort, setSort] = useState(null);
    const [selectedId, setSelectedId] = useState("");
    const [showModal, setShowModal] = useState(false);



    const closeModal = () => {
        setShowModal(false);
    };

    const getAllData = async () => {
        setUserLoading(true)
        try {
            let options = {};
            if (page !== undefined) options.page = page + 1;
            if (rowsPerPage !== undefined) options.perpage = rowsPerPage;
            if (sort !== null) options.sort = sort;
            if (sortby !== null) options.sortBy = sortby;
            const response = await callApi('GET', '/all/subscription-un-completed-data', null, options);
            const data = response?.data?.data?.data
            setTotalRecoard(response?.data?.data?.total);
            setOriginalData(data);
            setUserLoading(false)
        } catch (error) {
            console.error('Error:', error);
            setUserLoading(false)
            throw error;
        }
    };


    useEffect(() => {
        if (initialLoad) {
            getAllData();
            setInitialLoad(false);
        }
    }, [initialLoad]);

    useEffect(() => {
        if(!initialLoad){
          getAllData();
        }
      }, [page, rowsPerPage, sort, sortby ])


    const handleSort = (sortby, sort) => {
        setSortBy(sortby);
        setSort(sort);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPage(data);
        setPage(0);
    }

    const formatDateCell = (params) => {
        const dateString = params?.value;
        const date = moment(dateString);
        const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
        const timeAgo = date.fromNow();
        return (
          <div>
            <p className="fw-bold">{formattedDate} <br></br><small className="fw-normal">({timeAgo})</small></p>
          </div>
        );
      };

    const renderActionsCell = (params) => {
        const user = params.row;
        return (
          <div>
              <Tooltip
                title={`Subscription Details`}
                arrow
                onClick={() => handleViewUnSubscriptionDetails(user.id)}
              >
                <IconButton color="inherit" size="small">
                  <PreviewOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
          </div>
        );
      };

      const handleViewUnSubscriptionDetails = (id) => {
        navigate(`/users/unCompletedPurchasesDetails`, { state: { userId: id } });
        // setSelectedId(id)
        // setShowModal(true);
      };

    const columns = [
        { field: 'email', label: 'Email', width: 240, align: 'left' },
        { field: 'company_Name', label: 'company Name', width: 240, align: 'left' },
        { field: 'country_Name', label: 'country NameL', width: 240, align: 'left' },
        { field: 'created_at', label: 'REQUESTED Date', width: 240, renderCell: formatDateCell, align: 'left' },
        { field: 'actions', label: 'Actions', width: 240, renderCell: renderActionsCell, align: 'left' },
    ];


    return (
        <>
            <div id="content-page" className="content-page" >
            {/* <div id="content-page" className={`content-page ${showModal ? 'blur-background' : ''}`} > */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Dashboard
                                        </Link>
                                        {/* <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            
                                        </Typography> */}
                                    </Breadcrumbs>
                                </div>
                            </section>

                            <div className="iq-card position-relative rounded overflow-hidden">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>UnCompleted Purchases</h4>
                                    </div>
                                </div>

                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={originalData}
                                        columns={columns}
                                        enablePagination
                                        type={"unCompletePurchases"}
                                        loading={userLoading}
                                        page={page}
                                        count={totalRecoard}
                                        rowsPerPage={rowsPerPage}
                                        handleSort={handleSort}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
            {/* {showModal && <div className="overlay" onClick={closeModal}></div>} */}
            <UnCompletedPurchasesModal isOpen={showModal} onClose={closeModal} selectedId={selectedId}/>
        </>
    );
};
export default UnCompletedPurchases;
