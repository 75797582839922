import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from "react";
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { tr } from 'date-fns/locale';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#282621',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#fff',
    },
}));

function CommonTable(props) {
    const { columns, data, enablePagination = false, loading = false, type, page, count, rowsPerPage, handleSort, onPageChange, onRowsPerPageChange } = props
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState('desc');
    const [sort, setSort] = useState({
        field: 'name',  // Default sort field
        order: 'asc',   // Default sort order
    });


    const handleChangePage = (event, newPage) => {
        onPageChange(event, newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const perPage = parseInt(event.target.value, 10);
        onPageChange(0);
        onRowsPerPageChange(perPage); // Call the prop function to update rowsPerPage in the App component
    };

    const handleSortOne = (column) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        // Call the handleSort function passed from props
        handleSort(column, sortOrder);
    };

    // const slicedData = data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return (
        <div className='rounded w-100 h-100 overflow-auto'>
            <TableContainer component={Paper} className='overflow-visible rounded'>
                <Table aria-label="customized table" className='table table-bordered rounded mb-0'>
                    <TableHead className='position-sticky' style={{ top: "0px", zIndex: 1 }}>
                        <TableRow className='border-0'>
                            {columns.map((column, index) => (
                                <StyledTableCell key={index}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>{column.label}</span>
                                        {(((column.field === 'name' && type != "admin" && type != "userDetails") || (column.field === 'created_at' && type != "log") || (column.field === 'email' && type != "admin") || column.field === 'last_login') && (
                                            <IconButton className='text-light py-1' onClick={() => handleSortOne(column.field)}>
                                                {sortOrder === 'asc' && sortBy === column.field ? <ArrowDownwardIcon style={{ fontSize: '16px' }} /> : <ArrowUpwardIcon style={{ fontSize: '16px' }} />}
                                            </IconButton>
                                        )) || null}
                                    </div>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflowY: 'scroll' }}>
                        {loading ?
                            <tr>
                                <td colSpan={10}>
                                    <Box className='align-items-center justify-content-center py-5 d-flex table-loader'>
                                        <CircularProgress />
                                    </Box>
                                </td>
                            </tr>
                            : <>
                                {data?.length > 0 ?
                                    <>
                                        {data?.map((row, rowIndex) => (
                                            <StyledTableRow key={rowIndex}>
                                                {columns?.map((column, colIndex) => (
                                                    <StyledTableCell key={colIndex} align={column.align}>
                                                        {column.field === 'status' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        ) : column.field === 'actions' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        )
                                                        : column.field === 'recurring_count' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        )
                                                        : column.field === 'userName' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        )
                                                        : column.field === 'generate_report' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        )
                                                        : column.field === 'phone' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        )
                                                        : column.field === 'siteName' && column.renderCell ? (
                                                           column.renderCell({ row })
                                                        )
                                                        : column.field === 'user_name' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        )
                                                        : column.field === 'category' && column.renderCell ? (
                                                               column.renderCell({ row })
                                                        )
                                                        : column.field === 'end_at' && column.renderCell ? (
                                                             column.renderCell({ row })
                                                        )
                                                         : column.field === 'download' && column.renderCell ? (
                                                               column.renderCell({ row })
                                                        )
                                                        : column.field === 'last_login' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        )
                                                        : column.field === 'end_date' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        )
                                                        : column.field === 'date' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        )
                                                        : column.field === 'last_report_generated' && column.renderCell ? (
                                                            column.renderCell({ row })
                                                        )
                                                        : column.field === 'by_user' && typeof row[column.field] === 'boolean' ? (
                                                            row[column.field] ? 'true' : 'false'
                                                        ) : column.field === 'created_at' && column.renderCell && row[column.field] ? (
                                                            column.renderCell({ value: row[column.field] }) // Pass the date value to renderCell
                                                        ) : (
                                                            row[column.field]
                                                                                                    )}
                                                        {/* {column.field === 'by_user' && typeof row[column.field] === 'boolean'
                                            ? row[column.field] ? 'true' : 'false'
                                            : row[column.field]} */}
                                                        {/* {row[column.field]} */}
                                                    </StyledTableCell>
                                                ))}
                                            </StyledTableRow>
                                        ))}
                                    </> :
                                    <tr>
                                        <td colSpan={10}>
                                            <h4 className='align-items-center justify-content-center py-5 d-flex '>{"No Records Found"}</h4>
                                        </td>
                                    </tr>}
                            </>}
                    </TableBody>
                </Table>
            </TableContainer>
            {enablePagination && (
                <TablePagination
                    className='position-absolute bottom-0 bg-light w-100 border rounded-bottom'
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
}
export default CommonTable;
