import React, { useEffect, useRef, useState } from 'react'
import { TextField, Button } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ToastContainer } from 'react-toastify'
import Box from "@mui/material/Box";
import { postApi, postData } from 'src/action/RestClient'
import { useNavigate } from 'react-router'
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { notify } from 'src/common/toastMesaage';
import CircularProgress from '@mui/material/CircularProgress';



const CreateCustomPackages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState("create");
  const [updateId, setUpdateId] = useState();
  const [field, setField] = useState({ packageuid: "", title: "" })
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    packageUIDError: false,
    titleError: false,
  });


  //fill inputfield function for error or field value
  const handleChangeField = (e) => {
    const { name, value } = e.target;
    setField({ ...field, [name]: value });
    setError((prevError) => ({
      ...prevError,
      packageUIDError:
        name === "packageuid" && value.length < 12,
    }));
    setError((prevError) => ({
      ...prevError,
      titleError: name === "title" && value.length < 3,
    }));
  };


  //On form submit when create and update 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data = {}
      if (page == "create") {
        data = {
          title: field.title,
          packageUID: field.packageuid,
        };
      } else {
        data = {
          title: field.title,
          id: updateId,
        }
      }
      const formData = new FormData(); // Create a new FormData object
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
      if (page == "create") {
        const url = '/create-custom/packages';
        setLoading(true);
        const response = await postApi(url, formData);
        setLoading(false);
        if (response?.status) {
          notify("Packages Created Sucessfully", "success");
          setField({ packageuid: "", title: "" });
        }
        else {
          notify(response?.error, "error");
        }
      }
      else {
        const url = `/update-custom/packages`;
        const sandData = {...data, }
        setLoading(true);
        const response = await postApi(url, formData);
        setLoading(false);
        if (response?.status) {
          notify("Packages Update Sucessfully", "success");
        }
        else {
          notify(response?.error, "error");
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };



  //on update set all field value 
  useEffect(() => {
    if (location.state) {
      const stateData = location?.state?.data;
      setPage(location?.state?.type);
      setUpdateId(stateData._id)
      setField({
        packageuid: stateData.packageUID || "",
        title: stateData.title || "",
      });
      delete location.state;
    }
  }, [location.state]);


  return <>
    <div id="content-page" className="content-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
              <div role="presentation" style={{ marginTop: '20px' }}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    onClick={() => navigate("/billingAndSubscriptions/managePackages" ,{state: {changePackage: "custom"} })}
                    // href="/billingAndSubscriptions/managePackages"
                  >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Manage Packages
                  </Link>
                  {/* <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                  >
                    <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {page == "view" ? "View Custom Packages" :
                      page == "update" ? "Update Custom Packages"
                        : "Create Custom Packages"}
                  </Typography> */}
                </Breadcrumbs>
              </div>
            </section>
            <div className="iq-card position-relative rounded overflow-hidden">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>  {page == "view" ? "View Custom Packages" : page == "update" ? "Update Custom Packages" : "Create Custom Packages"} </h4>
                </div>
              </div>
              <Box sx={{ width: "100%", background: "white", paddingBottom: '41px' }}>
                <div className="FormsAreaNew container">
                  <div className="tab-content ">
                    <form onSubmit={handleSubmit} >
                      <Box className='create_package_outer'
                        component="div"
                        noValidate
                        autoComplete="off"
                      >
                        <Box className='form-field-box flex-wrap d-flex'>
                          <TextField
                            placeholder="Enter your package UID"
                            label="Package UID"
                            name="packageuid"
                            type="number"
                            value={field.packageuid}
                            variant="outlined"
                            sx={{ flex: 1 }}
                            onChange={handleChangeField}
                            error={error.packageUIDError}
                            helperText={
                              error.packageUIDError
                                ? " Package UID must be at least 12 characters long"
                                : ""
                            }
                            disabled={page == "view" || page == "update"}
                          />
                          <TextField
                            // id="standard-multiline-flexible"
                            placeholder="Enter your package title"
                            label="Package title"
                            multiline
                            name="title"
                            value={field.title}
                            maxRows={4}
                            variant="outlined"
                            sx={{ flex: 1 }}
                            onChange={handleChangeField}
                            error={error.titleError}
                            helperText={
                              error.titleError
                                ? "Title must be at least 3 characters long"
                                : ""
                            }
                            disabled={page == "view"}
                          />

                        </Box>

                        <div className='text-center pt-4'>
                          {loading ?
                            <Button
                              className="common-black-btn"
                              variant="contained"
                              type='button'
                            >
                              <Box gap-3 sx={{ color: "white", width: "100px" }}>
                                <CircularProgress color="inherit" size={18} />
                              </Box>
                            </Button>
                            :
                            <Button
                              className="common-black-btn"
                              variant="contained"
                              type='subit'
                              disabled={
                                !field.packageuid ||
                                !field.title ||
                                error.packageUIDError ||
                                error.titleError ||
                                page == "view"
                              }
                            >
                              {page == "view" ? "View Custom Packages" : page == "update" ? "Update Custom Packages" : "Create Custom Packages"}
                            </Button>
                          }
                        </div>
                      </Box>
                    </form>

                  </div>
                </div>

              </Box>
            </div >
          </div >
        </div >
      </div >
    </div >
    <ToastContainer />
  </>
}

export default CreateCustomPackages
