import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Box, Typography, TextField } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import Stack from "@mui/material/Stack";
import axios from "axios";
import DashboardAction from "src/action/DashboardAction";

const CommonUserModal = ({ open, setOpen, data }) => {
  const handleModal = (item) => {
    setMdalItem(item);
    setOpen(true);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalItem, setMdalItem] = useState("");
  const [cardData, setCardData] = useState([]);
  const [userDetail, setUserDetail] = useState("");
  const [siteList, setSiteList] = useState([]);
  const token = localStorage.getItem(`token`);
  const baseUrl = process.env.REACT_APP_BASE_URL
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    bgcolor: "background.paper",
    borderRadius: "10px !important",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgb(10 37 64 / 21%) 0px -2px 6px 0px inset !important",
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
  };
  // https://api.admin.websiteranking.companydemo.in/api/user/get/64f1b7f71104df73d207f792

  useEffect(() => {
    if (data) {
      axios.get(
          `${baseUrl}/user/get/${data}`,
          {
            headers: {
              accept: "*/*",
              "Auth-Token": `${token}`,
            },
          }
        )
        .then((res) => {
          setUserDetail(res.data?.data);
          // setCardData(res.data.data[0])
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            DashboardAction.checklogoutService()
          }
          console.log("Error In USER DETAILS", error);
        });
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      axios.get(`${baseUrl}/sites/byuser?user=${data}`,
          {
            headers: {
              accept: "*/*",
              "Auth-Token": `${token}`,
            },
          }
        )
        .then((res) => {
          setSiteList(res.data.data);
          // setCardData(res.data.data[0])
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            DashboardAction.checklogoutService()
          }
          console.log("Error for USer Modal", error);
        });
    }
  }, [data]);

  const formattedLastLogin = (time) => {
    const date = new Date(time);

    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  };

  const registerationStatus = (status) => {
    switch (status) {
      case 1:
        return `Completed`;
      case 0:
        return `Awaited`;
    }
  };

  const siteStatus = (status) => {
    switch (status) {
      case 0:
        return "Requested";
      case 1:
        return "Onboarded";
      case 2:
        return "Pending";
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            sx={{ gap: "20px" }}
          >
            <div style={{maxHeight:'600px',overflow:'auto'}}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                <PersonOutlinedIcon /> User Detail
              </Typography>
              <div style={{ marginTop: "10px" }}>
              <div
              className="container"
              style={{marginTop: "10px", marginBottom: "10px"}}
            >
              <div className="row">
                <div id="no-more-tables">
                  <table className="col-md-12 table-bordered table-striped table-condensed cf" style={{width:'500px'}}>
                    <thead>
                      <tr>
                        <th scope="col" className="tdAlign">
                          Name
                        </th>
                        <th className="tdAlign">{userDetail?.name}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row" className="tdAlign">
                          Email
                        </th>
                        <td className="tdAlign">{userDetail?.email}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="tdAlign">
                        Registration Status
                        </th>
                        <td className="tdAlign">
                        {registerationStatus(userDetail?.status)}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="tdAlign">
                        Last logged in
                        </th>
                        <td className="tdAlign">{formattedLastLogin(userDetail?.updated_at)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
                {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                  Name:- {userDetail?.name}
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Email: {userDetail?.email}
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Registration Status: {registerationStatus(userDetail?.status)}
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Last logged in: {formattedLastLogin(userDetail?.updated_at)}
                </Typography> */}
                <div style={{ marginTop: "20px" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h2"
                  >
                    <VerifiedUserOutlinedIcon /> Site Details <span><a href="/management/userProfile">Click</a></span>
                  </Typography>
                  <div style={{ marginTop: "10px",margin:'14px' }}>
                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                    >
                      Sites of this user:-
                    </Typography>
                    {/* <div
              className="container"
              style={{marginTop: "10px", marginBottom: "10px"}}
            >
              <div className="row">
                <div id="no-more-tables">
                  <table className="col-md-12 table-bordered table-striped table-condensed cf" style={{width:'500px'}}>
                    <thead>
                      <tr>
                        <th scope="col" className="tdAlign">
                          Name
                        </th>
                        <th className="tdAlign">{userDetail?.name}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row" className="tdAlign">
                          Email
                        </th>
                        <td className="tdAlign">{userDetail?.email}</td>
                      </tr>
                      <tr>
                        <th scope="row" className="tdAlign">
                        Registration Status
                        </th>
                        <td className="tdAlign">
                        {registerationStatus(userDetail?.status)}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="tdAlign">
                        Last logged in
                        </th>
                        <td className="tdAlign">{formattedLastLogin(userDetail?.updated_at)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
                    <div style={{ marginTop: "10px" }}>
                    {siteList?.length > 0 ? (
  <table className="col-md-12 table-bordered table-striped table-condensed cf">
    <thead>
      <tr>
        <th className="tdAlign">Name</th>
        <th className="tdAlign">URL</th>
        <th className="tdAlign">Site Status</th>
      </tr>
    </thead>
    <tbody>
      {siteList.map((item, index) => (
        <tr key={index}>
          <td className="tdAlign">{item.name}</td>
          <td className="tdAlign">{item.url}</td>
          <td className="tdAlign">{siteStatus(item.status)}</td>
        </tr>
      ))}
    </tbody>
  </table>
) : (
  <p className="mt-3">No site added yet</p>
)}
                      {/* {siteList?.length > 0
                        ? siteList?.map((item, index) => {
                            return (
                              <>
                                <Typography
                                  key={index}
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  {" "}
                                  {item.name}({item.url})
                                </Typography>
                                <Typography
                                  key={index}
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  {" "}
                                  Site status:- {siteStatus(item.status)}
                                </Typography>
                              </>
                            );
                          })
                        : "No site added yet"} */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div style={{marginTop:'20px'}}>
            <Button variant="contained" onClick={()=>setOpen(false)}>View More</Button>
            <Button variant="outlined" sx={{marginLeft:'10px'}} onClick={()=>setOpen(false)} >Cancel</Button>

            </div> */}
            </div>
            
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default CommonUserModal;
