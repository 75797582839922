import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { TextField, Button } from '@mui/material'
import { postData } from 'src/action/RestClient';

const QuestionModal = ({ isOpen, onClose, question, questionId, category, getQuestionData, selectedindex, changeIndex }) => {
    const [selectedQuestion, setSelectedQuestion] = useState({ 
                question_id: "",
                question: "",
                weight: "",
                recommendation_pass: "",
                business_Implication_pass: "",
                recommendation_fail: "",
                business_Implication_fail: "",
                status: ""
    });
    const [selctedQuestionId, setSelctedQuestionId] = useState();
    const [selectCategory, setSelectCategory] = useState();
    const [index, setIndex] = useState();
    
    const handleChangeField = (e) => {
        const { name, value } = e.target;
        setSelectedQuestion({ ...selectedQuestion, [name]: value });
      };
    const handleSubmit = async() =>{
        try {
            const data = {
                category: selectCategory,
                question_id: selectedQuestion?.question_id,
                question: selectedQuestion?.question,
                weight: selectedQuestion?.weight,
                recommendation_pass: selectedQuestion?.recommendation_pass,
                business_Implication_pass: selectedQuestion?.business_Implication_pass,
                id:selctedQuestionId,
                recommendation_fail: selectedQuestion?.recommendation_fail,
                business_Implication_fail: selectedQuestion?.business_Implication_fail,
                status: selectedQuestion?.status ? selectedQuestion?.status : "active"
            };
             const url = '/update/questions';
             const response = await postData(url, data);
            if (response?.status) {
                setSelectedQuestion("");
                getQuestionData();
                changeIndex(index);
                onClose();               
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleClose = () =>{
        setSelectedQuestion("")
        onClose();

    }

    useEffect(() =>{
        setSelectedQuestion({
            question_id: question?.question_id,
            question: question?.question ? question?.question : "",
            weight: question?.weight ?  question?.weight : "" ,
            recommendation_pass: question?.recommendation_pass ? question?.recommendation_pass : "",
            business_Implication_pass: question?.business_Implication_pass ? question?.business_Implication_pass :"",
            recommendation_fail: question?.recommendation_fail ?question?.recommendation_fail :"",
            business_Implication_fail: question?.business_Implication_fail ? question?.business_Implication_fail: "",
            status: question?.status ? question?.status : "",
        });
        setSelctedQuestionId(questionId);
        setSelectCategory(category);
        setIndex(selectedindex)
    },[question])

  return (
    <div className="modal" style={{ display: isOpen ? 'block' : 'none', margin: "6.75rem auto" }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content" style={{boxShadow: "5px 6px 20px 10px #00000024"}}>
                            <div className="modal-header">
                                <h5 className="modal-title">Update Question</h5>
                                <button type="button" className="close" onClick={handleClose}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <Box sx={{ width: "100%", background: "white", }}>
                                    <div className="FormsAreaNew container">
                                        <div className="tab-content ">
                                            <form  >
                                                <Box className='create_package_outer'
                                                    component="div"
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <Box className='form-field-box flex-wrap d-flex'>
                                                        <TextField
                                                            className='modalField'
                                                            label="Question name"
                                                            placeholder="Question name"
                                                            multiline
                                                            name="question"
                                                            value={selectedQuestion?.question}
                                                            maxRows={4}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                         <TextField
                                                            className='modalField'
                                                            label="Question weight"
                                                            placeholder="Enter question weight"
                                                            multiline
                                                            name="weight"
                                                            value={selectedQuestion?.weight}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                        <TextField
                                                            className='modalField'
                                                            label="Recommendation for pass"
                                                            placeholder="Enter recommendation for pass"
                                                            multiline
                                                            name="recommendation_pass"
                                                            value={selectedQuestion?.recommendation_pass}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                        <TextField
                                                            className='modalField'
                                                            label="Recommendation for fail"
                                                            placeholder="Enter recommendation for fail"
                                                            multiline
                                                            name="recommendation_fail"
                                                            value={selectedQuestion?.recommendation_fail}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                        <TextField
                                                            className='modalField'
                                                            label="Business implication for pass"
                                                            placeholder="Enter business implication for pass"
                                                            multiline
                                                            name="business_Implication_pass"
                                                            value={selectedQuestion?.business_Implication_pass}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                        
                                                        <TextField
                                                            className='modalField'
                                                            label="Business implication for fail"
                                                            placeholder="Enter business implication for fail"
                                                            multiline
                                                            name="business_Implication_fail"
                                                            value={selectedQuestion?.business_Implication_fail}
                                                            variant="outlined"
                                                            sx={{ flex: 1 }}
                                                            onChange={handleChangeField}
                                                        />
                                                        
                                                       

                                                    </Box>
                                                    <div className='d-flex gap-2 justify-content-center'>
                                                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                                                        <button type="button" className="questionBtn btn btn-primary" onClick={handleSubmit}>Save changes</button>
                                                    </div>
                                                </Box>
                                            </form>

                                        </div>
                                    </div>

                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
  );
};

export default QuestionModal;