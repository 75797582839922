import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { Grid, CircularProgress, } from '@mui/material';
import moment from 'moment';
import { callApi } from 'src/action/RestClient';

const UnCompletedPurchasesModal = ({ isOpen, onClose, selectedId }) => {

    const [selectedSubId, setSelectedSubId] = useState("");
    const [subscription, setSubscription] = useState({});
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        onClose();
    }

    const getUnSubscriptioDetails = async (id) => {
        setLoading(true);
        try {
            const response = await callApi('GET', `/get/subscription-un-completed-data/${id}`, null, null);
            const data = response?.data?.data
            if (response.status) {
                setSubscription(data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            throw error;
        }
    };
    useEffect(() => {
        setSelectedSubId(selectedId);
        if (selectedId) {
            getUnSubscriptioDetails(selectedId);
        }
    }, [selectedId]);

    const loader = () => {
        return (
            <div className='w-100'>
                <Box className="align-items-center justify-content-center py-5 d-flex table-loader" style={{ minHeight: "auto" }}>
                    <CircularProgress />
                </Box>
            </div>
        );
    };


    return (
        <div className="modal" style={{ display: isOpen ? 'block' : 'none', margin: "6.75rem auto" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content" style={{ boxShadow: "5px 6px 20px 10px #00000024" }}>
                    <div className="modal-header">
                        <h5 className="modal-title">UnCompleted Purchase Details</h5>
                        <button type="button" className="close" onClick={handleClose}>&times;</button>
                    </div>
                    <div className="modal-body">

                        <div class="PaymentAreaDetails">

                            <div className="col-lg-12">
                                <div className="billingInformation">
                                    {loading ? (
                                        loader()
                                    ) : Object.keys(subscription).length <= 0 ? (
                                        <div>
                                            <div colSpan={10}>
                                                <h4 className="align-items-center justify-content-center py-5 d-flex ">
                                                    {'No Records Found'}
                                                </h4>
                                            </div>
                                        </div>
                                    ) : (
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr style={{fontSize: "medium"}}>
                                                    <td className="w-25">
                                                        {' '}
                                                        <strong > Term Period :</strong>{' '}
                                                    </td>
                                                    <td className="w-75">
                                                        {' '}
                                                        {subscription?.termPeriod}
                                                    </td>
                                                </tr>
                                                <tr style={{fontSize: "medium"}}>
                                                    <td>
                                                        {' '}
                                                        <strong>Package Title:</strong>{' '}
                                                    </td>
                                                    <td> {subscription?.packageTitle}</td>
                                                </tr>
                                                <tr style={{fontSize: "medium"}}>
                                                    <td>
                                                        {' '}
                                                        <strong>Amount:</strong>{' '}
                                                    </td>
                                                    <td> ${" "}{subscription?.amount} </td>
                                                </tr>
                                                <tr style={{fontSize: "medium"}}>
                                                    <td>
                                                        {' '}
                                                        <strong>Company Name:</strong>
                                                    </td>
                                                    <td> {subscription?.company_Name}</td>
                                                </tr>
                                                <tr style={{fontSize: "medium"}}>
                                                    <td>
                                                        {' '}
                                                        <strong>Country Name:</strong>
                                                    </td>
                                                    <td> {subscription?.country_Name}</td>
                                                </tr>
                                                <tr style={{fontSize: "medium"}}>
                                                    <td>
                                                        {' '}
                                                        <strong>Address:</strong>
                                                    </td>
                                                    <td> {subscription?.address}</td>
                                                </tr>
                                                <tr style={{fontSize: "medium"}}>
                                                    <td>
                                                        {' '}
                                                        <strong>Created At:</strong>
                                                    </td>
                                                    <td> {moment(subscription?.created_at).format('ddd, D MMM YYYY HH:mm')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnCompletedPurchasesModal;