import GetAll_Sites from './GetAll_Sites';
import Common_Fun from 'src/HOC/Common_Fun';
import { siteHeadKey as headkey,siteColoumnKey as coloumnKey } from 'src/common/apiCommonData';

const baseUrl = process.env.REACT_APP_BASE_URL;
const getApiData = `${baseUrl}/sites/get?status=1`
const addDataRoute='/management/createSite'
const updateDataRoute='/management/createSite'
const slug = "site"

const Enhanced_GetSites = Common_Fun(GetAll_Sites, getApiData,addDataRoute,updateDataRoute,headkey, coloumnKey,slug);

export default Enhanced_GetSites;
