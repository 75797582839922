import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { currencies } from "src/common/apiCommonData";
import MenuItem from "@mui/material/MenuItem";
import { config } from "src/common/apiCommonData";
import axios from "axios";
import { fi } from "date-fns/locale";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import GrainIcon from "@mui/icons-material/Grain";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "src/content/dashboards/Crypto/PageHeader";
import { ToastContainer, toast } from "react-toastify";
interface UserData {
  _id?: number;
  name: string;
  email: string;
  password?: string;
  password_confirmation?: string;
  is_admin?: number;
  designation?: string;
}

interface optionData {
  value: string;
  label: string;
}

const Create_User: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const data = location.state as UserData;
  const [loading, setLoading] = useState(false);

  const [field, setField] = useState<UserData>({
    name: data?.name || "",
    email: data?.email || "",
    password: data?.password || "",
    password_confirmation: data?.password_confirmation || "",
    is_admin: data?.is_admin || 0,
    designation: data?.designation || "", // Add the new field
  });

  // Validation states
  const [error, setError] = useState({
    nameError: false,
    emailError: false,
    passwordError: false,
    passwordConfirmationError: false,
    designationError: false,
  });

  const token = localStorage.getItem("token");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setField((prevField) => ({
      ...prevField,
      [name]: value,
    }));

    // Validate name length (minimum 3 characters)
    setError((prevError) => ({
      ...prevError,
      nameError: name === "name" && value.length < 3,
    }));

    // Validate email using regex
    setError((prevError) => ({
      ...prevError,
      emailError:
        name === "email" &&
        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value),
    }));

    if (name === "password") {
      setError((prevError) => ({
        ...prevError,
        passwordError:
          value.length > 0 && (value.length < 6 || value.length > 20),
      }));
    }

    if (name === "password_confirmation") {
      setError((prevError) => ({
        ...prevError,
        passwordConfirmationError: value !== field.password,
      }));
    }
    if (name === "designation") {
      setError((prevError) => ({
        ...prevError,
        designationError: name === "designation" && value.length < 3,
      }));
    }
  };

  const baseUrl = process.env.REACT_APP_BASE_URL;


  const postData = () => {
    // Create a FormData object to hold the form data
    setLoading(true);
    const formData = new FormData();
    formData.append("name", field.name);
    formData.append("email", field.email);
    formData.append("designation", field.designation);

    if (data?._id == undefined) {
      formData.append("password", field.password);
      formData.append("password_confirmation", field.password_confirmation);
      // formData.append("is_admin",`${field.is_admin}`);
    }

    const api = data?._id ? `${baseUrl}/user/update/${data._id}` : `${baseUrl}/register`;

    // Perform the POST/PUT request using axios (or any other HTTP client library of your choice)
    axios.request({
        url: api,
        method: data?._id ? "put" : "post",
        data: data?._id ? field : formData,
        headers: {
          accept: "*/*",
          "Auth-Token": `${token}`,
        },
      })
      .then((response) => {
        if (data?._id) {
          notify("User Updated Successfully.", "success");
        } else {
          notify("User Added Successfully.", "success");
          setField({
            name: "",
            email: "",
            password: "",
            password_confirmation: "",
            is_admin: 0,
            designation: "", // Add the new field
          });
        }
        setStatus(true); // Set status to true to trigger the navigation to '/management/profile/getAllUsers'
        setLoading(false);
       
      })
      .catch((error) => {
        if (data?._id) {
          notify("Error occurred in updating user.", "error");
        } else {
          notify("Error occurred in adding user.", "error");
        }
        setLoading(false);
        console.error("Error posting data:", error);
        // Handle errors as per your requirements (e.g., display an error message to the user)
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if there are any errors before posting the data
    if (
      error.nameError ||
      error.emailError ||
      error.passwordError ||
      error.passwordConfirmationError ||
      error.designationError
    ) {
      alert("Please fill in all fields correctly");
    } else {
      postData();
      // Perform submission or further logic
    }
  };

  useEffect(() => {
    if (status) {
      // navigate("/management/getAllUsers");
    }
  }, [status, navigate]);


  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const loggedin_Name = localStorage.getItem("login_data");
  const parsedData = JSON.parse(loggedin_Name);
  const user = {
    name: parsedData?.data?.name,
    avatar: "/static/images/avatars/1.jpg",
  };

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <>
      {/* <Helmet>
        <title>{user.name} | National Web Quality Index</title>
      </Helmet> */}
      <section
        className="breadcrum-section"
        style={{ marginTop: "20px", marginLeft: "20px" }}
      >
        <div role="presentation" style={{ marginTop: "20px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="/management/getAllUsers"
            >
              <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Management
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="/management/getAllUsers"
            >
              <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Users
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {data ? "Update user" : "Add user "}
            </Typography>
          </Breadcrumbs>
        </div>
      </section>
      <div style={{ width: "95%", margin: "auto" }}>
        <PageTitleWrapper>
          <PageHeader title={data ? "Update user" : "Add user"} />
        </PageTitleWrapper>
      </div>
      <Box
        component="form"
        sx={{
          backgroundColor: "white",
          width: "90%",
          margin: "0px auto",
          padding: "2rem",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          fontFamily: "'Roboto', sans-serif", // Add your desired font
        }}
        noValidate
        autoComplete="off"
      >
        {/* <h1 style={{fontWeight:'semibold',marginBottom:'20px',textAlign:'left'}}>{data ? "Update User" : "Add User"}</h1> */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
          <TextField
            label="Name"
            name="name"
            value={field.name}
            variant="outlined"
            onChange={handleChange}
            error={error.nameError}
            helperText={
              error.nameError ? "Name must be at least 3 characters long" : ""
            }
          />
          <TextField
            label="Email"
            name="email"
            value={field.email}
            disabled={data ? true : false}
            variant="outlined"
            onChange={handleChange}
            error={error.emailError}
            helperText={error.emailError ? "Invalid email format" : ""}
          />
        </Box>
        {data?._id ? null : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              marginTop: "15px",
            }}
          >
            <TextField
              label="Password"
              name="password"
              value={field.password}
              variant="outlined"
              onChange={handleChange}
              error={error.passwordError}
              helperText={
                error.passwordError
                  ? "Password length should be between 6 and 20 characters"
                  : ""
              }
            />
            <TextField
              label="Confirm password"
              name="password_confirmation"
              value={field.password_confirmation}
              variant="outlined"
              onChange={handleChange}
              error={error.passwordConfirmationError}
              helperText={
                error.passwordConfirmationError
                  ? "Password confirmation does not match"
                  : ""
              }
            />
            <TextField
              label="Designation"
              name="designation"
              value={field.designation}
              variant="outlined"
              onChange={handleChange}
              error={error.designationError}
              helperText={
                error.designationError ? "Designation is required" : ""
              }
            />
          </Box>
        )}
        {data ? (
          <Button
            onClick={handleSubmit}
            className="common-black-btn"
            variant={"contained"}
            sx={{
              marginTop: "2rem",
              // backgroundColor: "#4CAF50", // Add your desired button color
              // color: "white", // Add your desired text color
            }}
          >
            {loading ? "Updating..." : `Update User`}
          </Button>
        ) : (
          <Button
            onClick={handleSubmit}
            variant={"contained"}
            sx={{
              marginTop: "2rem",
              backgroundColor: "#4CAF50", // Add your desired button color
              color: "white", // Add your desired text color
            }}
            disabled={
              !field.name ||
              !field.email ||
              !field.password ||
              !field.password_confirmation ||
              !field.designation ||
              error.nameError ||
              error.emailError ||
              error.passwordError ||
              error.passwordConfirmationError ||
              error.designationError
            }
          >
            {loading ? "Adding..." : `Add User`}
          </Button>
        )}
      </Box>
      <ToastContainer />
    </>
  );
};

export default Create_User;
