import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import { callApi, postApi } from "src/action/RestClient";
import { useLocation, useNavigate } from "react-router";
import CommonTable from "src/common/table/table";
import moment from "moment";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, Button, FormControl, IconButton, Select, MenuItem, Tooltip, CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";
import ResponsiveDialog from "src/common/DeleteDialogue";
import { notify } from "src/common/toastMesaage";
import FormControlLabel from '@mui/material/FormControlLabel';
import { IOSSwitch } from "../BillingAndSubscriptions/manageTax/switchComonent";

const ManageCountry = () => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [countryData, setCountryData] = useState([]);
    const [userLoading, setUserLoading] = useState(false);
    const [loadingStates, setLoadingStates] = useState({});
    const [page, setPage] = useState(0); // Current page index
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRecoard, setTotalRecoard] = useState(0);
    const [sortby, setSortBy] = useState(null);
    const [sort, setSort] = useState(null);
    const [search, setSearch] = useState('');
    const [searchTerm, setSearchTerm] = useState(null);
    const [filteredCountries, setFilteredCountries] = useState([]);


    const handleChange = (e) => {
        setSearch(e?.target?.value);
        setFilteredCountries(searchCountries(countryData, search));
    };


    const getAllCountry = async () => {
        setUserLoading(true)
        try {
            const response = await callApi('GET', "/get-all-countries", null, null);
            const data = response?.data?.data;
            setCountryData(data);
            setFilteredCountries(data);
            setTotalRecoard(data?.length);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };

    useEffect(() => {
        setFilteredCountries(searchCountries(countryData, search));
    }, [search]);

    useEffect(() => {
        if (initialLoad) {
            getAllCountry();
            setInitialLoad(false);
        }
    }, [initialLoad]);


    useEffect(() => {
        if (!initialLoad) {
            getAllCountry();
        }
    }, [searchTerm])



    const handleSort = (sortby, sort) => {
        setSortBy(sortby);
        setSort(sort);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPage(data);
        setPage(0);
    }

    const handleSearch = (arr, search) => {
        const filteredData = searchCountries(arr, search);
    };

    function searchCountries(data, searchTerm) {
        const lowerCaseSearchTerm = searchTerm?.toLowerCase();
        return data?.filter(country => country?.country_name?.toLowerCase().includes(lowerCaseSearchTerm));
    }


    const formatStatusCell = (params) => {
        // console.log("params.row", params.row)
        const user = params.row;
        return (
            <div>
                {loadingStates[user._id] ? <Box gap-3 sx={{  width: "100px" }}>
                                <CircularProgress color="inherit" size={18} />
                              </Box> :
                    <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }}
                            checked={user?.status === "active" ? true : false}
                            onChange={() => toggleButton(user?.status === "active" ? "active" : "inactive", user)}
                        />}
                    />
                }
            </div>
            // <div>
            //     <MySwitchComponent data={user} getAllData={getAllData} />
            // </div>
        );
    }
    // /update-country/{id}  Posttype:- send all updated data (country_code,country_name,)
    const toggleButton = async (status, user) => {
        // const updatedData = taxData?.map(item =>
        //     item._id === user?._id ? { ...item, status: status === "active" ? "inactive" : "active" } : item
        // );
        const data = {
            status: status === "active" ? "inactive" : "active",
            country_code: user?.country_code,
            country_name: user?.country_name,
        }
        setLoadingStates(prev => ({ ...prev, [user._id]: true }));
        try {
            const response = await postApi(`/update-country/${user?._id}`, data);
            if (response?.status) {
                notify("Country status update successfully", "success");
                getAllCountry();
            } else {
                notify(response?.error, "error");
            }
        } catch (error) {
            notify(error.message, "error");
        } finally {
            setLoadingStates(prev => ({ ...prev, [user._id]: false }));
        }
    };


    const columns = [
        { field: 'country_name', label: 'Country NAME', width: 240, align: 'left' },
        { field: 'country_code', label: 'Country Code', width: 240, align: 'left' },
        { field: 'status', label: 'Status', width: 240, renderCell: formatStatusCell, align: 'left' },
    ];




    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            MASTERS MANAGEMENT
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Manage Country
                                        </Typography>
                                    </Breadcrumbs>
                                </div>
                            </section>
                            <div className="iq-card position-relative rounded overflow-hidden">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Manage Country</h4>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'end',
                                        }}
                                    >
                                        <div className="form-outline" data-mdb-input-init>
                                            <input style={{minWidth: '200px'}}
                                                type="text"
                                                id="search"
                                                name="search"
                                                className="form-control"
                                                value={search}
                                                placeholder="Search Country by name"
                                                onChange={(e) => { handleChange(e) }}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn"
                                            style={{ backgroundColor: '#282621' }}
                                            data-mdb-ripple-init
                                            onClick={() => handleSearch(countryData, search)}
                                        >
                                            <i className="fas fa-search text-light"></i>
                                        </button>
                                    </div>
                                </div>
                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={filteredCountries}
                                        columns={columns}
                                        // enablePagination
                                        type={"managePackages"}
                                        loading={userLoading}
                                    // page={page}
                                    // count={totalRecoard}
                                    // rowsPerPage={rowsPerPage}
                                    // handleSort={handleSort}
                                    // onPageChange={handlePageChange}
                                    // onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
            <ToastContainer />
        </>
    );
};
export default ManageCountry;
