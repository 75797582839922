import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import moment from 'moment';
import { callApi } from "src/action/RestClient";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import CommonTable from "src/common/table/table";


const AgencyAccounts = () => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [userLoading, setUserLoading] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [page, setPage] = useState(0); // Current page index
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRecoard, setTotalRecoard] = useState(0);
    const [sortby, setSortBy] = useState(null);
    const [sort, setSort] = useState(null);


    const getAllData = async () => {
        setUserLoading(true)
        try {
            let options = {};
            if (page !== undefined) options.page = page + 1;
            if (rowsPerPage !== undefined) options.perpage = rowsPerPage;
            if (sort !== null) options.sort = sort;
            if (sortby !== null) options.sortBy = sortby;
            const response = await callApi('GET', '/get/agency?status=active', null, options);
            const data = response?.data?.data?.data;
            setTotalRecoard(data.length);
            setOriginalData(data);
            setUserLoading(false)
        } catch (error) {
            console.error('Error:', error);
            setUserLoading(false)
            throw error;
        }
    };


    useEffect(() => {
        if (initialLoad) {
            getAllData();
            setInitialLoad(false);
        }
    }, [initialLoad]);

    useEffect(() => {
        if(!initialLoad){
          getAllData();
        }
      }, [page, rowsPerPage, sort, sortby ])




    const handleSort = (sortby, sort) => {
        setSortBy(sortby);
        setSort(sort);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPage(data);
        setPage(0);
    }


    const statusCall = (params) => {
        const status = params.row.status;
        if (status == "inactive") {
            return (
                <div className="table-no-badge">
                  <p >{"INACTIVE"}</p>
                </div>
              );
         
        }
        else {
            return (
                <div className="table-yes-badge">
                  <p>{"ACTIVE"}</p>
                </div>
              );
        }
      };


    const formatDateCell = (params) => {
        const dateString = params?.value;
        const date = moment(dateString);
        const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
        const timeAgo = date.fromNow();
        return (
          <div>
            <p className="fw-bold">{formattedDate} <br></br><small className="fw-normal">({timeAgo})</small></p>
          </div>
        );
      };



    const columns = [
        // { field: 'name', label: 'Name', width: 240, align: 'left' },
        { field: 'company_Name', label: 'company Name', width: 240, align: 'left' },
        { field: 'country_Name', label: 'country NameL', width: 240, align: 'left' },
        { field: 'status', label: 'Status', width: 240, align: 'left', renderCell: statusCall, },
        { field: 'created_at', label: 'REQUESTED Date', width: 240, renderCell: formatDateCell, align: 'left' },
    ];


    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Dashboard
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Agency Accounts
                                        </Typography>
                                    </Breadcrumbs>
                                </div>
                            </section>

                            <div className="iq-card position-relative rounded overflow-hidden">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Agency Accounts</h4>
                                    </div>
                                </div>

                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={originalData}
                                        columns={columns}
                                        enablePagination
                                        type={"site"}
                                        loading={userLoading}
                                        page={page}
                                        count={totalRecoard}
                                        rowsPerPage={rowsPerPage}
                                        handleSort={handleSort}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </>
    );
};
export default AgencyAccounts;
