import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import GrainIcon from '@mui/icons-material/Grain';
import HomeIcon from '@mui/icons-material/Home';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import StreetviewIcon from '@mui/icons-material/Streetview';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import ResponsiveDialog from "src/common/DeleteDialogue";
import CommonUserModal from "src/common/commonUserModal";
import Footer from "src/components/Footer";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { Userdata } from 'src/models/crypto_order';
import CommonTable from "src/common/table/table";
import moment from 'moment';
import { ToastContainer } from 'react-toastify'
import { postData } from "src/action/RestClient";
import DashboardAction from "src/action/DashboardAction";
import { parentRoute } from "src/action/NavigationService";
import { notify } from "src/common/toastMesaage";
import { Co2Sharp } from "@mui/icons-material";


const TopSites = () => {
    const [originalData, setOriginalData] = useState([])
    const loggedin_Name = localStorage.getItem("login_data");
    const parsedData = JSON.parse(loggedin_Name);
    const [isAuth, setIsAuth] = useState();
    const user = {
        name: parsedData?.data?.name,
        avatar: "/static/images/avatars/1.jpg",
    };
    // NEW CHANGE STARTS FROM HERE

    const renderActionsCell = (params) => {
        const user = params.row;
        return (
            <div>
                <Tooltip title="Edit Site" arrow onClick={() => updateUserData(user)} >
                    <IconButton color="inherit" size="small">
                        <EditTwoToneIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Site" arrow onClick={() => deleteUserData(user._id)} >
                    <IconButton color="inherit" size="small">
                        <DeleteTwoToneIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={`View Site`}
                    arrow
                    onClick={() => handleRedirectPage(user._id)}
                >
                    <IconButton color="inherit" size="small">
                        <RemoveRedEyeOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={`View Report`}
                    arrow
                    onClick={() => handleReportGenerate(user)}
                >
                    <IconButton color="inherit" size="small">
                        <PreviewIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={`MileStone Report`}
                    arrow
                    onClick={() => handleMileStoneReport(user._id)}
                >
                    <IconButton color="inherit" size="small">
                        <StreetviewIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={`Manage Ranking`}
                    arrow
                    onClick={() => handleRankingField(user?.name)}
                >
                    <IconButton color="inherit" size="small">
                        <AddModeratorIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const handleRankingField = (name) => {
        navigate(`/site-management/manageRanking/top/${name}`)
    }

    const handleMileStoneReport = (id) =>{
        navigate(`/site-management/mileStoneReportList/top/${id}`)
    }

    const handleRedirectPage = (item) => {
        navigate(`/site-management/siteDetails/${item}`)
    }

    const handleReportGenerate  = (user) => {
        const siteUrl = user?.name
        const logindataString = localStorage.getItem('login_data');
        const logindata = JSON.parse(logindataString);
        const uuid = logindata?.data?.uuid;
        const url = `${parentRoute()}/dashboard/${siteUrl}?uuid=${uuid}`;
        if(uuid){
            window.open(`${url}`, '_blank')
        }
        // window.location.href = 'https://app.websiteranking.ai/dashboard/apple.com'; 
    }


    const deleteUserData = async (id) => {
        // await getAllData();
        // if (auth.current === true) {
        //     setIsAuth(true);
        // } else {
            setUserDeleteId(id);
            setDeleteModal(true);
        // }
    };

    const updateUserData = async (data) => {
        // await getAllData();
        if (auth.current === true) {
            setIsAuth(true)
        } else {
            navigate(`/site-management/createSite`,  { state: {data: data , flag: "topSites"}});
        }
    };

    const renderStatusCell = (params) => {
        const status = params.row.status;
        // alert(status)

        if (status == "1") {
            return <span>Completed</span>;
        } else if (status == "0") {
            return <span>Awaited</span>;
        } else {
            return <span>{status}</span>;
        }
    };


    const renderRowId = (params) => {
        const id = params?.row?._id?.$oid;
        return id
    }


    const formatDateCell = (params) => {
        const dateString = params?.value;
        const date = moment(dateString);
        const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
        const timeAgo = date.fromNow();
        return (
            <div>
                <p className="fw-bold">{formattedDate} <br></br><small className="fw-normal">({timeAgo})</small></p>
            </div>
        );
    };


    const formatLastReportDateCell = (params) => {
        const dateString = params?.row.last_report_generated;
        if (dateString == "No insights available") {
            return (
                <div>
                    <p className="fw-bold">{dateString} </p>
                </div>
            );
        } else {
            const date = moment(dateString);
            const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
            const timeAgo = date.fromNow();
            return (
                <div>
                    <p className="fw-bold">{formattedDate} <br></br><small className="fw-normal">({timeAgo})</small></p>
                </div>
            );

        }
    };

    const handleGenerateReport = (id) => {
        generateReportApi(id)
    }


    const generateReport = (params) => {
        const { last_report_generated: status, reportProgressStatus, reportProgressTime, _id, reportTodayPendingOrDoneStatus } = params.row;
        const renderReportStatus = () => {
          const isDisabled = reportTodayPendingOrDoneStatus;
          if (isDisabled) {
            return (
              <div className={`fw-bold btn btn-link ${isDisabled ? 'disabled' : ''}`} >
                <a className="fw-bold btn btn-link" aria-disabled={isDisabled} style={{ pointerEvents: isDisabled ? 'none' : 'auto', color: isDisabled ? 'gray' : 'inherit' }}>
                  Generate Report
                </a>
              </div>
            );
          } else {
            if (reportProgressStatus === "NA" && reportProgressTime === null) {
              return (
                <div onClick={() => handleGenerateReport(_id)}>
                  <a className="fw-bold btn btn-link">Generate Report</a>
                </div>
              );
            } else if (reportProgressStatus === "pending") {
              return (
                <div>
                  <p className="fw-bold" style={{ marginLeft: '14px' }}>
                    Generate Report <br /><small className="fw-normal">(Request Pending)</small>
                  </p>
                </div>
              );
            } else if (reportProgressStatus === "working") {
              return (
                <div>
                  <p className="fw-bold" style={{ marginLeft: '14px' }}>
                    Generate Report <br /><small className="fw-normal">(working since {reportProgressTime})</small>
                  </p>
                </div>
              );
            }
          }
        };
      
        return status === "No insights available" ? renderReportStatus() : renderReportStatus();
      };
      
   
    const columns = [
        // { field: 'index', label: 'ID', width: 100, align: 'left' },
        { field: 'name', label: 'SITE NAME', width: 240, align: 'left' },
        { field: 'url', label: 'SITE URL', width: 240, align: 'left' },
        { field: 'created_at', label: 'Onboarded Date', width: 240, renderCell: formatDateCell, align: 'left' },
        { field: 'last_report_generated', label: 'LAST REPORT GENERATED', width: 270, renderCell: formatLastReportDateCell, align: 'left' },
        { field: 'actions', label: 'Actions', width: 240, renderCell: renderActionsCell, align: 'left' },
        { field: 'generate_report', label: 'Generate Report', width: 240, align: 'left', renderCell: generateReport },
    ];



    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [userDeleteId, setUserDeleteId] = useState();
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [userAnswer, setUserAnswer] = useState(false);
    const auth = React.useRef(false);
    const [userLoading, setUserLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const token = localStorage.getItem('token') || '';
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0); // Current page index
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRecoard, setTotalRecoard] = useState(0);
    const [sortby, setSortBy] = useState("created_at");
    const [sort, setSort] = useState("desc");
    const [searchTerm, setSearchTerm] = useState(null);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userDeleteApi = `${baseUrl}/sites/delete/`;
    const authtoken = token;


    useEffect(() => {
        if (userData) {
            const indexedUserData = userData?.map((item, index) => ({ ...item, index: index + 1 }));
            setOriginalData(indexedUserData);
            setFilteredData(indexedUserData)
        }
    }, [userData])


    const getAllData = async () => {
        setUserLoading(true);
        const apiEndpoint = `${baseUrl}/top100/sites`;
        let options = {};
        if (page !== undefined) options.page = page + 1;
        if (rowsPerPage !== undefined) options.perpage = rowsPerPage;
        if (sort !== null) options.sort = sort;
        if (sortby !== null) options.sortBy = sortby;
        if (searchTerm != null) options.searchTerm = searchTerm;
        return await axios.get(apiEndpoint, {
            headers: {
                accept: '*/*',
                'Auth-Token': authtoken,
            },
            params: options
        })
            .then((response) => {
                setUserData(response.data.data.data);
                setTotalRecoard(response.data.data.total)
                setUserLoading(false);
            }).catch((error) => {
                auth.current = true;
                setUserLoading(false);
                if (error.response && error.response.status === 401) {
                    DashboardAction.checklogoutService()
                  }
            })

    };


    useEffect(() => {
        if (initialLoad) {
            getAllData();
            setInitialLoad(false);
        }
    }, [initialLoad]);


    // useEffect(() => {
    //     if (!initialLoad) {
    //         getAllData();
    //     }
    // }, [userAnswer]);

    useEffect(() => {
        if(userAnswer){
            notify("Sites Deleted Successfully", "success");
            if (!initialLoad) {
                getAllData();
                setUserAnswer(false);
            }
        }
    }, [userAnswer]);

    useEffect(() => {
        if (!initialLoad) {
            getAllData();
        }
    }, [page, rowsPerPage, sort, sortby, searchTerm])


    const createData = async () => {
        await getAllData();
        if (auth.current === true) {
            setIsAuth(true);
        } else {
            navigate(`/management/createSite`);
        }
    };


    const handleSort = (sortby, sort) => {
        setSortBy(sortby);
        setSort(sort);
    };

    const handleSearch = (search) => {
        setSearchTerm(search);
        setPage(0);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPage(data);
        setPage(0);
    }

    const generateReportApi = async(id) => {
        const options = { id : id };

        const url = '/genrate/site/report';
        const data = {id : id };
        const response = await postData(url, data);
        if(response?.status){
            const data = response
            if(response?.status){
                notify(response.message, 'success');
                getAllData();
            }else{
                notify('Currently, we cannot generate your app report.', 'error')
            }
            console.log("data",data)
            // if (data?.getTime == null && data?.request_id == null) {
            //     notify('Currently, we cannot generate your app report.', 'error')
            // } else {
            //     getAllData();
            //     notify(response.message, 'success')
            // }
        }
        // return axios.post(`${baseUrl}/genrate/site/report`, null, {
        //     headers: {
        //         accept: '*/*',
        //         'Auth-Token': authtoken,
        //     },
        //     data: options
        // })
            // .then((response) => {
            //     console.log("response", response.data);
            //     const data = response?.data
            //     if (data?.getTime == null && data?.request_id == null) {
            //         notify('Currently, we cannot generate your app report.', 'error')
            //     } else {
            //         getAllData();
            //     }
            // })
            // .catch((error) => {
            //     // Handle error
            // });
    }



    const slicedData = filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            {/* <Authenticate_Dialog
        title="You are not authenticated. Please login again"
        open={isAuth}
        setOpen={setIsAuth}
      /> */}
            {/* <CommonUserModal open={modalOpen} setOpen={setModalOpen} data={modalData} /> */}
            <Helmet>
                <title>{user.name ? user.name : ""}</title>
            </Helmet>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Sites
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Top Sites
                                        </Typography>
                                    </Breadcrumbs>
                                </div>
                            </section>
                            {/* <PageTitleWrapper>
                                <PageHeader title="Sites Onboarded" />
                            </PageTitleWrapper> */}
                            <div className="iq-card position-relative rounded overflow-hidden">
                                {/* <div style={{ display: "flex", justifyContent: "end", width: "95%" }}>
                                    <Button sx={{ margin: 1 }} variant="contained" onClick={createData}>
                                        Add site
                                    </Button>
                                    </div> */}
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Top Sites</h4>
                                    </div>
                                    {/* <div
                                        className="align-items-center custom-toolbar-button d-flex gap-3 justify-content-end"

                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                marginRight: "16px",
                                                fontWeight: 600,
                                                margin: "auto",
                                            }}
                                        >
                                            Sites Onboarded: {totalRecoard}
                                        </Typography>
                                        <Button variant="contained" style={{ backgroundColor: "#282621" }} className="rounded" onClick={createData}>
                                            Onboard New Site
                                        </Button>
                                    </div> */}
                                    <div
                                        className="input-group w-auto"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "end",
                                        }}
                                    >
                                        <div className="form-outline" data-mdb-input-init>
                                            <input
                                                type="text"
                                                id="search"
                                                name="search"
                                                className="form-control"
                                                value={search}
                                                placeholder="Search by site name"
                                                // onFocus={() => setSearch("")}
                                                // onBlur={() => setSearch("")}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn" style={{ backgroundColor: "#282621" }}
                                            data-mdb-ripple-init
                                            onClick={() => handleSearch(search)}
                                        >
                                            <i className="fas fa-search text-light"></i>
                                        </button>
                                    </div>
                                </div>

                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={filteredData}
                                        columns={columns}
                                        enablePagination
                                        type={"site"}
                                        loading={userLoading}
                                        page={page}
                                        count={totalRecoard}
                                        rowsPerPage={rowsPerPage}
                                        handleSort={handleSort}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
            <ResponsiveDialog
                openDialogue={openDeleteModal}
                setopenDialogue={setDeleteModal}
                title="site"
                setAnswer={setUserAnswer}
                id={userDeleteId}
                apiUrl={userDeleteApi}
            />
            <Footer />
            <ToastContainer />
        </>
    );
};
export default TopSites;
