import { TextField,Button } from '@mui/material'
import React, { useState } from 'react'
import PageTitleWrapper from 'src/components/PageTitleWrapper'
import PageHeader from 'src/content/dashboards/Crypto/PageHeader'
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import DashboardAction from 'src/action/DashboardAction';

const Add_Setting = () => {
  const loggedin_Name=localStorage.getItem('login_data');
  const [settingData,setSettingData] = useState([])
  const parsedData=JSON.parse(loggedin_Name)
  const user = {
    name: parsedData?.data?.name,
    avatar: '/static/images/avatars/1.jpg'
  };

  const baseUrl = process.env.REACT_APP_BASE_URL
  const token = localStorage.getItem(`token`);
  const getSettingData = () =>{
    axios.get(`${baseUrl}/settings`,{
      headers: {
        accept: "*/*",
        "Auth-Token": `${token}`,
      },
    }).then((res)=>{
      setSettingData(res.data?.data?.data)
    }).catch((error)=>{
      if (error.response && error.response.status === 401) {
        DashboardAction.checklogoutService()
      }
      console.log("Error occured",error)
    })
  }

  const handleChange = (e) =>{
    
  }

  React.useEffect(()=>{
    getSettingData();
  },[])
  return <>
  <Helmet>
        <title>{user.name ? user.name : ""} </title>
    </Helmet>
  <section className="breadcrum-section" style={{marginTop:'20px',marginLeft:'20px'}}>
      <div role="presentation" style={{marginTop:'20px'}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          // href="/configuration/addSetting"
          href='/'
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Management
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Settings
        </Typography>
      </Breadcrumbs>
    </div>
        </section>
  <PageTitleWrapper>
    <PageHeader title="Settings" />
  </PageTitleWrapper>
  <div style={{display:'flex',width:"95%",marginLeft:'40px',marginTop:'15px',gap:'10px',flexDirection:'column'}}>
  {settingData.map((item,index)=>{
   return <TextField key={index} id="outlined-basic" label={item.label} variant="outlined" style={{width:'100%',marginTop:'5px'}} defaultValue={item.value} onChange={handleChange}  />
  })}
  
  </div>
  <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'20px'}}>
  <Button
          className="common-black-btn"
          // color="primary"
        >
          Add Setting
        </Button>
  </div>
  <div>
  </div>
  </>
}

export default Add_Setting
