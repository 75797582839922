import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, useNavigate, useParams } from "react-router";
import CommonTable from "src/common/table/table";
import moment from "moment";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { callApi, postData } from "src/action/RestClient";
import StreetviewIcon from '@mui/icons-material/Streetview';
import MySwitchComponent from "./switchComonent";
import HistoryIcon from '@mui/icons-material/History';
import GrainIcon from '@mui/icons-material/Grain';


const MileStoneHistory = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [milestoneHistry, setMilestoneHistory] = useState(location?.state ? location?.state : [])

   
    const formatDateCell = (params) => {
        const dateString = params?.row?.date;
        const date = moment(dateString);
        const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
        const timeAgo = date.fromNow();
        return (
            <div>
                <p className="fw-bold">{formattedDate} 
                {/* <br></br><small className="fw-normal">({timeAgo})</small> */}
                </p>
            </div>
        );
    };

    const columns = [
        { field: 'status', label: 'status', width: 240, align: 'left' },
        { field: 'date', label: 'MileStone Update DATE', width: 240, renderCell: formatDateCell, align: 'left' },
    ];



    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            onClick={() => navigate(-1)}
                                            // href="/site-management/mileStoneReportList"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            MileStoneList
                                        </Link>
                                    </Breadcrumbs>
                                </div>
                            </section>
                            <div className="iq-card position-relative rounded overflow-hidden">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Milestone History </h4>
                                    </div>
                                    <div className="align-items-center custom-toolbar-button d-flex gap-3 justify-content-end"  >
                                    </div>

                                </div>


                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={milestoneHistry}
                                        columns={columns}
                                        // enablePagination
                                        type={"managePackages"}
                                        // loading={loading}
                                    // page={page}
                                    // count={totalRecoard}
                                    // rowsPerPage={rowsPerPage}
                                    // handleSort={handleSort}
                                    // onPageChange={handlePageChange}
                                    // onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                            </div >
                        </div >
                    </div >
                </div >
            </div >

        </>
    );
};
export default MileStoneHistory;
