import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import axios from 'axios'
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/content/dashboards/Crypto/PageHeader';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { Helmet } from 'react-helmet-async';
import Box from "@mui/material/Box";
import { TextField, Button } from '@mui/material';
import Switch from '@mui/material/Switch';
import QuestionModal from "src/Page Variations/algorithms/questionModal";
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { postData } from "src/action/RestClient";
import CircularProgress from '@mui/material/CircularProgress';
import DashboardAction from "src/action/DashboardAction";
import { getCategoryTitle } from "src/sites/categoryTitle";
import { useLocation, useNavigate } from "react-router";

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));



const Question = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [questionsData, setQestionsData] = useState();
    const [index, setIndex] = useState(location?.state?.selectedindex ? location?.state?.selectedindex :  0);
    const [questionId, setQuestionId] = useState();
    const [questionLoading, setQuestionLoading] = useState(false)
    const [category, setCategory] = useState();
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [isActive, setIsActive] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem('token') || '';


    const handleEditClick = (question, category, catIndex) => {
         setSelectedQuestion(question);
         setCategory(category),
         setIndex(catIndex),
        navigate(`/algorithm/updateQuestionnaire`, { state: { question, category, catIndex } });
        //  setShowModal(true);
    };

    const changeIndex = (item) =>{
        setIndex(item)
    }

    // Function to close the modal
    const closeModal = () => {
        setShowModal(false);
    };

    const toggleButton = (status, question, category, catIndex) => {
        setIndex(catIndex )
        const originalObject = question;
        const newObj = { ...originalObject, status: status, category: category, id: questionId };
        statusChange(newObj)
    };

    const statusChange = async (data) => {
        try {
            const url = '/update/questions';
            const response = await postData(url, data);
            if (response?.status) {
                getQuestionData();
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const getQuestionData = async () => {
        setQuestionLoading(true)
        await axios.get(`${baseUrl}/get/questions`, {
            headers: {
                accept: '*/*',
                'Auth-Token': `${token}`,
            },
        }).then((res) => {
            setQestionsData(res?.data?.data?.data);
            setQuestionId(res?.data?.data?._id?.$oid)
            setQuestionLoading(false)
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                DashboardAction.checklogoutService()
              }
            console.log("Error occured in fetching the frameWork", error)
            setQuestionLoading(false)
        })
    }

    useEffect(() => {
        getQuestionData();
    }, [])

    return (
        <>
            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                <div role="presentation" style={{ marginTop: '20px' }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href="/"
                        >
                            <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Dashboard
                        </Link>
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Questions
                        </Typography>
                    </Breadcrumbs>
                </div>
            </section>
            <PageTitleWrapper>
                <PageHeader title="Questions" />
            </PageTitleWrapper>

            <div className="accordion" id="accordionExample" style={{ width: '95%', margin: 'auto', marginTop: '20px' }}>
                {questionLoading ?
                    <>
                        <Box className='align-items-center justify-content-center py-5 d-flex table-loader'>
                            <CircularProgress />
                        </Box>
                    </> :
                    <>
                        {questionsData !== undefined && questionsData !== null && Object.keys(questionsData).length > 0 ?
                            <>
                                {Object.entries(questionsData).map(([category, questions], catIndex) => (
                                    <div className="accordion-item" key={catIndex}>
                                        <h2 className="accordion-header" id={`heading${catIndex}`}>
                                            <button
                                                className={`accordion-button ${catIndex === index ? 'collapsed' : ''}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${catIndex}`}
                                                aria-expanded={catIndex === index ? 'true' : 'false'}
                                                aria-controls={`collapse${catIndex}`}
                                            >
                                                {getCategoryTitle(category)}
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapse${catIndex}`}
                                            className={`accordion-collapse collapse ${catIndex === index ? 'show' : ''}`}
                                            aria-labelledby={`heading${catIndex}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Question</th>
                                                            <th>Business Implication Pass</th>
                                                            <th>recommendation Pass</th>
                                                            <th>Weight</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {questions.map((question, quesIndex) => (
                                                            <tr key={quesIndex}>
                                                                <td>{quesIndex + 1}. {question.question}</td>
                                                                <td>{question.business_Implication_pass}</td>
                                                                <td>{question.recommendation_pass}</td>
                                                                <td>{question.weight}</td>
                                                                <td>
                                                                    <FormControlLabel
                                                                        control={<IOSSwitch sx={{ m: 1 }}
                                                                            defaultChecked={question.status == "active"}
                                                                            onChange={() => toggleButton(question?.status == "active" ? "inActive" : "active", question, category, catIndex)}
                                                                        />}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <button className="questionBtn btn"  onClick={() => handleEditClick(question, category, catIndex)}>Update</button>
                                                                    {/* <button className="btn btn-danger  ms-2">Delete</button> */}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                            : ""}
                    </>}
            </div>
            <QuestionModal isOpen={showModal} onClose={closeModal} question={selectedQuestion} questionId={questionId} category={category} getQuestionData={getQuestionData} selectedindex={index} changeIndex={(item)=>changeIndex(item)}/>
        </>
    );
};
export default Question;


