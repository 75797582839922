import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
const navigateRef = React.createRef();

const setNavigateRef = (navigate) => {
  navigateRef.current = navigate;
}

const navigate = (name) => {
  if (navigateRef.current){
    const navigateFn = navigateRef.current
    navigateFn(name)
  } 
}


export const useNavigateParams = () => {
    const location = useLocation();
    return location.state
  }



const localEnvironment = process.env.REACT_APP_LOCAL_ROUTE === 'true';
const stagingRoute = process.env.REACT_APP_STAGING_ROUTE === 'true';
const productionEnvironment = process.env.REACT_APP_PROD_ROUTE === 'true';

  export const parentRoute = () =>{
    if(localEnvironment)  return 'http://localhost:3001'
    else if(stagingRoute) return  "https://app.staging.websiteranking.ai"
    else if(productionEnvironment) return "https://app.websiteranking.ai"
    else return ;
  } 

export default { navigate, setNavigateRef };