import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import GrainIcon from '@mui/icons-material/Grain';
import HomeIcon from '@mui/icons-material/Home';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import ResponsiveDialog from "src/common/DeleteDialogue";
import CommonUserModal from "src/common/commonUserModal";
import Footer from "src/components/Footer";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { Userdata } from 'src/models/crypto_order';
import CommonTable from "src/common/table/table";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify'
import DashboardAction from "src/action/DashboardAction";
import { parentRoute } from "src/action/NavigationService";


interface FetchOptions {
  page?: number;
  searchQuery?: string;
  perpage?: number;
  sort?: string;
  sortBy?: string;
  startDate?: string;
  endDate?: string;
  searchTerm?: string;
}



const GetAll_Sites = () => {
  const [originalData, setOriginalData] = useState([])
  const loggedin_Name = localStorage.getItem("login_data");
  const parsedData = JSON.parse(loggedin_Name);
  const [isAuth, setIsAuth] = useState<boolean>();
  const user = {
    name: parsedData?.data?.name,
    avatar: "/static/images/avatars/1.jpg",
  };
  // NEW CHANGE STARTS FROM HERE

  const renderActionsCell = (params) => {
    const user = params.row;
    return (
      <div>
        <Tooltip title="Edit Site" arrow onClick={() => updateUserData(user)} >
          <IconButton color="inherit" size="small">
            <EditTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Site" arrow onClick={() => deleteUserData(user?._id)} >
          <IconButton color="inherit" size="small">
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={`View Site`}
          arrow
          onClick={() => handleRedirectPage(user._id)}
        >
          <IconButton color="inherit" size="small">
            <RemoveRedEyeOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={`View Report`}
          arrow
          onClick={() => handleReportGenerate(user)}
        >
          <IconButton color="inherit" size="small">
            <PreviewIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const handleRedirectPage = (item) => {
    navigate(`/site-management/siteDetails/${item?.$oid}`)
  }

  const handleReportGenerate = (user) => {
    const siteUrl = user?.name    
    const logindataString = localStorage.getItem('login_data');
    const logindata = JSON.parse(logindataString);
    const uuid = logindata?.data?.uuid;
    const url = `${parentRoute()}/dashboard/${siteUrl}?uuid=${uuid}`;
    if (uuid) {
      window.open(`${url}`, '_blank')
    }
    // window.location.href = 'https://app.websiteranking.ai/dashboard/apple.com'; 
  }

  const deleteUserData = async (id: string) => {
    // await getAllData();
    // if (auth.current === true) {
    //   setIsAuth(true);
    // } else {
    setUserDeleteId(id);
    setDeleteModal(true);
    // }
  };

  const updateUserData = async (data: Userdata) => {
    // await getAllData();
    if (auth.current === true) {
      setIsAuth(true)
    } else {
      // navigate(`/management/createSite`, { state: data });
      navigate(`/site-management/createSite`, { state: { data: data, flag: "allSite" } });
    }
  };

  const renderStatusCell = (params) => {
    const status = params.row.status;
    // alert(status)

    if (status == "1") {
      return <span>Completed</span>;
    } else if (status == "0") {
      return <span>Awaited</span>;
    } else {
      return <span>{status}</span>;
    }
  };

  const timestampToDate = (timestamp) => {
    const date = new Date(+timestamp)
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const renderRowId = (params) => {
    const id = params?.row?._id?.$oid;
    return id
  }


  const formatDateCell = (params: any) => {
    const inputDate = params?.value?.$date?.$numberLong;
    const timestamp = parseInt(inputDate);
    if (!isNaN(timestamp)) {
      const date = moment(timestamp);
      const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
      const timeAgo = date.fromNow();
      return (
        <div>
          <p className="fw-bold">{formattedDate} <br /><small className="fw-normal">({timeAgo})</small></p>
        </div>
      );
    }
    // if (inputDate) {
    //   const dateObject = new Date(Number(inputDate));
    //   const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    //   const months = [
    //     "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //   ];

    //   const formattedDate = `${weekdays[dateObject.getUTCDay()]}, ${dateObject.getUTCDate()} ${months[dateObject.getUTCMonth()]} ${dateObject.getUTCFullYear()} ${dateObject.getUTCHours()}:${(dateObject.getUTCMinutes() < 10 ? '0' : '') + dateObject.getUTCMinutes()}:${(dateObject.getUTCSeconds() < 10 ? '0' : '') + dateObject.getUTCSeconds()} +0000`;

    //   return formattedDate;
    // } else {
    //   return null; // or handle the case when data is not available
    // }
  };

  const columns = [
    // { field: 'index', label: 'ID', width: 100, align: 'left' },
    { field: 'name', label: 'SITE NAME', width: 240, align: 'left' },
    { field: 'url', label: 'SITE URL', width: 240, align: 'left' },
    { field: 'created_at', label: 'ONBOARD DATE', width: 270, renderCell: formatDateCell, align: 'left' },
    { field: 'actions', label: 'Actions', width: 240, renderCell: renderActionsCell, align: 'left' },
  ];


  // const columns: Column111[] = [
  //   // { field: 'index', headerName: 'ID', width: 250,renderCell:renderRowId },
  //   {
  //     field: 'name',
  //     headerName: 'SITE NAME',
  //     width: 300,
  //     editable: false,
  //   },
  //   {
  //     field: 'url',
  //     headerName: 'SITE URL',
  //     width: 300,
  //     editable: false,
  //   },
  //   {
  //     field: 'created_at',
  //     headerName: 'ONBOARD DATE',
  //     width: 300,
  //     editable: false,
  //   //   valueGetter: (params) => {
  //   //     // Extract the date from the status field and format it as needed
  //   //     const timestamp = params.row.created_at.$date.$numberLong;
  //   // return timestampToDate(timestamp);
  //   //   }
  //   },
  //   {
  //     field: 'actions',
  //     headerName: 'ACTIONS',
  //     width: 150,
  //     editable: false,
  //     renderCell: renderActionsCell
  //   },
  // ];


  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [userDeleteId, setUserDeleteId] = useState<String>();

  const [userLoading, setUserLoading] = useState<Boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const auth = React.useRef(false);
  const token = localStorage.getItem('token') || '';
  const [modalData, setModalData] = useState();
  const [openDeleteModal, setDeleteModal] = useState<Boolean>(false);
  const [userAnswer, setUserAnswer] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0); // Current page index
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalRecoard, setTotalRecoard] = useState(0);
  const [sortby, setSortBy] = useState(null);
  const [sort, setSort] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userDeleteApi = `${baseUrl}/sites/delete/`;
  const getApiData = `${baseUrl}/sites/get?status=1`




  useEffect(() => {
    if (userData) {
      const indexedUserData = userData?.map((item, index) => ({ ...item, index: index + 1 }));
      setOriginalData(indexedUserData);
      setFilteredData(indexedUserData)
    }
  }, [userData])


  const getAllData = () => {
    setUserLoading(true);
    const apiEndpoint = getApiData;
    const authtoken = token;
    let options: FetchOptions = {};
    if (page !== undefined) options.page = page + 1;
    if (rowsPerPage !== undefined) options.perpage = rowsPerPage;
    if (sort !== null) options.sort = sort;
    if (sortby !== null) options.sortBy = sortby;
    if (searchTerm != null) options.searchTerm = searchTerm;
    return axios.get(apiEndpoint, {
      headers: {
        accept: '*/*',
        'Auth-Token': authtoken,
      },
      params: options
    })
      .then((response) => {
        setUserData(response.data.data.data);
        setTotalRecoard(response.data.data.total)
        setIsAuthenticated(false);
        setUserLoading(false);
      }).catch((error) => {
        setIsAuthenticated(true);
        auth.current = true;
        setUserLoading(false);
        if (error.response && error.response.status === 401) {
          DashboardAction.checklogoutService()
        }
      })

  };


  useEffect(() => {
    if (initialLoad) {
      getAllData();
      setInitialLoad(false);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (!initialLoad) {
      getAllData();
    }
  }, [page, rowsPerPage, sort, sortby, searchTerm])


  useEffect(() => {
    if (userAnswer) {
      notify("Sites Deleted Successfully", "success");
      if (!initialLoad) {
        getAllData();
        setUserAnswer(false);
      }
    }
  }, [userAnswer]);


  // useEffect(() => {
  //   if(!initialLoad){
  //   getAllData();
  //   }
  // }, [userAnswer]);

  useEffect(() => {
    if (isAuthenticated) {
      setIsAuth(true)
    }
  }, [isAuthenticated]);

  const createData = async () => {
    await getAllData();
    if (auth.current === true) {
      setIsAuth(true);
    } else {
      // navigate(`/management/createSite`);
      navigate(`/site-management/createSite`);
    }
  };


  // const handleOpenModal = (item) =>{
  //   console.log("handleOpenModal is",item)
  //   setModalData(item)
  //   setModalOpen(true)
  // }

 



  // React.useEffect(() => {
  //   if (search === "") {
  //     setFilteredData(originalData);
  //   } else {
  //     const newFilteredData = originalData.filter((item) =>
  //       item.name.toLowerCase().includes(search.toLowerCase())
  //     );
  //     setFilteredData(newFilteredData);
  //   }
  // }, [search]);

  // const chnagePage = (page: string) => {
  //   console.log("Page is", page);
  //   const actualPage = page.replace(
  //     `https://api.admin.websiteranking.companydemo.in/api/users?page=`,
  //     ""
  //   );
  //   const parsedValue = parseInt(actualPage);
  //   setCurrPage(parsedValue);
  // };

  const handleSort = (sortby, sort) => {
    setSortBy(sortby);
    setSort(sort);
  };

  const handleSearch = (search) => {
    setSearchTerm(search);
    setPage(0);
  };


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    const data = parseInt(newRowsPerPage, 10);
    setRowsPerPage(data);
    setPage(0);
  }

  const notify = (message, type) => {
    toast[type](message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const slicedData = filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      {/* <Authenticate_Dialog
        title="You are not authenticated. Please login again"
        open={isAuth}
        setOpen={setIsAuth}
      /> */}
      <CommonUserModal open={modalOpen} setOpen={setModalOpen} data={modalData} />
      <Helmet>
        <title>{user.name ? user.name : ""} </title>
      </Helmet>
      <div id="content-page" className="content-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                <div role="presentation" style={{ marginTop: '20px' }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      sx={{ display: 'flex', alignItems: 'center' }}
                      color="inherit"
                      href="/"
                    >
                      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      Sites
                    </Link>
                    <Typography
                      sx={{ display: 'flex', alignItems: 'center' }}
                      color="text.primary"
                    >
                      <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                      Sites Onboarded
                    </Typography>
                  </Breadcrumbs>
                </div>
              </section>
              {/* <PageTitleWrapper>
                <PageHeader title="Sites Onboarded" />
              </PageTitleWrapper> */}

              <div className="iq-card position-relative rounded overflow-hidden">

                {/* <div style={{ display: "flex", justifyContent: "end", width: "95%" }}>
                  <Button sx={{ margin: 1 }} variant="contained" onClick={createData}>
                    Add site
                  </Button>
                </div> */}
                <div className="iq-card-header d-flex justify-content-between">
                  <div className="iq-header-title">
                    <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Sites</h4>
                  </div>
                  <div className="d-flex gap-4">
                    <div
                      className="align-items-center custom-toolbar-button d-flex gap-3 justify-content-end"

                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          marginRight: "16px",
                          fontWeight: 600,
                          margin: "auto",
                        }}
                      >
                        Sites Onboarded: {totalRecoard}
                      </Typography>
                      <Button variant="contained" style={{ backgroundColor: "#282621" }} className="rounded" onClick={createData}>
                        Onboard New Site
                      </Button>
                    </div>
                    <div
                      className="input-group w-auto"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <div className="form-outline" data-mdb-input-init>
                        <input
                          type="text"
                          id="search"
                          name="search"
                          className="form-control"
                          value={search}
                          placeholder="Search site by name"
                          // onFocus={() => setSearch("")}
                          // onBlur={() => setSearch("")}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn" style={{ backgroundColor: "#282621" }}
                        data-mdb-ripple-init
                        onClick={() => handleSearch(search)}
                      >
                        <i className="fas fa-search text-light"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                  <CommonTable
                    data={filteredData}
                    columns={columns}
                    enablePagination
                    type={"site"}
                    loading={userLoading}
                    page={page}
                    count={totalRecoard}
                    rowsPerPage={rowsPerPage}
                    handleSort={handleSort}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>

                {/* <DataGrid
                  slots={{
                    toolbar: () => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "10px",
                        }}
                      >
                        {/* <CustomToolbar /> *}

                        <div
                          className="custom-toolbar-button"
                          style={{ display: "inline-flex", gap: "10px" }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              marginRight: "16px",
                              fontWeight: 600,
                              margin: "auto",
                            }}
                          >
                            Sites Onboarded: {originalData.length}
                          </Typography>
                          <Button variant="contained" onClick={createData}>
                            Onboard New Site
                          </Button>
                        </div>
                      </div>
                    ),
                    noRowsOverlay: CustomNoRowsOverlay,
                    footer: () => (
                      <>
                        <div style={{ display: 'flex', justifyContent: 'end', width: '95%', margin: 'auto', padding: '10px', borderTop: '1px solid #e0e0e0', alignItems: 'center' }}>
                          <GridPagination />
                          <div style={{ display: 'flex' }}>
                            {prevPage !== null &&
                              <ArrowBackIosIcon onClick={() => chnagePage(prevPage)} style={{ width: '1rem', color: 'black', cursor: "pointer" }} />}
                            {nextPage !== null && <ArrowForwardIosIcon onClick={() => chnagePage(nextPage)} style={{ width: '1rem', color: 'black', cursor: "pointer" }} />}
                          </div>
                        </div>

                        {/* <div>
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    Sahil
                  </Typography>
                </div> *}
                      </>
                    ),
                  }}
                  rows={filteredData && filteredData}
                  getRowId={(row) => row.index}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  disableDensitySelector
                  pageSizeOptions={[25, 30, 50]}
                  disableRowSelectionOnClick
                /> */}
                {/* </Box> */}
              </div >
            </div >
          </div >
        </div >
      </div >
      <ResponsiveDialog
        openDialogue={openDeleteModal}
        setopenDialogue={setDeleteModal}
        title="site"
        setAnswer={setUserAnswer}
        id={userDeleteId}
        apiUrl={userDeleteApi}
      />
      <Footer />
      <ToastContainer />
    </>
  );
};
export default GetAll_Sites;
