import * as React from 'react';
import Box from '@mui/material/Box';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/content/dashboards/Crypto/PageHeader';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import axios from 'axios';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColDef,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import DashboardAction from 'src/action/DashboardAction';
// import Typography from '@mui/material/Typography';


export default function Sites_Requesting() {
  const [open, setOpen] = React.useState(false);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalItem,setMdalItem] = React.useState('');
  const [data,setData] = React.useState([]);
  const [updatedData,setUpdatedData] = React.useState([]);
  const [siteId,setSiteId] = React.useState('');
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const requestedSiteApi = `${baseUrl}/sites/get?status=0`;
  const token = localStorage.getItem('token') || '';

  const handleModal = (item,id) =>{
    setSiteId(id)
    setMdalItem(item)
    setOpen(true);
  }
  const getData=async()=>{
    await axios.get(`${requestedSiteApi}`,{
      headers: {
        accept: '*/*',
        'Auth-Token': `${token}`,
      },}).then((res)=>{
        setData(res.data?.data)
      }).catch((error)=>{
        if (error.response && error.response.status === 401) {
          DashboardAction.checklogoutService()
        }
      })
  }

  React.useEffect(()=>{
    getData();
  },[])

  const columns = [
    { field: 'id', headerName: 'ID', width: 120 },
    {
      field: 'date',
      headerName: 'DATE',
      headerClassName: 'strong-header', // Add this line
      align: 'left',
      headerAlign: 'left',
      width: 270,
    },
    {
      field: 'name',
      headerName: 'SITE NAME',
      headerClassName: 'strong-header', // Add this line
      align: 'left',
      headerAlign: 'left',
      width: 270,
    },
    {
      field: 'url',
      headerName: 'SITE URL',
      headerClassName: 'strong-header', // Add this line
      align: 'left',
      headerAlign: 'left',
      width: 270,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      headerClassName: 'strong-header', // Add this line
      align: 'left',
      headerAlign: 'left',
      width: 270,
      renderCell: (params) => (
          <>
            <button className='button-site-requested' onClick={()=>handleModal('onboard',params.row._id)} style={{border: 0,
      outline: 0,
      background: "red",
      padding: "10px",
      borderRadius: "10px",
      cursor:'pointer',
      color: "#fff",
      fontWeight: 500}}  onMouseOver={(e) => { e.currentTarget.style.background = "transparent"; e.currentTarget.style.color = "black"; e.currentTarget.style.border = "1px solid red"; }}
      onMouseOut={(e) => { e.currentTarget.style.background = "red"; e.currentTarget.style.border = "0"; e.currentTarget.style.color = "white"; }}>Onboard</button>
            <button style={{marginLeft:'10px',border: 0,
      outline: 0,
      background: "green",
      cursor:'pointer',
      padding: "10px",
      borderRadius: "10px",
      color: "#fff",
      fontWeight: 500}} onClick={()=>handleModal('request',params.row._id)}
      onMouseOver={(e) => { e.currentTarget.style.background = "transparent"; e.currentTarget.style.color = "black"; e.currentTarget.style.border = "1px solid green"; }}
      onMouseOut={(e) => { e.currentTarget.style.background = "green"; e.currentTarget.style.border = "0"; e.currentTarget.style.color = "white"; }}>Request</button>
          </>
        ),
    },
  ];
  
  const rows = [
    { id: 1, siteName: 'Snow', siteName: 'Jon', siteUrl:"lalitkala.gov.in", userName:"Sahil", userEmail:'sahil.bhutani@adgonline.in', age: 35 },
    { id: 2, siteName: 'Lannister', siteName: 'Cersei', siteUrl:"lalitkala.gov.in", userName:"Sahil", userEmail:'sahil.bhutani@adgonline.in', age: 42 },
    { id: 3, siteName: 'Lannister', siteName: 'Jaime',siteUrl:"lalitkala.gov.in", userName:"Sahil", userEmail:'sahil.bhutani@adgonline.in', age: 45 },
    { id: 4, siteName: 'Stark', siteName: 'Arya',siteUrl:"lalitkala.gov.in", userName:"Sahil", userEmail:'sahil.bhutani@adgonline.in', age: 16 },
    { id: 5, siteName: 'Targaryen', siteName: 'Daenerys',siteUrl:"lalitkala.gov.in", userName:"Sahil", userEmail:'sahil.bhutani@adgonline.in', age: null },
    { id: 6, siteName: 'Melisandre', siteName: "Ferrara", siteUrl:"lalitkala.gov.in", userName:"Sahil", userEmail:'sahil.bhutani@adgonline.in', age: 150 },
    { id: 7, siteName: 'Clifford', siteName: 'Ferrara', siteUrl:"lalitkala.gov.in", userName:"Sahil", userEmail:'sahil.bhutani@adgonline.in', age: 44 },
    { id: 8, siteName: 'Frances', siteName: 'Rossini', siteUrl:"lalitkala.gov.in", userName:"Sahil", userEmail:'sahil.bhutani@adgonline.in', age: 36 },
    { id: 9, siteName: 'Roxie', siteName: 'Harvey', siteUrl:"lalitkala.gov.in", userName:"Sahil", userEmail:'sahil.bhutani@adgonline.in', age: 65 },
  ];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleOnboarding = (id) => {
    // Handle Onboarding button click
    console.log(`Onboarding clicked for ID ${id}`);
  };

  const handleRequested = (id) => {
    // Handle Requested button click
    console.log(`Requested clicked for ID ${id}`);
  };

  React.useEffect(()=>{
    if(data){
      const newData = data.map((item,index) => ({
        id:index + 1,
        date: formattedDate(item?.created_at?.$date?.$numberLong),
        name: item.name,
        url: item.url,
        _id: item._id?.$oid
        // userName:"Sahil",
        // userEmail:"sahil.bhutani@adgonline.in"
      }));
  
      setUpdatedData(newData);
    }
  },[data])

  const [message,setMessage] = React.useState('')

  const onBoardModalData = async () => {
  
    try {
      const res = await axios.put(
        `${baseUrl}/sites/stchange/${siteId}`,
        {
          status: modalItem == "onboard" ? 1 : 2,
          message: message,
        },
        {
          headers: {
            accept: '*/*',
            'auth-Token': `${token}`,
          },
        }
      );
  
      getData();
    } catch (error) {
      console.log("Error occurred in onboarding/rejecting", error);
    }
  
    setOpen(false);
    setMessage('');
  };
  

  const requestModalData = () =>{
    setSiteId("");
    setOpen(false);
  }

  
  React.useEffect(()=>{
    if(open == false){
      setSiteId('')
    }
  },[open])

  const formattedDate = (timestamp)=>{
    
    const date = new Date(+timestamp);

const options = { day: 'numeric', month: 'long', year: 'numeric' };
const formattedDate = date.toLocaleDateString('en-US', options);
return formattedDate

  }

  const loggedin_Name=localStorage.getItem('login_data');
  const parsedData=JSON.parse(loggedin_Name)
  const user = {
    name: parsedData?.data?.name,
    avatar: '/static/images/avatars/1.jpg'
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {/* <Typography variant="subtitle1" sx={{ marginLeft: '16px' }}>
        Total Sites: {siteList.length}
      </Typography> */}
      </GridToolbarContainer>
    );
  }

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Requested Site Found</Box>
      </StyledGridOverlay>
    );
  }
  return <>
    <Helmet>
        <title>{user.name ? user.name : ""} </title>
    </Helmet>
    <section className="breadcrum-section" style={{marginTop:'20px',marginLeft:'20px'}}>
      <div role="presentation" style={{marginTop:'20px'}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/site-management/getAllOnboardedSite"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Sites
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Sites Requested 
        </Typography>
      </Breadcrumbs>
    </div>
        </section>
    <PageTitleWrapper>
        <PageHeader title="Sites Requested" />
    </PageTitleWrapper>
    <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          {modalItem == 'onboard' ?  "Welcoming You to Onboard Site" :  "Reason for Rejecting Site"}
    </Typography>
    <TextField sx={{width:'100%',marginTop:'10px'}}
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={4}
          value={message}
          required
          onChange={(e)=>setMessage(e.target.value)}
          
        />
        <div style={{display:'flex',justifyContent:'start',gap:'10px',marginTop:'10px'}}>
        <Button variant="contained" disabled={message.length>0 ? false : true} color="success"  onClick={onBoardModalData}>{modalItem == 'onboard' ?  "Onboard" :  "Reject"}</Button>
        <Button variant="contained" color="error"  onClick={()=>setOpen(false)}>Cancel</Button>
        </div>
        </Box>
      </Modal>
    <Box sx={{ height: updatedData?.length == 0 ? 400 : "", width: '95%',backgroundColor:'white' ,margin:'auto'}}>
      <DataGrid slots={{
         toolbar: () => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <CustomToolbar />
          </div>
        ),
        noRowsOverlay: CustomNoRowsOverlay,
      }}
        rows={updatedData}
        columns={columns}
        getRowId={(row) => row.name}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        disableDensitySelector
        pageSizeOptions={[25, 30, 50]}
        disableRowSelectionOnClick
      />
    </Box>
  </>
}