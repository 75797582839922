import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import { callApi } from "src/action/RestClient";
import { useLocation, useNavigate } from "react-router";
import CommonTable from "src/common/table/table";
import moment from "moment";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, Button, FormControl,IconButton, Select, MenuItem, Tooltip } from "@mui/material";
import { ToastContainer } from "react-toastify";
import ResponsiveDialog from "src/common/DeleteDialogue";
import CreateManagePackages from "./createManagePackage";
import { notify } from "src/common/toastMesaage";


const ManagePackages = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedType = location?.state 
    const [userData, setUserData] = useState([]);
    const [customUserData, setCustomUserData] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [userAnswer, setUserAnswer] = useState(false);
    const [userDeleteId, setUserDeleteId] = useState();
    const [userLoading, setUserLoading] = useState();
    const [page, setPage] = useState(0); // Current page index
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRecoard, setTotalRecoard] = useState(0);
    const [sortby, setSortBy] = useState(null);
    const [sort, setSort] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userDeleteApi = `${baseUrl}/package/delete/`
    const [changePackage, setChangePackage] = useState(selectedType?.changePackage ? selectedType?.changePackage : "regular");


    const handleChange = (event) => {
        setChangePackage(event.target.value);
        if(event.target.value === "custom"){
            getAllCustomData()
        }
        if(event.target.value === "regular"){
            getAllData();
        }
        setChangePackage(event.target.value);
        
      };


    const getAllData = async () => {
        setUserLoading(true)
        try {
            let options = {};
            if (page !== undefined) options.page = page + 1;
            if (rowsPerPage !== undefined) options.perpage = rowsPerPage;
            if (sort !== null) options.sort = sort;
            if (sortby !== null) options.sortBy = sortby;
            if (searchTerm != null) options.searchTerm = searchTerm;
            const response = await callApi('GET', "/get/packages/regular", null, options);
            const data = response?.data?.data;
            setUserData(data);
            setTotalRecoard(data?.length);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };

    const getAllCustomData = async () => {
        setUserLoading(true)
        try {
            let options = {};
            if (page !== undefined) options.page = page + 1;
            if (rowsPerPage !== undefined) options.perpage = rowsPerPage;
            if (sort !== null) options.sort = sort;
            if (sortby !== null) options.sortBy = sortby;
            if (searchTerm != null) options.searchTerm = searchTerm;
            const response = await callApi('GET', '/get/packages/custom', null, options);
            const data = response?.data?.data;
            setCustomUserData(data);
            setTotalRecoard(data?.length);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };


    useEffect(() => {
        if (initialLoad ) {
            getAllData();
            setInitialLoad(false);
        }
    }, [initialLoad]);

    useEffect(()=>{
        if(selectedType?.changePackage){
            getAllCustomData();
        }
    },[selectedType?.changePackage])


    useEffect(() => {
        if (!initialLoad) {
            getAllData();
        }
    }, [page, rowsPerPage, sort, sortby, searchTerm])


    useEffect(() => {

        if (userAnswer) {
            notify("Packages Deleted Successfully", "success");
            if (!initialLoad) {
                if(changePackage === "custom"){
                    getAllCustomData();
                }else{
                    getAllData();
                }
                setUserAnswer(false);
            }
        }
    }, [userAnswer]);


    const handleSort = (sortby, sort) => {
        setSortBy(sortby);
        setSort(sort);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPage(data);
        setPage(0);
    }


    const formatDateCell = (params) => {
        const dateString = params?.value;
        const date = moment(dateString);
        const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
        const timeAgo = date.fromNow();
        return (
            <div>
                <p className="fw-bold">{formattedDate} <br></br><small className="fw-normal">({timeAgo})</small></p>
            </div>
        );
    };

    const renderActionsCell = (params) => {
        const user = params.row;
        return (
            <div>
                <Tooltip title="Edit Packages" arrow
                    onClick={() => updatePackage(user)}
                >
                    <IconButton color="inherit" size="small">
                        <EditTwoToneIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Packages" arrow
                    onClick={() => deletePackage(user._id)}
                >
                    <IconButton color="inherit" size="small">
                        <DeleteTwoToneIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={`View Packages`}
                    arrow
                    onClick={() => handleViewPage(user)}
                >
                    <IconButton color="inherit" size="small">
                        <RemoveRedEyeOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const renderCustomActionsCell =(params) =>{
        const user = params.row;
        return (
            <div>
                <Tooltip title="Edit Packages" arrow
                    onClick={() => updateCustomPackage(user)}
                >
                    <IconButton color="inherit" size="small">
                        <EditTwoToneIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Packages" arrow
                    onClick={() => deletePackage(user._id)}
                >
                    <IconButton color="inherit" size="small">
                        <DeleteTwoToneIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={`View Packages`}
                    arrow
                    onClick={() => handleCustomViewPage(user)}
                >
                    <IconButton color="inherit" size="small">
                        <RemoveRedEyeOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }
    const deletePackage = async (id) => {
        setUserDeleteId(id);
        setDeleteModal(true);
    };

    const updatePackage = async (data) => {
        navigate(`/billingAndSubscriptions/managePackage`, { state: { data, type: "update" } });
    };
    const updateCustomPackage = async (data) => {
        navigate(`/billingAndSubscriptions/customPackage`, { state: { data, type: "update" } });
    };


    const handleViewPage = async (data) => {
        navigate(`/billingAndSubscriptions/managePackage`, { state: { data, type: "view" } });
    };

    const handleCustomViewPage = async (data) => {
        navigate(`/billingAndSubscriptions/customPackage`, { state: { data, type: "view" } });
    };



    const columns = [
        { field: 'title', label: 'PACKAGES NAME', width: 240, align: 'left' },
        { field: 'description', label: 'DESCRIPTION', width: 240, align: 'left' },
        { field: 'allowWebsites', label: 'ALLOW WEBSITE', width: 240, align: 'left' },
        { field: 'created_at', label: 'PACKAGES CREATED DATE', width: 240, renderCell: formatDateCell, align: 'left' },
        { field: 'actions', label: 'Actions', width: 240, renderCell: renderActionsCell, align: 'left' },
    ];

    const columnsCustom = [
        { field: 'title', label: 'PACKAGES NAME', width: 240, align: 'left' },
        { field: 'created_at', label: 'PACKAGES CREATED DATE', width: 240, renderCell: formatDateCell, align: 'left' },
        { field: 'actions', label: 'Actions', width: 240, renderCell: renderCustomActionsCell, align: 'left' },
    ];



    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Billing And Subscriptions
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Manage Packages
                                        </Typography>
                                    </Breadcrumbs>
                                </div>
                            </section>
                            <div className="iq-card position-relative rounded overflow-hidden">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Manage Packages</h4>
                                    </div>
                                    <div style={{ display: "flex", alignItems:"center" }}>
                                    <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Select Packages Type:</h4>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl
                                                sx={{ m: 1, minWidth: 120 }}
                                                className="recurring-select-filter"
                                            >
                                               
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                     value={changePackage}
                                                    // label="recurring"
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                     onChange={handleChange}
                                                    className="py-0 outline-none rounded"
                                                >
                                                    {/* <MenuItem value="Action">Action</MenuItem> */}
                                                    <MenuItem value="custom">Custom package</MenuItem>
                                                    <MenuItem value="regular">Regular Package</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="align-items-center custom-toolbar-button d-flex gap-3 justify-content-end"  >
                                        <Button className="common-black-btn"
                                            onClick={() => navigate("/billingAndSubscriptions/managePackage")}
                                        >
                                            Create Regular Packages
                                        </Button>
                                        <Button className="common-black-btn"
                                            onClick={() => navigate("/billingAndSubscriptions/customPackage")}
                                        >
                                            Create Custom Packages
                                        </Button>
                                    </div>

                                </div>
                                {changePackage === "regular" ? 
                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={userData}
                                        columns={columns}
                                        enablePagination
                                        type={"managePackages"}
                                        loading={userLoading}
                                        page={page}
                                        count={totalRecoard}
                                        rowsPerPage={rowsPerPage}
                                        handleSort={handleSort}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                                :
                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={customUserData}
                                        columns={columnsCustom}
                                        enablePagination
                                        type={"managePackages"}
                                        loading={userLoading}
                                        page={page}
                                        count={totalRecoard}
                                        rowsPerPage={rowsPerPage}
                                        handleSort={handleSort}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                                }
                            </div >
                        </div >
                    </div >
                </div >
            </div >
            <ResponsiveDialog
                openDialogue={openDeleteModal}
                setopenDialogue={setDeleteModal}
                title="site"
                setAnswer={setUserAnswer}
                id={userDeleteId}
                apiUrl={userDeleteApi}
            />
            <ToastContainer />
        </>
    );
};
export default ManagePackages;
