import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import { callApi, postApi } from "src/action/RestClient";
import { useLocation, useNavigate } from "react-router";
import CommonTable from "src/common/table/table";
import moment from "moment";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, Button, FormControl, IconButton, Select, MenuItem, Tooltip, CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";
import ResponsiveDialog from "src/common/DeleteDialogue";
import { notify } from "src/common/toastMesaage";
import FormControlLabel from '@mui/material/FormControlLabel';
import { IOSSwitch } from "../BillingAndSubscriptions/manageTax/switchComonent";
import UsersSites from "src/sites/Users_Sites";

const ManageStates = () => {
    const navigate = useNavigate();
    const [initialLoad, setInitialLoad] = useState(true);
    const [countryData, setCountryData] = useState([]);
    const [userLoading, setUserLoading] = useState(false);
    const [loadingStates, setLoadingStates] = useState({});
    const [userDeleteId, setUserDeleteId] = useState();
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [userAnswer, setUserAnswer] = useState(false);
    const [page, setPage] = useState(0); // Current page index
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRecoard, setTotalRecoard] = useState(0);
    const [sortby, setSortBy] = useState(null);
    const [sort, setSort] = useState(null);
    const [search, setSearch] = useState('');
    const [stateData, setStateData] = useState([]);
    const [filterStateData, setFilterStateData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [countryName, setCountryName] = useState("Canada");
    const [countryId, setCountryId] = useState("");
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userDeleteApi = `${baseUrl}/delete-states/`

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "search") {
            setSearch(value);
            setFilterStateData(searchCountries(stateData, search));
        }
        if (name === "country_Name") {
            filteredCountrie(value);
        }
    };


    const getAllCountry = async () => {
        setUserLoading(true)
        try {
            const response = await callApi('GET', "/get-all-countries", null, null);
            const data = response?.data?.data;
            const selectCountrydata = data.find(item => item?.country_name.toLowerCase() === "canada");
            const countryId = selectCountrydata?._id;
            setCountryId(countryId);
            getAllState(countryId);
            setCountryData(data);
            setTotalRecoard(data?.length);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };

    const getAllState = async (id) => {
        setUserLoading(true)
        try {
            const response = await callApi('GET', `/get-all-states-by-country/${id}`, null, null);
            const data = response?.data?.data;
            setStateData(data);
            setFilterStateData(data);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };
    useEffect(() => {
        setFilterStateData(searchCountries(stateData, search));
    }, [search]);

    useEffect(() => {
        if (initialLoad) {
            getAllCountry();
            setInitialLoad(false);
        }
    }, [initialLoad]);

    useEffect(() => {
        if (userAnswer) {
            notify("State Deleted Successfully", "success");
            if (!initialLoad) {
                getAllState(countryId);
                setUserAnswer(false);
            }
        }
    }, [userAnswer]);


    const handleSort = (sortby, sort) => {
        setSortBy(sortby);
        setSort(sort);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPage(data);
        setPage(0);
    }

    const handleSearch = (arr, search) => {
        const filteredData = searchCountries(arr, search);
    };

    function searchCountries(data, searchTerm) {
        const lowerCaseSearchTerm = searchTerm?.toLowerCase();
        return data?.filter(country => country?.name?.toLowerCase().includes(lowerCaseSearchTerm));
    }

    const handleSelect = (country) => {
        setCountryName(country?.country_name);
        setCountryId(country?._id)
        getAllState(country?._id);
        setSuggestions([]);
    };


    const filteredCountrie = (value) => {
        // setInputValue(value);
        setCountryName(value);
        const countryName = countryData?.filter((country) =>
            country?.country_name?.toLowerCase().includes(value.toLowerCase())
        ).slice(0, 5);
        setSuggestions(countryName)
    }


    const formatStateStatusCell = (params) => {
        const user = params.row;
        return (
            <div>
                {loadingStates[user._id] ? <Box gap-3 sx={{ width: "100px" }}>
                    <CircularProgress color="inherit" size={18} />
                </Box> :
                    <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }}
                            checked={user?.status === "active" ? true : false}
                            onChange={() => toggleStateButton(user?.status === "active" ? "active" : "inactive", user)}
                        />}
                    />
                }
            </div>

        );
    }

    const toggleStateButton = async (status, user) => {
        const data = {
            status: status === "active" ? "inactive" : "active",
            code: user?.code,
            name: user?.name,
            countryId: user?.countryId,
        }
        setLoadingStates(prev => ({ ...prev, [user._id]: true }));
        try {
            const response = await postApi(`/update-states/${user?._id}`, data);
            if (response?.status) {
                notify("State status update successfully", "success");
                getAllState(user?.countryId);
            } else {
                notify(response?.error, "error");
            }
        } catch (error) {
            notify(error.message, "error");
        } finally {
            setLoadingStates(prev => ({ ...prev, [user._id]: false }));
        }
    };

    const renderStateActionsCell =(params) =>{
        const user = params.row;
        return (
            <div>
                <Tooltip title="Edit States" arrow
                    onClick={() => updateStates(user)}
                >
                    <IconButton color="inherit" size="small">
                        <EditTwoToneIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete State" arrow
                    onClick={() => deleteState(user._id)}
                >
                    <IconButton color="inherit" size="small">
                        <DeleteTwoToneIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

    const updateStates = async (data) => {
        navigate(`/master/createCountyState`, { state: { data, type: "update", countryName: countryName} });
    };

    const deleteState = async (id) => {
        setUserDeleteId(id);
        setDeleteModal(true);
    };




    const columnsState = [
        { field: 'name', label: 'State NAME', width: 240, align: 'left' },
        { field: 'code', label: ' Code', width: 240, align: 'left' },
        { field: 'status', label: 'Status', width: 240, renderCell: formatStateStatusCell, align: 'left' },
        { field: 'actions', label: 'Actions', width: 240, renderCell: renderStateActionsCell, align: 'left' },
    ];


    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            MASTERS MANAGEMENT
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Manage States
                                        </Typography>
                                    </Breadcrumbs>
                                </div>
                            </section>
                            <div className="iq-card position-relative rounded overflow-hidden">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Manage States</h4>
                                    </div>
                                    <div className="form-group col-lg-5 d-flex align-items-center">
                                        <h4 className="card-title mb-0 fw-bold text-nowrap me-2" style={{ fontSize: "1.1rem" }}> Select County Name :</h4>
                                        {/* <label htmlFor="country_Name" className="fxt-label text-nowrap me-2">
                                            Select County Name :
                                        </label> */}
                                        <div className="form-group col-lg-7">
                                            <input
                                                id="country_Name"
                                                type="text"
                                                className="form-control"
                                                name="country_Name"
                                                placeholder="Enter Country Name"
                                                required="required"
                                                value={countryName}
                                                onChange={handleChange}
                                                autoComplete="off"
                                            />
                                            {countryName && suggestions.length > 0 && (
                                                <div className="suggestion-box">
                                                    {/* Render suggestions here */}
                                                    <ul>
                                                        {suggestions.map((country, index) => (
                                                            <li key={index} onClick={() => handleSelect(country)}>
                                                                {country.country_name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'end',
                                        }}
                                    >
                                        <div className="form-outline" data-mdb-input-init>
                                            <input
                                                type="text"
                                                id="search"
                                                name="search"
                                                className="form-control"
                                                value={search}
                                                placeholder="Search State by name"
                                                onChange={(e) => { handleChange(e) }}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn"
                                            style={{ backgroundColor: '#282621' }}
                                            data-mdb-ripple-init
                                            onClick={() => handleSearch(stateData, search)}
                                        >
                                            <i className="fas fa-search text-light"></i>
                                        </button>
                                    </div>
                                    <div className="align-items-center custom-toolbar-button d-flex gap-3 justify-content-end"  >
                                        <Button className="common-black-btn"
                                            onClick={() => navigate("/master/createCountyState")}
                                        >
                                            Create New State
                                        </Button>

                                    </div>

                                </div>
                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={filterStateData}
                                        columns={columnsState}
                                        // enablePagination
                                        type={"managePackages"}
                                        loading={userLoading}
                                    // page={page}
                                    // count={totalRecoard}
                                    // rowsPerPage={rowsPerPage}
                                    // handleSort={handleSort}
                                    // onPageChange={handlePageChange}
                                    // onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
            <ResponsiveDialog
                openDialogue={openDeleteModal}
                setopenDialogue={setDeleteModal}
                title="state"
                setAnswer={setUserAnswer}
                id={userDeleteId}
                apiUrl={userDeleteApi}
            />
            <ToastContainer />
        </>
    );
};
export default ManageStates;
