import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { IconButton, Grid, CircularProgress, Paper, Tooltip,} from '@mui/material';
import moment from 'moment';
import { callApi } from 'src/action/RestClient';
import { useLocation, useNavigate } from 'react-router';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Typography from '@mui/material/Typography';

const UnCompletedPurchasesDetails = ({ isOpen, onClose, selectedId }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedSubId, setSelectedSubId] = useState("");
    const [subscription, setSubscription] = useState({});
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        onClose();
    }

    const getUnSubscriptioDetails = async (id) => {
        setLoading(true);
        try {
            const response = await callApi('GET', `/get/subscription-un-completed-data/${id}`, null, null);
            const data = response?.data?.data
            if (response.status) {
                setSubscription(data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            throw error;
        }
    };
    useEffect(() => {
        setSelectedSubId(selectedId);
        if (location?.state?.userId) {
            getUnSubscriptioDetails(location?.state?.userId);
        }
    }, [location?.state?.userId]);

    const loader = () => {
        return (
            <div className='w-100'>
                <Box className="align-items-center justify-content-center py-5 d-flex table-loader" style={{ minHeight: "auto" }}>
                    <CircularProgress />
                </Box>
            </div>
        );
    };


    return (
        <>
            <div
                className="content-page"
                style={{ fontFamily: 'sans-serif', fontSize: '15px' }}
            >
                <Paper>
                    <div className="d-flex">
                        <div className="col p-2">
                            <Tooltip
                                className="me-2"
                                title={`Back`}
                                arrow
                                onClick={() => navigate("/users/uncompletedPurchases")}
                            >
                                <IconButton color="inherit" size="small">
                                    <ArrowBackOutlinedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="col text-center p-2">
                            <Typography variant="h5" gutterBottom fontSize="medium">
                                UnCompleted Purchase Details
                            </Typography>
                        </div>
                        <div className="col text-end p-2">
                            {/* <ButtonGroup size="small" aria-label="Small button group">
                {buttons}
              </ButtonGroup> */}
                        </div>
                    </div>

                    <Grid container spacing={2} style={{ padding: '35px' }}>
                        {loading ? (
                            loader()
                        ) : Object.keys(subscription).length <= 0 ? (
                            <div>
                                <div colSpan={10}>
                                    <h4 className="align-items-center justify-content-center py-5 d-flex ">
                                        {'No Records Found'}
                                    </h4>
                                </div>
                            </div>
                        ) : (
                            <table className="table table-striped">
                                <tbody>
                                    <tr style={{ fontSize: "medium" }}>
                                        <td className="w-25">
                                            {' '}
                                            <strong > Term Period :</strong>{' '}
                                        </td>
                                        <td className="w-75">
                                            {' '}
                                            {subscription?.termPeriod}
                                        </td>
                                    </tr>
                                    <tr style={{ fontSize: "medium" }}>
                                        <td>
                                            {' '}
                                            <strong>Package Title:</strong>{' '}
                                        </td>
                                        <td> {subscription?.packageTitle}</td>
                                    </tr>
                                    <tr style={{ fontSize: "medium" }}>
                                        <td>
                                            {' '}
                                            <strong>Amount:</strong>{' '}
                                        </td>
                                        <td> ${" "}{subscription?.amount} </td>
                                    </tr>
                                    <tr style={{ fontSize: "medium" }}>
                                        <td>
                                            {' '}
                                            <strong>Company Name:</strong>
                                        </td>
                                        <td> {subscription?.company_Name}</td>
                                    </tr>
                                    <tr style={{ fontSize: "medium" }}>
                                        <td>
                                            {' '}
                                            <strong>Country Name:</strong>
                                        </td>
                                        <td> {subscription?.country_Name}</td>
                                    </tr>
                                    <tr style={{ fontSize: "medium" }}>
                                        <td>
                                            {' '}
                                            <strong>Address:</strong>
                                        </td>
                                        <td> {subscription?.address}</td>
                                    </tr>
                                    <tr style={{ fontSize: "medium" }}>
                                        <td>
                                            {' '}
                                            <strong>Created At:</strong>
                                        </td>
                                        <td> {moment(subscription?.created_at).format('ddd, D MMM YYYY HH:mm')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </Grid>
                </Paper>
            </div>
        </>
    );
};

export default UnCompletedPurchasesDetails;