import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "src/content/dashboards/Crypto/PageHeader";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from '@mui/material';
import axios from 'axios';
import { ToastContainer } from "react-toastify";
import { useLocation } from 'react-router';
import { notify } from 'src/common/toastMesaage';
const UpdateAdmin = () => {
    const loggedin_Name = localStorage.getItem("login_data");
    const parsedData = JSON.parse(loggedin_Name);
    const location = useLocation();
    const slug = location.pathname.replace(`/admin-management/updateAdmin/`,"")
    const [data,setData] = useState();
    const [sucessStatus,setSucessStatus] = useState('');
    const [errorStatus,setErrorStatus] = useState(``);
    const user = {
      name: parsedData?.data?.name,
      avatar: "/static/images/avatars/1.jpg",
    };
    const baseUrl = process.env.REACT_APP_BASE_URL
    const [adminField,setAdminField] = useState({
        password:'',
        password_confirmation:"",
        user_id:slug
    })
    const [error, setError] = useState({
        passwordError: false,
        passwordConfirmationError: false,
      });
      const [loading,setLoading] = useState(false);
      const token = localStorage.getItem('token') || '';
    const handleChange = (e) =>{
        const { name, value} = e.target;
        setAdminField({...adminField,[name]: value});
       
          if (name === "password") {
            setError((prevError) => ({
              ...prevError,
              passwordError:
                value.length > 0 && (value.length < 6 || value.length > 20),
            }));
          }
      
          if (name === "password_confirmation") {
            setError((prevError) => ({
              ...prevError,
              passwordConfirmationError: value !== adminField.password,
            }));
          }
    }
    const postAdminData = () =>{
        setLoading(true)
        axios.post(
          `${baseUrl}/resetpass`,
          {
            user_id:slug,
            password:adminField.password,
            password_confirmation:adminField.password_confirmation
          },
          {
            headers: {
              accept: "*/*",
              "Auth-Token": `${token}`,
            },
          }
        ).then((res)=>{
            setData(res?.data?.data)
            setLoading(false)
            if(res?.data?.status == true){
                notify("Password Reset Sucessfully","success");
                setAdminField({
                    password:'',
                    password_confirmation:"",
                })
            }
            else{
                notify('Something went wrong','error');
            }
        }).catch((error)=>{
            console.log("Error occured ",error)
            setLoading(false)
        })
    }
    const handleSubmit = (e) =>{
        e.preventDefault();
        if (
            error.passwordError ||
            error.passwordConfirmationError
          ) {
            console.log("Please fill correct data")
          } else {
            postAdminData();
          }
    }

    React.useEffect(()=>{
        if(slug){
            setAdminField({
                password:'',
                password_confirmation:"",
                user_id:slug
            })
        }
    },[slug])
  return <>
  <Helmet>
        <title>{user.name ? user.name : ""} </title>
      </Helmet>
      <section className="breadcrum-section" style={{marginTop:'20px',marginLeft:'20px'}}>
      <div role="presentation" style={{marginTop:'20px'}}>
      <Breadcrumbs aria-label="breadcrumb">
        {/* <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/management/getAllUsers"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Management
        </Link> */}
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/admin-management/admin"
        >
          <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Admin
        </Link>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Update Admin 
        </Typography>
      </Breadcrumbs>
    </div>
        </section>
      <PageTitleWrapper>
        <PageHeader title="Update Admin" />
      </PageTitleWrapper>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" , width:'95%',margin:'auto' ,marginTop:"20px", backgroundColor:'white',padding:'40px'}}>
       <TextField
        label=" Reset Password"
        name="password"
        value={adminField.password}
        variant="outlined"
        onChange={handleChange}
        error={error.passwordError}
        helperText={
          error.passwordError ? "Password must be 6 characters long" : ""
        }
      />
      <TextField
        label="Password Confirmation"
        name="password_confirmation"
        value={adminField.password_confirmation}
        variant="outlined"
        onChange={handleChange}
        error={error.passwordConfirmationError}
        helperText={error.passwordConfirmationError ? "Password mismatch" : ""}
      />
       <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
       <Button
          onClick={handleSubmit}
          className="common-black-btn"
          // variant={"contained"}
          // sx={{
          //   marginTop: "2rem",
          //   backgroundColor: "#4CAF50", // Add your desired button color
          //   color: "white", // Add your desired text color
          // }}
          disabled={
            !adminField.password ||
            !adminField.password_confirmation ||
            error.passwordError || 
            error.passwordConfirmationError
          }
        >
          {loading ? `Reseting...` : `Reset password`}
        </Button>
        </div>
    </Box>
    
    <ToastContainer />

  </>
}

export default UpdateAdmin
