import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { config } from "src/common/apiCommonData";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "src/content/dashboards/Crypto/PageHeader";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import GrainIcon from "@mui/icons-material/Grain";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet-async";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

interface FormData {
  name: string;
  url: string;
  description: string;
  // image_logo : string,
  // web_logo: string,
  logo_url: string;
  user_Name: string;
  user_Email: string;
}
interface SiteData extends FormData {
  _id: {
    $oid: string;
  };
  user_id: {
    $oid: string;
  };
  ministry: string;
  departments: string
}

interface UserData {
  _id: string;
  name: string;
  email: string;
  is_admin: number;
  status: number;
  updated_at: string;
  created_at: string;

}

interface DepartmentData {
  name: string,
  _id: {
    $oid: string
  }
}

const Create_Site: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const token = localStorage.getItem("token");
  const data = location.state as SiteData;
  
  const baseUrl = process.env.REACT_APP_BASE_URL
  const update_Site_Url = `${baseUrl}/sites/update/`;
  const add_Site_Url = `${baseUrl}/sites/create`;

  const [field, setField] = useState<SiteData>({
    name: data?.name || "",
    url: data?.url || "",
    _id: data?._id,
    description: data?.description || "",
    // image_logo: data?.image_logo  || "",
    logo_url: data?.logo_url || "",
    user_Email: data?.user_Email || "",
    user_Name: data?.user_Name || "",
    ministry: data?.ministry || "",
    departments: data?.departments || "",
    user_id: data?.user_id
      ? typeof data.user_id.$oid === "string"
        ? { $oid: data.user_id.$oid }
        : { $oid: "" }
      : { $oid: "" },

  });
  const [file, setFile] = useState(null);
  const [userId, setUserId] = useState("");
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState<File>();
  const [base64String, setBase64String] = useState<String>();

  // Validation states
  const [error, setError] = useState({
    nameError: false,
    urlError: false,
    descriptionError: false,
    logoUrlError: false,
    userEmailError: false,
    userNameError: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setField((prevField) => ({
      ...prevField,
      [name]: value,
    }));

    // Regular expression for URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate each field
    setError((prevError) => ({
      ...prevError,
      nameError: name === "name" && value.length < 3,
      urlError: name === "url" && !urlRegex.test(value),
      descriptionError: name === "description" && value.length === 0,
      logoUrlError: name === "logo_url" && value.length === 0,
      userEmailError: name === "user_Email" && !emailRegex.test(value),
      userNameError: name === "user_Name" && value.length === 0,
    }));
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  function previewFile(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files[0];
    const name = e.target.name;
    const value = e.target.value;
    setField({ ...field, [e.target.name]: e.target.value });

    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const result = e.target.result;
        if (typeof result === "string") {
          const base64 = result.split(",")[1];

          setField(() => ({
            ...field,
            [name]: value,
            web_logo: base64, // Store the Base64-encoded image in the web_logo property
          }));

          setSelectedFile(file);
          setBase64String(base64);
        }
      };

      reader.readAsDataURL(file);
    }
  }

  // const token=localStorage.getItem('token')
  const updateData = () => {
    const formData = new FormData();
    {
      data
        ? axios
          .put(`${update_Site_Url}${data?._id}`, field, {
            headers: {
              accept: "*/*",
              "Auth-Token": `${token}`,
            },
          })
          .then((res) => {
            setStatus(res.data?.status);
          })
          .catch((error) => {
            console.log("Error occurred in updating the user data", error);
          })
        : formData.append("name", field.name);
      formData.append("url", field.url);
      formData.append("logo_url", field.logo_url);
      formData.append("description", field.description);
      formData.append("user_Name", field.user_Name);
      formData.append("user_Email", field.user_Email);
      formData.append("ministry", field.ministry)
      formData.append("department", field.departments)
      axios
        .post(`${add_Site_Url}`, formData, {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        })
        .then((res) => {
          setStatus(res.data?.status);
        })
        .catch((error) => {
          console.log("Error occurred while adding users", error);
        });
    }
  };

  const handleSubmit = (e) => {
    updateData();
    // Perform submission or further logic
  };

  useEffect(() => {
    if (status) {
      // navigate("/management/getAllSites");
    }
  }, [status, navigate]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleMultipleSend = () => {
    if (!file) {
      console.error("No file selected");
      return;
    }
    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("file", file);
    axios
      .post(`${baseUrl}/sites/uploadsites`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setMessage(res.data.message);
        setFile(null);
      })
      .catch((error) => {
        console.error(
          "Error occurred while sending multiple files",
          error.response
        );
      });
  };

  useEffect(() => {
    const storedLoginData = localStorage.getItem("login_data");
    if (storedLoginData) {
      const parsedData = JSON.parse(storedLoginData);
      setUserId(parsedData.data.user_id);
    }
  }, []);

  const [autoSuggestion, setAutoSuggestion] = useState([]);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [ministryData, setMinistryData] = useState([]);
  const [departmentData, setDepartmentData] = useState<DepartmentData>()
  const [ministryId, setMinistryId] = useState('');
  const [userData, setUserData] = useState<UserData | undefined>();


  const getAllMinistry = () => {
    axios.get(`${baseUrl}/ministries`,
      {
        headers: {
          accept: "*/*",
          "Auth-Token": `${token}`,
        },
      }).then((res) => {
        setMinistryData(res?.data?.data)
      }).catch((error) => {
        console.log("Error occured in getting ministry response", error)
      })
  }
  const getSuggestion = () => {
    setSuggestionLoading(true);
    axios
      .post(`${baseUrl}emails`,
        { email: field.user_Email },
        {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        }
      )
      .then((res) => {
        setAutoSuggestion(res?.data?.data);
        setSuggestionLoading(false);
      })
      .catch((error) => {
        console.log("Error occurred while getting email auto suggestion", error);
        setSuggestionLoading(false);
      });
  };

  useEffect(() => {
    if (field.user_Email.length > 3 && showSuggestion) {
      getSuggestion();
    } else {
      setAutoSuggestion([]);
    }
  }, [field.user_Email]);

  const handleGetDataFromSuggestion = (item) => {
    setField({ ...field, ["user_Email"]: item.email, ["user_Name"]: item.name });
    // setField({ ...field, ["user_Name"]: item.name });
    setShowSuggestion(false);
    setAutoSuggestion([]);
    setShowSuggestion(true);
  };

  const loggedin_Name = localStorage.getItem("login_data");
  const parsedData = JSON.parse(loggedin_Name);
  const user = {
    name: parsedData?.data?.name,
    avatar: "/static/images/avatars/1.jpg",
  };

  function getUserData() {
    axios
      .get(
        `${baseUrl}/user/get/${data?._id}`,
        {
          headers: {
            accept: "*/*",
            "Auth-Token": `${token}`,
          },
        }
      )
      .then((res) => {
        setUserData(res.data?.data);
      })
      .catch((error) => {
        console.log("Error occured in getting user data", error);
      });
  }

  React.useEffect(() => {
    if (data) {
      getUserData();
    }
  }, [data]);

  React.useEffect(() => {
    getAllMinistry();
  }, [])


  const getAllDepartment = () => {
    axios.get(`${baseUrl}/departments/${field.ministry}`,
      {
        headers: {
          accept: "*/*",
          "Auth-Token": `${token}`,
        },
      }).then((res) => {
        setDepartmentData(res?.data?.data)
      }).catch((error) => {
        console.log("Error occured in getting Department response", error)
      })
  }

  React.useEffect(() => {
    if (field?.ministry?.length > 0) {
      getAllDepartment()
    }
  }, [field])

  return (
    <>
      <Helmet>
        <title>{user.name ? user.name : ""} </title>
      </Helmet>
      <section
        className="breadcrum-section"
        style={{ marginTop: "20px", marginLeft: "20px" }}
      >
        <div role="presentation" style={{ marginTop: "20px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
               href="/site-management/getAllOnboardedSite"
              // href="/"
            >
              <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Sites
            </Link>
            {/* <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="/site-management/getAllOnboardedSite"
            >
              <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Sites Onboard
            </Link> */}
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {data ? "Update Site" : "Create Site"}
            </Typography>
          </Breadcrumbs>
        </div>
      </section>
      <PageTitleWrapper>
        <PageHeader title={data ? "Update Site" : "Create Site"} />
      </PageTitleWrapper>
      <div className="FormsAreaNew container">
        <div className="tab-content mt-2">
          <div
            className="tab-pane fade show active"
            id="content1"
            style={{
              background: "#fff",
              width: "100%",
              borderRadius: "20px",
              boxShadow: "0 0 52px #3d3d3d1f",
              border: "1px solid #dcdcdc",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box
                component="form"
                sx={{
                  backgroundColor: "white",
                  display: "flex",
                  width: "90%",
                  marginTop: "100px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  padding: "1rem",
                }}
                noValidate
                autoComplete="off"
              >
                {/* <div>
                  <h1 style={{fontWeight:'bold',marginBottom:'20px',textAlign:'left'}}>{data ? "Update Site" : "Add Site"}</h1>
                </div> */}
                <Box sx={{ display: "flex", gap: "2rem", width: "100%" }}>
                  <TextField
                    id="standard-multiline-flexible"
                    placeholder="Enter your Site name"
                    label="Site Name"
                    multiline
                    name="name"
                    value={field.name}
                    maxRows={4}
                    variant="outlined"
                    sx={{ flex: 1 }}
                    onChange={handleChange}
                    error={error.nameError}
                    helperText={
                      error.nameError
                        ? "Name must be at least 3 characters long"
                        : ""
                    }
                  />
                  <TextField
                    id="standard-textarea"
                    label="Site Url"
                    placeholder="Enter your Site Url"
                    multiline
                    name="url"
                    value={field.url}
                    variant="outlined"
                    sx={{ flex: 1 }}
                    onChange={handleChange}
                    error={error.urlError}
                    helperText={
                      error.urlError
                        ? "Please enter valid url"
                        : ""
                    }
                  />
                </Box>
                <Box sx={{ display: "flex", gap: "2rem", width: "100%" }}>
                  <TextField
                    id="standard-textarea"
                    label="Site Description"
                    placeholder="Enter your Site Description"
                    multiline
                    name="description"
                    value={field?.description}
                    variant="outlined"
                    sx={{ flex: 1 }}
                    onChange={handleChange}
                    error={error.descriptionError}
                    helperText={
                      error.descriptionError
                        ? "Please enter description"
                        : ""
                    }
                  />

                  <TextField
                    id="standard-textarea"
                    label="Logo url"
                    placeholder="Enter your website logo url"
                    multiline
                    name="logo_url"
                    value={field?.logo_url}
                    variant="outlined"
                    sx={{ flex: 1 }}
                    onChange={handleChange}
                    error={error.logoUrlError}
                    helperText={
                      error.logoUrlError
                        ? "Please enter some value"
                        : ""
                    }
                  />

                  {/* <input type='file'
                id="standard-textarea"
                // label="Web Logo"
                placeholder="Upload your website url"
                // multiline
                name="image_logo"
                value={field?.image_logo}
                // variant="outlined"
                // sx={{ flex: 1 }}
                onChange={previewFile}
              /> */}
                </Box>
                <div style={{ display: 'flex', width: "100%", margin: 'auto', gap: '30px', marginTop: '10px' }}>
                  <FormControl style={{ width: '50%' }}>
                    <InputLabel id="demo-simple-select-label">Ministry</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="ministry"
                      name='ministry'
                      value={field.ministry}
                      label="Ministry"

                      onChange={handleChange}
                    >
                      <MenuItem value="">

                      </MenuItem>
                      {ministryData?.map((item, index) => {
                        return <MenuItem value={item?._id?.$oid} key={index}>{item.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl style={{ width: '50%' }}>
                    <InputLabel id="demo-simple-select-label">Department</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="departments"
                      name='departments'
                      value={field.departments}
                      label="departments"
                      disabled={field?.ministry.length > 0 ? false : true}
                      onChange={handleChange}
                    >
                      <MenuItem value="">

                      </MenuItem><MenuItem value={departmentData?._id?.$oid} >{departmentData?.name}</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <TextField id="outlined-basic" label="Keyword" variant="outlined" name='keyword' style={{width:'50%'}} value={field.keyword} onChange={handleChange} /> */}
                </div>
                <Box sx={{ display: "flex", gap: "2rem", width: "100%" }}>
                 
                    <TextField
                      id="standard-textarea"
                      label="User email"
                      placeholder="Enter your user email"
                      multiline
                      name="user_Email"
                      disabled={data ? true : false}
                      value={field?.user_Email}
                      variant="outlined"
                      sx={{ flex: 1, width: "100%" }}
                      onChange={handleChange}
                    />
                    {autoSuggestion.length > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          background: "white",
                          padding: "10px",
                          width: "100%",
                          zIndex: 99,
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          borderRadius: "10px",
                          marginTop: "5px",
                        }}
                      >
                        {autoSuggestion?.map((item, index) => {
                          return (
                            <p
                              key={index}
                              style={{
                                color: "#223354",
                                padding: "10px",
                                marginBottom: "0px !important",
                                cursor: "pointer",
                              }}
                              onClick={() => handleGetDataFromSuggestion(item)}
                            >
                              {item.email}
                            </p>
                          );
                        })}
                      </div>
                    )}
                    {suggestionLoading && (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            background: "white",
                            padding: "10px",
                            width: "100%",
                            zIndex: 99,
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            borderRadius: "10px",
                            marginTop: "5px",
                          }}
                        >
                          <p
                            style={{
                              color: "#223354",
                              padding: "10px",
                              marginBottom: "0px !important",
                              cursor: "pointer",
                            }}
                          >
                            Loading...
                          </p>
                        </div>
                      </>
                    )}
                  

                  <TextField
                    id="standard-textarea"
                    label="User name"
                    placeholder="Enter username"
                    multiline
                    name="user_Name"
                    value={field?.user_Name}
                    disabled={data ? true : false}
                    variant="outlined"
                    sx={{ flex: 1 }}
                    onChange={handleChange}
                  />
                </Box>
                {data ? <Button
                  className="common-black-btn"
                  variant="contained"
                  sx={{ margin: "30px" }}
                  onClick={handleSubmit}

                >
                  Update Site
                </Button> : <Button
                  className="common-black-btn"
                  variant="contained"
                  sx={{ margin: "30px" }}
                  onClick={handleSubmit}
                  disabled={
                    !field.url ||
                    !field.name ||
                    !field.logo_url ||
                    !field.description ||
                    error.descriptionError ||
                    error.logoUrlError ||
                    error.nameError ||
                    error.urlError
                  }
                >
                  Add Site
                </Button>}

              </Box>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Create_Site;
