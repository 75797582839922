import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Logo2 from '../../src/assets/images/new-logo-03.png';
import { ToastContainer } from "react-toastify";
import { notify } from "src/common/toastMesaage";



function AdminResetPassword() {
    const userId = useParams();
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [login, setLogin] = useState({
        password: "",
        confirmPassword: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        passwordError: "",
        confirmPasswordError: "",
    });

    // For changing values
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLogin({ ...login, [name]: value });
        if (name === "password") {
            value?.length < 6 || value?.length > 20
                ? setError({
                    ...error, passwordError: "Password length should be in between 6 to 20 characters.",
                })
                : setError({ ...error, passwordError: "" });
            return;
        }
        setError({ ...error, confirmPasswordError: "" })
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        if (login?.password != login?.confirmPassword) {
            setError({ ...error, confirmPasswordError: "The password confirmation does not match." });
            return;
        }
        let data = {
            id: userId?.resetPasswordToken,
            password: login?.password,
        }
        setLoading(true)
        try {
            const response = await axios.post(`${baseUrl}api/reset/password`, data);
            setLoading(false)
            const res = response?.data
            if(res?.status){
                notify(res?.message,"success")
                navigate("/")
            }
            else{
                notify(res?.error, "error");
            }
          } catch (error) {
            setLoading(false)
            notify("Error in create password:", "error");
            console.error("Error email send:", error);
          }
    }

    return (
        <>
            <div className="bg-common-dark">
                <div className="FromArea">
                    <form className="form_container" onSubmit={handleSubmit}>
                        <div className="logo_container">
                            <img
                                src={Logo2} width={350}
                                alt="Logo"
                                loading="lazy"
                            />
                            {/* <h1 style={{fontWeight:'bold',fontSize:'0.85rem',padding:'10px',paddingBottom:'0px',color:'#222'}}>National Web Quality Index</h1> */}
                        </div>
                        <div className="title_container">
                            <p className="title">Create your new password.</p>
                            {/* <span>Find Your Account.</span> */}
                        </div>
                        <br />
                        <div className="input_container">
                            <label className="input_label" htmlFor="password">
                                Password
                            </label>
                            <i className="fa fa-key icon"></i>
                            <input
                                placeholder="Password"
                                title="Inpit title"
                                name="password"
                                className="input_field"
                                id="password"
                                value={login.password}
                                onChange={handleChange}
                            />
                        </div>
                        {error.passwordError && (
                            <p style={{ width: "100%", color: "red" }}>{error.passwordError}</p>
                        )}
                        {error.emailError && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "start",
                                    width: "100%",
                                    color: "red",
                                }}
                            >
                                <p>{error.emailError}</p>
                            </div>
                        )}
                        <div className="input_container">
                            <label className="input_label" htmlFor="confirmpassword">
                                Confirm Password
                            </label>
                            <i className="fa fa-key icon"></i>
                            <input
                                placeholder="Confirm Password"
                                title="Inpit title"
                                name="confirmPassword"
                                className="input_field"
                                id="confirmpassword"
                                value={login.confirmPassword}
                                onChange={handleChange}
                            />
                        </div>
                        {error.confirmPasswordError && (
                            <p style={{ width: "100%", color: "red" }}>{error.confirmPasswordError}</p>
                        )}
                        <button
                            title="Sign In"
                            type="submit"
                            className="sign-in_btn mt-3"
                            disabled={
                                !login?.password ||
                                !login?.confirmPassword ||
                                error.passwordError ||
                                error?.confirmPasswordError ||
                                loading
                            }
                            style={{
                                backgroundColor:
                                    (!login?.password || !login?.confirmPassword || error.passwordError || error?.confirmPasswordError || loading) && "gray",
                            }}
                        >
                            <span>{loading ? `Loding...` : "Create"}</span>
                        </button>
                        <div className="bottomDesign">
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default AdminResetPassword;
