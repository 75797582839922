import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import { Box, Button, CircularProgress, IconButton, TextField, Tooltip } from "@mui/material";
import CommonTable from "src/common/table/table";
import { useNavigate } from "react-router";
import { callApi, postApi } from "src/action/RestClient";
import ResponsiveDialog from "src/common/DeleteDialogue";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ToastContainer } from "react-toastify";
import { notify } from "src/common/toastMesaage";
import moment from "moment";
import FormControlLabel from '@mui/material/FormControlLabel';
import MySwitchComponent, { IOSSwitch } from "./switchComonent";

const data = [
    {
        "_id": "6631ec8867c3b9b858051054",
        "title": "Gst",
        "percentage": "20",
        "description": "Goods and Services Tax",
        "status": "active",
        "updated_at": "2024-06-03T06:28:44.140000Z",
        "created_at": "2024-05-01T07:17:28.752000Z"
    },
    {
        "_id": "6631ecce67c3b9b858051055",
        "title": "services tax",
        "percentage": "40",
        "description": "Services tax",
        "status": "inactive",
        "updated_at": "2024-06-03T06:29:50.729000Z",
        "created_at": "2024-05-01T07:18:38.254000Z"
    },
    {
        "_id": "6631ecfa67c3b9b858051056",
        "title": "income tax",
        "percentage": "30",
        "description": "Income tax",
        "status": "active",
        "updated_at": "2024-06-03T06:30:44.806000Z",
        "created_at": "2024-05-01T07:19:22.181000Z"
    },
    
]

const ManageTax = () => {
    const navigate = useNavigate();
    const [taxData, setTaxData] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [userAnswer, setUserAnswer] = useState(false);
    const [userDeleteId, setUserDeleteId] = useState();
    const [userLoading, setUserLoading] = useState();
    const [page, setPage] = useState(0); // Current page index
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRecoard, setTotalRecoard] = useState(0);
    const [sortby, setSortBy] = useState(null);
    const [sort, setSort] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [loading, setLoading] = useState(false);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userDeleteApi = `${baseUrl}/package/delete/`


    const getAllData = async () => {
        setUserLoading(true)
        try {
            let options = {};
            if (page !== undefined) options.page = page + 1;
            if (rowsPerPage !== undefined) options.perpage = rowsPerPage;
            if (sort !== null) options.sort = sort;
            if (sortby !== null) options.sortBy = sortby;
            if (searchTerm != null) options.searchTerm = searchTerm;
            const response = await callApi('GET', '/list/taxes', null, null);
            const data = response?.data?.data;
            setTaxData(data);
            setTotalRecoard(data?.length);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (initialLoad) {
            getAllData();
            setInitialLoad(false);
        }
    }, [initialLoad]);


    useEffect(() => {
        if (!initialLoad) {
            getAllData();
        }
    }, [page, rowsPerPage, sort, sortby, searchTerm])


    useEffect(() => {
        if(userAnswer){
            notify("Packages Deleted Successfully", "success");
            if (!initialLoad) {
                getAllData();
                setUserAnswer(false);
            }
        }
    }, [userAnswer]);


    const handleSort = (sortby, sort) => {
        setSortBy(sortby);
        setSort(sort);
    };


    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPage(data);
        setPage(0);
    }


    const formatDateCell = (params) => {
        const dateString = params?.value;
        const date = moment(dateString);
        const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
        const timeAgo = date.fromNow();
        return (
            <div>
                <p className="fw-bold">{formattedDate} <br></br><small className="fw-normal">({timeAgo})</small></p>
            </div>
        );
    };

    const renderActionsCell = (params) => {
        const user = params.row;
        return (
            <div>
                <Tooltip title="Edit Packages" arrow
                 onClick={() => updatePackage(user)}
                >
                    <IconButton color="inherit" size="small">
                        <EditTwoToneIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Packages" arrow
                 onClick={() => deletePackage(user._id)}
                >
                    <IconButton color="inherit" size="small">
                        <DeleteTwoToneIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };


    const toggleButton = async (status, user) => {
        const updatedData = taxData?.map(item =>
            item._id === user?._id ? { ...item, status: status === "active" ? "inactive" : "active" } : item
        );
         setTaxData(updatedData);
        // const selectedData = updatedData.find(item => item._id === user?._id);
        // const { _id, updated_at, created_at, ...newData } = selectedData;
        // const updateNewData = { ...newData, id: _id };
        const data = {
            status: status === "active" ? "inactive" : "active",
            stripeTaxId: user?.stripeTaxId,
            id: user?._id,
        }
        setLoading(true);
        try {
            const response = await postApi('/active-inactive/tax/status', data);
            if (response?.status) {
                notify("Taxes status update successfully", "success");
                getAllData();
            } else {
                notify(response?.error, "error");
            }
        } catch (error) {
            notify(error.message, "error");
        } finally {
            setLoading(false);
        }
    };
    
    const formatStatusCell = (params) =>{
        const user = params.row;
        return (
            <div>
            <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }}
                    checked={user?.status === "active" ? true : false}
                    onChange={() => toggleButton(user?.status === "active" ? "active" : "inactive" , user)}
                />}
            />
        </div>
            // <div>
            //     <MySwitchComponent data={user} getAllData={getAllData} />
            // </div>
        );
    }

    const deletePackage = async (id) => {
            // setUserDeleteId(id);
            // setDeleteModal(true);
    };

    const updatePackage = async (data) => {
        navigate(`/billingAndSubscriptions/manageTax`, {state: { data, type: "update" } });
    };


    const handleViewPage = async (data) => {
        navigate(`/billingAndSubscriptions/manageTax`, { state: { data, type: "view" } });
    };

    const columns = [
        { field: 'name', label: 'TAX NAME', width: 240, align: 'left' },
        { field: 'percentage', label: 'PERCENTAGE', width: 240, align: 'left' },
        { field: 'description', label: 'DESCRIPTION', width: 240, align: 'left' },
        { field: 'country', label: 'Country Code', width: 240, align: 'left' },
        { field: 'state', label: 'State Code', width: 240, align: 'left' },
        { field: 'created_at', label: 'TAX CREATED DATE', width: 240, renderCell: formatDateCell, align: 'left' },
        { field: 'status', label: 'Status', width: 240, renderCell: formatStatusCell, align: 'left' },
        { field: 'actions', label: 'Actions', width: 240, renderCell: renderActionsCell, align: 'left' },
    ];



    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Billing And Subscriptions
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Manage Taxeses
                                        </Typography>
                                    </Breadcrumbs>
                                </div>
                            </section>

                            <div className="iq-card position-relative rounded overflow-hidden">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Manage Taxes</h4>
                                    </div>
                                    <div className="align-items-center custom-toolbar-button d-flex gap-3 justify-content-end"  >
                                        <Button className="common-black-btn"
                                            onClick={() => navigate("/billingAndSubscriptions/manageTax")}
                                        >
                                            Create Taxes
                                        </Button>
                                    </div>
                                </div>
                                <Box sx={{ height: 700, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={taxData}
                                        columns={columns}
                                        // enablePagination
                                        type={"manageTaxes"}
                                        loading={userLoading}
                                        page={page}
                                        count={totalRecoard}
                                        rowsPerPage={rowsPerPage}
                                        handleSort={handleSort}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
            <ResponsiveDialog
                openDialogue={openDeleteModal}
                setopenDialogue={setDeleteModal}
                title="site"
                setAnswer={setUserAnswer}
                id={userDeleteId}
                apiUrl={userDeleteApi}
            />
            <ToastContainer/>
        </>
    );
};
export default ManageTax;
