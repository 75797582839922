import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';

import { postData } from 'src/action/RestClient';
import { useNavigate, useLocation } from 'react-router';
import {
  IconButton,
  Tooltip,
  Grid,
  Button,
  ButtonGroup,
  Paper,
  Box,
  CircularProgress,
} from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const SubscriptionDetails = () => {
  const location = useLocation();
  const userId = location?.state?.userId;
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState([]);
  const [loading, setLoading] = useState(true);
  const buttons = [
    <Button
      key="payments"
      onClick={() => invoiceHandler()}
      style={{ color: '#47546f' }}
    >
      Invoices
    </Button>,
    <Button
      key="invoices"
      onClick={() => paymentDetailsHandler()}
      style={{ color: '#47546f' }}
    >
      Payments
    </Button>,
  ];

  const postAPICalls = async () => {
    const url = '/subscription/by-user';
    const data = { id: userId };
    setLoading(true);
    try {
        const response = await postData(url, data);
        if (response.status) {
            setSubscription(response.data);
        }
    } catch (error) {
        console.error('Error fetching subscription:', error);
    } finally {
        setLoading(false);
    }
};

  useEffect(() => {
    postAPICalls();
  }, []);

  const loader = () => {
    return (
      <div className='w-100'>
        <Box className="align-items-center justify-content-center py-5 d-flex table-loader" style={{minHeight: "auto"}}>
          <CircularProgress />
        </Box>
      </div>
    );
  };

  const paymentDetailsHandler = () => {
    navigate('/users/paymentDetails', { state: { userId: userId } });
  };

  const invoiceHandler = () => {
    // navigate('/users/invoiceDetails', { state: { userId: userId } });
    navigate('/users/invoiceDetailsList', { state: { userId: userId } });
  };

  return (
    <>
      <div
        className="content-page"
        style={{ fontFamily: 'sans-serif', fontSize: '15px' }}
      >
        <Paper>
          <div className="d-flex">
            <div className="col p-2">
              <Tooltip
                className="me-2"
                title={`Back`}
                arrow
                onClick={() => navigate("/management/getAllUsers")}
              >
                <IconButton color="inherit" size="small">
                  <ArrowBackOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <div className="col text-center p-2">
              <Typography variant="h5" gutterBottom fontSize="medium">
                Subscription Details
              </Typography>
            </div>
            <div className="col text-end p-2">
              <ButtonGroup size="small" aria-label="Small button group">
                {buttons}
              </ButtonGroup>
            </div>
          </div>

          <Grid container spacing={2} style={{ padding: '35px' }}>
            {loading ? (
              loader()
            ) : subscription?.length === 0 ? (
              <div>
                <div colSpan={10}>
                  <h4 className="align-items-center justify-content-center py-5 d-flex ">
                    {'No Records Found'}
                  </h4>
                </div>
              </div>
            ) : (
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td className="w-25">
                      {' '}
                      <strong> Subscription Plan:</strong>{' '}
                    </td>
                    <td className="w-75">
                      {' '}
                      {subscription?.packageDetails?.title}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {' '}
                      <strong>Price:</strong>{' '}
                    </td>
                    <td> {subscription?.subscriptionAmount}</td>
                  </tr>
                  <tr>
                    <td>
                      {' '}
                      <strong>Status:</strong>{' '}
                    </td>
                    <td> {subscription?.status} </td>
                  </tr>
                  <tr>
                    <td>
                      {' '}
                      <strong>PurchaseDate:</strong>
                    </td>
                    <td> {subscription?.subscriptionPurchaseDate}</td>
                  </tr>
                  <tr>
                    <td>
                      {' '}
                      <strong>Description:</strong>
                    </td>
                    <td> {subscription?.packageDetails?.description}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </Grid>
        </Paper>
      </div>
    </>
  );
};
export default SubscriptionDetails;
