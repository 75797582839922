export const  getCategoryTitle = (category) => {
    switch (category) {
      case "security_privacy":  return "Security Privacy";
      case "accessibility":  return "Accessibility";
      case "branding_visual":  return "Branding Visual";
      case "business_presence":  return "Business Presence";
      case "content_information":  return "Content Information";
      case "integration_service":  return "Integration Service";
      case "interactivity_engagement":  return "Interactivity Engagement";
      case "mobile_responsiveness":  return "Mobile Responsiveness";
      case "navigation":  return "Navigation";
      case "performance_technical":  return "Performance Technical";
      case "seo":  return "Seo";
      default:  return category;
    }
  };
  