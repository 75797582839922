import React, { useEffect, useState } from 'react';
import { postData } from 'src/action/RestClient';
import {
  Paper,
  Tooltip,
  IconButton,
  Grid,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useLocation } from 'react-router';
import CommonTable from 'src/common/table/table';

const PaymentDetails = () => {
  const location = useLocation();
  const userId = location?.state?.userId;
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageOfLinesOfInvoice, setPageOfLinesOfInvoice] = useState(0); // Current page index of Lines of invoices
  const [pageOfTaxesOfInvoice, setPageOfTaxesOfInvoice] = useState(0); // Current page index of texes of invoices
  const [rowsPerPageOfLinesOfInvoice, setRowsPerPageOfLinesOfInvoice] = useState(10);
  const [rowsPerPageOfTaxesOfInvoice, setRowsPerPageOfTaxesOfInvoice] = useState(10);
  const navigate = useNavigate();


  const handlePageChangeOfLinesOfInvoice = (event, newPage) => {
    setPageOfLinesOfInvoice(newPage);
  };
  const handlePageChangeOfTaxesOfInvoice = (event, newPage) => {
    setPageOfTaxesOfInvoice(newPage);
  };

  const handleRowsPerPageChangeOfLinesOfInvoice = (newRowsPerPage) => {
    const data = parseInt(newRowsPerPage, 10);
    setRowsPerPageOfLinesOfInvoice(data);
    setPageOfLinesOfInvoice(0);
  };

  const handleRowsPerPageChangeOfTaxesOfInvoice = (newRowsPerPage) => {
    const data = parseInt(newRowsPerPage, 10);
    setRowsPerPageOfTaxesOfInvoice(data);
    setPageOfTaxesOfInvoice(0);
  };


  const postAPICalls = async () => {
    const url = `/subscription-payments/by-user`;
    const data = { id: userId };
    setLoading(true);
    try {
      const response = await postData(url, data);
      if (response.status) {
        setPaymentDetails(response?.data);
      }
    } catch (error) {
      console.error('Error fetching subscription:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    postAPICalls();
  }, []);


  const column2 = [
    { field: 'subscriptionId', label: 'subscription Id', width: 240, align: 'left' },
    { field: 'amount', label: 'Price', width: 240, align: 'left' },
    { field: 'statusOfPayment', label: 'Status', width: 240, align: 'left' },
    { field: 'dateOfPayment', label: 'PurchaseDate', width: 240, align: 'left', },
  ];

  const loader = () => {
    return (
      <div className='w-100'>
        <Box className="align-items-center justify-content-center py-5 d-flex table-loader" style={{ minHeight: "auto" }}>
          <CircularProgress />
        </Box>
      </div>
    );
  };

  return (
    <>
      <div
        className="content-page"
        style={{ fontFamily: 'sans-serif', fontSize: '15px' }}
      >
        <Paper>
          <div className="d-flex">
            <div className="col p-2">
              <Tooltip
                className="me-2"
                title={`Back`}
                arrow
                onClick={() => navigate(-1)}
              >
                <IconButton color="inherit" size="small">
                  <ArrowBackOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <div className="col text-center p-2">
              <Typography variant="h5" gutterBottom fontSize="medium">
                Payment Details
              </Typography>
            </div>
            <div className="col"></div>
          </div>
        </Paper>
        {loading ? (
          loader()
        ) : paymentDetails?.length === 0 ? (
          <div>
            <div colSpan={10}>
              <h4 className="align-items-center justify-content-center py-5 d-flex ">
                {'No Records Found'}
              </h4>
            </div>
          </div>
        ) : (
          <div className="row mt-3">
            <div className="col">
              {' '}
              <Paper>
                <>
                  <div className="iq-card position-relative rounded overflow-hidden cardAreaTable">
                    <Box
                      sx={{
                        height: 400,
                        width: '100%',
                        background: 'white',
                        paddingBottom: '41px',
                      }}
                      className="shadow-none"
                    >
                      <CommonTable
                        data={paymentDetails}
                        columns={column2}
                        //   enablePagination
                        type={'taxesOfInvoice'}
                        page={pageOfTaxesOfInvoice || 1}
                        count={paymentDetails?.length || 1}
                        rowsPerPage={rowsPerPageOfTaxesOfInvoice}
                        onPageChange={handlePageChangeOfTaxesOfInvoice}
                        onRowsPerPageChange={
                          handleRowsPerPageChangeOfTaxesOfInvoice
                        }
                      />
                    </Box>
                  </div>
                </>
              </Paper>
            </div>
          </div>
        )}

      </div>
    </>
  );
};
export default PaymentDetails;
