import GetAll_Users from './GetAll_Users';
import Common_Fun from 'src/HOC/Common_Fun';
import { userHeadKey as headkey ,userColoumnKey as coloumnKey} from 'src/common/apiCommonData';

const baseUrl = process.env.REACT_APP_BASE_URL
const getApiData=`${baseUrl}/users`;
const addDataRoute='/management/createUser'
const updateDataRoute='/management/createUser';

const slug = "user"

const Enhanced_User = Common_Fun(GetAll_Users, getApiData,addDataRoute,updateDataRoute,headkey,coloumnKey,slug);

export default Enhanced_User;
