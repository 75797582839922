import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import axios from 'axios'
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/content/dashboards/Crypto/PageHeader';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Box from "@mui/material/Box";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { callApi, postData } from "src/action/RestClient";
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate, useParams } from "react-router";
import { getCategoryTitle } from "./categoryTitle";
import { Button } from "@mui/material";
import { ToastContainer } from 'react-toastify'
import { notify } from "src/common/toastMesaage";


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '1000ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));



const MilestoneReport = () => {
    const location = useLocation();
    console.log("location", location)
    const siteId = location?.state?.data?.site_id?.$oid;
    const queueId = location?.state?.data?.queue_id;
    const siteName = location?.state?.data?.name;
    const navigate = useNavigate();
    const [mileStoneData, setMilestoneData] = useState();
    const [flag, setFlag] = useState(location?.state?.flag ? location?.state?.flag : "");
    const [index, setIndex] = useState(0);
    const [mileStoneLoading, setMilestoneLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingButtons, setLoadingButtons] = useState({});


    const handleChange = (event, key, quesIndex, catIndex, type) => {
        const { value } = event.target;
        const updatedMileStoneData = { ...mileStoneData };
        const categoryKey = Object.keys(updatedMileStoneData)[catIndex];
        const questions = updatedMileStoneData[categoryKey];
        const question = questions[quesIndex];
        // console.log("question", value, question, type)
        if (!question.hasOwnProperty('data')) {
            question.data = {};
        }
        if (Array.isArray(question.data) && question.data.length === 0) {
            question.data = {};
        }
        if (typeof question.data === 'object') {
            question.data[key] = value;
        }
        question.data[key] = value;
        setMilestoneData(updatedMileStoneData);
    };


    const handleUpdate = (e, catIndex, description, data, category, quction) => {
        e.preventDefault();
        setIndex(catIndex)
        const keysLength = Object.keys(data).length;
        let transformedData;
        if (keysLength === 1) {
            transformedData = data.result;
        } else {
            transformedData = data;
        }
        const sendData = {
            category: category,
            questionId: quction?.acId ? quction?.acId : quction?.id,
            data: transformedData,
            queId: queueId,
            description: description
        }
        console.log("sendData", sendData)
        updateQuctions(sendData, quction?.id)
    };


    const updateQuctions = async (data, id) => {
        try {
            setLoading(true);
            setLoadingButtons(prevState => ({ ...prevState, [id]: true, }));

            const url = '/updateQuestions';
            const response = await postData(url, data);
            setLoading(false);
            setLoadingButtons(prevState => ({ ...prevState, [id]: false, }));
            if (response?.status) {
                notify(response?.message, "success");
                getMilestoneData();
            } else {
                notify(response?.error, "error");
            }
        } catch (error) {
            setLoading(false);
            setLoadingButtons(prevState => ({ ...prevState, [id]: false, }));
            console.error('Error:', error);
            notify(response?.message, "success");
        }
    }

    const toggleButton = (status, quesIndex, catIndex, descriptions, data, category, quctionId) => {
        setIndex(catIndex)
        // console.log("toggling", status, quesIndex, catIndex, descriptions, data, category, quctionId)
        const updatedMileStoneData = { ...mileStoneData };
        const categoryKey = Object.keys(updatedMileStoneData)[catIndex];
        const questions = updatedMileStoneData[categoryKey];
        const question = questions[quesIndex];
        question["description"] = !question["description"]
        setMilestoneData(updatedMileStoneData);


        // const keysLength = Object.keys(data).length;
        // let transformedData;
        // if (keysLength === 1) {
        //     transformedData = data.result;
        // } else {
        //     transformedData = data;
        // }
        // const sendData = {
        //     category: category,
        //     questionId: quctionId,
        //     data: transformedData,
        //     queId: queueId,
        //     description: question?.description
        // }
        // // console.log("sendData", sendData)
        // updateQuctions(sendData)
    }


    // /get/reportForSiteWithqueId/{id}/{queId}
    const getMilestoneData = async () => {
        try {
            setMilestoneLoading(true)
            const response = await callApi('GET', `/get/reportForSiteWithqueId/${siteId}/${queueId}`, null, null);
            setMilestoneLoading(false)
            const data = response?.data?.data;
            setMilestoneData(data);
        }
        catch (error) {
            setMilestoneLoading(false)
            console.error('Error:', error);
        }
    }


    useEffect(() => {
        getMilestoneData(siteId);
    }, [siteId])



    return (
        <>
            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                <div role="presentation" style={{ marginTop: '20px' }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            onClick={() => navigate(-1)}
                        // href="/site-management/getAllTopSite"
                        >
                            <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            MileStoneList
                        </Link>
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        </Typography>
                    </Breadcrumbs>
                </div>
            </section>
            <PageTitleWrapper>
                {/* <PageHeader title={`${siteName}`} /> */}
                <PageHeader
                    title={<span> WebSite:- <span style={{ color: '#276b1f', fontSize: "2rem", fontWeight: 500 }}>{siteName}</span></span>}
                />
            </PageTitleWrapper>

            <div className="accordion" id="accordionExample" style={{ width: '95%', margin: 'auto', marginTop: '20px' }}>
                {mileStoneLoading ?
                    <>
                        <Box className='align-items-center justify-content-center py-5 d-flex table-loader'>
                            <CircularProgress />
                        </Box>
                    </> :
                    <>
                        {mileStoneData !== undefined && mileStoneData !== null && Object.keys(mileStoneData).length > 0 ?
                            <>
                                {Object.entries(mileStoneData).map(([category, questions], catIndex) => (
                                    <div className="accordion-item" key={catIndex}>
                                        <h2 className="accordion-header" id={`heading${catIndex}`}>
                                            <button
                                                className={`accordion-button ${catIndex === index ? 'collapsed' : ''}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${catIndex}`}
                                                aria-expanded={catIndex === index ? 'true' : 'false'}
                                                aria-controls={`collapse${catIndex}`}
                                            >
                                                {getCategoryTitle(category)}
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapse${catIndex}`}
                                            className={`accordion-collapse collapse ${catIndex === index ? 'show' : ''}`}
                                            aria-labelledby={`heading${catIndex}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                {(!questions || questions.length === 0) ? (
                                                    <p>No questions available for this category.</p>
                                                ) : (
                                                    questions?.map((question, quesIndex) => (
                                                        <div key={quesIndex}>
                                                            <div>
                                                                {quesIndex + 1}. {question.title}
                                                                <div className="modal-body">
                                                                    <Box sx={{ width: "100%", background: "white", }}>
                                                                        <div className="FormsAreaNew container">
                                                                            <div className="tab-content ">
                                                                                <form  >
                                                                                    {Array.isArray(question.data) && question.data.length == 0 && question.description === "coming_soon" ?
                                                                                        <>
                                                                                            <div className="report-form">
                                                                                                <div className="form-group" >
                                                                                                    {"The status is coming soon for this question"}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <> <div className="report-form">
                                                                                            <div className="form-group">
                                                                                                <label>{question.screenPresent && question?.data == false ? "Screen Present" : "Description"}</label>
                                                                                                <div>{question.screenPresent && question?.data == false ? question.screenPresent == true ? "true" : "false" :
                                                                                                    question.description == "Unknown" ? "Unknown" :
                                                                                                        question.description === 0 ? "Zero" :
                                                                                                            <FormControlLabel
                                                                                                                control={<IOSSwitch sx={{ m: 1 }}
                                                                                                                    checked={question.description === true ? true : false}
                                                                                                                    // defaultChecked={question.description === true ? true : false}
                                                                                                                    onChange={() => toggleButton(question.description == true ? "inActive" : "active", quesIndex, catIndex, question.description, question.data, category, question.id)}
                                                                                                                />}
                                                                                                            />
                                                                                                }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                            {question?.screens && question?.data == false ?
                                                                                                <>
                                                                                                    <div className="report-form">
                                                                                                        {question?.screens.map((item, itemIndex) => (
                                                                                                            <div className="form-group" key={itemIndex}>
                                                                                                                <label>{item?.title}</label>
                                                                                                                <textarea
                                                                                                                    type="text"
                                                                                                                    value={item?.imgPath}
                                                                                                                    // onChange={event => handleChange(event, "result", quesIndex, catIndex, "one")}
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <>  {(typeof question.data === 'object' && question.data !== null && Object.keys(question.data).length > 0) ||
                                                                                                    (Array.isArray(question.data) && question.data.length > 0) ? (
                                                                                                    <div className="report-form">
                                                                                                        {Array.isArray(question.data) ? (
                                                                                                            question.data.map((item, itemIndex) => (
                                                                                                                <div className="form-group" key={itemIndex}>
                                                                                                                    <div>{`Data ${itemIndex + 1}`}</div>
                                                                                                                    <textarea
                                                                                                                        type="text"
                                                                                                                        value={item}
                                                                                                                        onChange={event => handleChange(event, "result", quesIndex, catIndex, "one")}

                                                                                                                    />
                                                                                                                </div>
                                                                                                            ))
                                                                                                        )
                                                                                                            : (
                                                                                                                Object.entries(question.data).map(([key, value]) => (
                                                                                                                    <div className="form-group" key={key}>
                                                                                                                        <label>{key}</label>
                                                                                                                        <textarea
                                                                                                                            type="text"
                                                                                                                            value={value}
                                                                                                                            onChange={event => handleChange(event, key, quesIndex, catIndex, "two")}

                                                                                                                        />
                                                                                                                    </div>
                                                                                                                ))
                                                                                                            )}
                                                                                                    </div>
                                                                                                ) :
                                                                                                    (
                                                                                                        <div className="report-form">
                                                                                                            <div className="form-group" >
                                                                                                                <label>result</label>
                                                                                                                <textarea
                                                                                                                    type="text"
                                                                                                                    value={question.data}
                                                                                                                    onChange={event => handleChange(event, "result", quesIndex, catIndex, "three")}
                                                                                                                // disabled={question.data == false}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>}
                                                                                            <div>
                                                                                                {question.data == false
                                                                                                    ? ""
                                                                                                    :
                                                                                                    <>{loadingButtons[question.id] ?
                                                                                                        <Button className="common-black-btn">
                                                                                                           <Box gap-3 sx={{ color: "white", width: "100px" }}>
                                                                                                                <CircularProgress color="inherit" size={18} />
                                                                                                            </Box>
                                                                                                        </Button>
                                                                                                        :
                                                                                                        <Button className="common-black-btn"
                                                                                                            onClick={(e) => handleUpdate(e, catIndex, question.description, question.data, category, question)}
                                                                                                        >
                                                                                                             <> {flag == "update" ? "Update MileStone" : "Create MileStone"} </>
                                                                                                        </Button>}
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                    }

                                                                                </form>

                                                                            </div>
                                                                        </div>
                                                                    </Box>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                            : ""}
                    </>}
            </div>
            <ToastContainer />
        </>
    );
};
export default MilestoneReport;


{/* <IOSSwitch
                                              sx={{ m: 1 }}
                                              checked={checkedStates[question.id] || false}
                                              onChange={() => {
                                                const newStatus = question.description === true ? "inActive" : "active";
                                                toggleButton(newStatus, quesIndex, catIndex, question.description, question.data, category, question.id);
                                                setCheckedStates(prevState => ({
                                                  ...prevState,
                                                  [question.id]: !prevState[question.id],
                                                }));
                                              }}
                                            />
                                          } */}