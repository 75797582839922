import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import axios from 'axios'
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from 'src/content/dashboards/Crypto/PageHeader';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Box from "@mui/material/Box";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { callApi, postData } from "src/action/RestClient";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from "react-router";
import { getCategoryTitle } from "./categoryTitle";
import { Button } from "@mui/material";



const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '1000ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));



const CreateMilestoneReport = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [mileStoneData, setMilestoneData] = useState();
    console.log("mileStoneData", mileStoneData)
    const [index, setIndex] = useState(0);
    const [mileStoneLoading, setMilestoneLoading] = useState(false)

    const handleChange = (event, key, quesIndex, catIndex) => {
        const { value } = event.target;
        console.log("change", key, value, quesIndex, catIndex);
        const updatedMileStoneData = { ...mileStoneData };
        const categoryKey = Object.keys(updatedMileStoneData)[catIndex];
        const questions = updatedMileStoneData[categoryKey];
        const question = questions[quesIndex];
        if (!question.hasOwnProperty('data')) {
            question.data = {};
        } else if (question.data.length == 0) {
            question.data = {};
        }
        console.log("quctionData", question.data)
        question.data[key] = value;
        setMilestoneData(updatedMileStoneData);
    };


    const removeTags = (html) => {
            // Check if html is a string
            if (typeof html === 'string') {
              const regex = /<[^>]*>/g;
              return html.replace(regex, '');
            }
            // If html is not a string, return it as is
            return html;
          };

    const handleUpdate = (e, quesIndex, catIndex, title, description, data) => {
        e.preventDefault();
        setIndex(catIndex);
        console.log("Updating:", quesIndex, catIndex, title, description, data);
        const params = {
            title: title,
            description: description,
            result: data
        }
        console.log("params", params)
    };


    const toggleButton = (status, quesIndex, catIndex, title, descriptions, data) => {
        setIndex(catIndex);
        console.log("toggleing", status, title, descriptions, data);
        const updatedMileStoneData = { ...mileStoneData };
        const categoryKey = Object.keys(updatedMileStoneData)[catIndex];
        const questions = updatedMileStoneData[categoryKey];
        const question = questions[quesIndex];
        console.log("question", question)
        question["description"] = !question["description"]

        console.log("updatedMileStoneData", updatedMileStoneData)
    }



    const getMilestoneData = async () => {
        try {
            setMilestoneLoading(true)
            const response = await callApi('GET', `/get/reportForSite/${params?.id}`, null, null);
            setMilestoneLoading(false)
            const data = response?.data?.data;
            setMilestoneData(data);
        }
        catch (error) {
            setMilestoneLoading(false)
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        getMilestoneData(params?.id);
    }, [params])

    return (
        <>
            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                <div role="presentation" style={{ marginTop: '20px' }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            onClick={() => navigate(-1)}
                        // href="/site-management/getAllTopSite"
                        >
                            <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            MileStoneList
                        </Link>
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >
                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        </Typography>
                    </Breadcrumbs>
                </div>
            </section>
            <PageTitleWrapper>
                <PageHeader title="Milestone Report" />
            </PageTitleWrapper>

            <div className="accordion" id="accordionExample" style={{ width: '95%', margin: 'auto', marginTop: '20px' }}>
                {mileStoneLoading ?
                    <>
                        <Box className='align-items-center justify-content-center py-5 d-flex table-loader'>
                            <CircularProgress />
                        </Box>
                    </> :
                    <>
                        {mileStoneData !== undefined && mileStoneData !== null && Object.keys(mileStoneData).length > 0 ?
                            <>
                                {Object.entries(mileStoneData).map(([category, questions], catIndex) => (
                                    <div className="accordion-item" key={catIndex}>
                                        <h2 className="accordion-header" id={`heading${catIndex}`}>
                                            <button
                                                className={`accordion-button ${catIndex === index ? 'collapsed' : ''}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${catIndex}`}
                                                aria-expanded={catIndex === index ? 'true' : 'false'}
                                                aria-controls={`collapse${catIndex}`}
                                            >
                                                {getCategoryTitle(category)}
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapse${catIndex}`}
                                            className={`accordion-collapse collapse ${catIndex === index ? 'show' : ''}`}
                                            aria-labelledby={`heading${catIndex}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                {(!questions || questions.length === 0) ? (
                                                    <p>No questions available for this category.</p>
                                                ) : (
                                                    questions?.map((question, quesIndex) => (
                                                        <div key={quesIndex}>
                                                            <div>
                                                                {quesIndex + 1}. {question.title}
                                                                <div className="modal-body">
                                                                    <Box sx={{ width: "100%", background: "white", }}>
                                                                        <div className="FormsAreaNew container">
                                                                            <div className="tab-content ">
                                                                                <form  >
                                                                                    <div className="report-form">
                                                                                        <div className="form-group">
                                                                                            <label>Description</label>
                                                                                            <FormControlLabel
                                                                                                control={<IOSSwitch sx={{ m: 1 }}
                                                                                                    // checked={isChecked}
                                                                                                    defaultChecked={question.description === true}
                                                                                                    onChange={() => toggleButton(question.description == true ? "inActive" : "active", quesIndex, catIndex, question.title, question.description, question.data)}
                                                                                                />}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    {(typeof question.data === 'object' && question.data !== null && Object.keys(question.data).length > 0) ||
                                                                                        (Array.isArray(question.data) && question.data.length > 0) ? (
                                                                                        <div className="report-form">
                                                                                            {Array.isArray(question.data) ? (
                                                                                                question.data.map((item, itemIndex) => (
                                                                                                    <div className="form-group" key={itemIndex}>
                                                                                                        <div>{`Data ${itemIndex + 1}`}</div>
                                                                                                        <textarea
                                                                                                            type="text"
                                                                                                            value={item}
                                                                                                            onChange={e => handleChange(`Data ${itemIndex + 1}`, e.target.value)}

                                                                                                        />
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : (
                                                                                                Object.entries(question.data).map(([key, value]) => (
                                                                                                    <div className="form-group" key={key}>
                                                                                                        <label>{key}</label>
                                                                                                        <textarea
                                                                                                            type="text"
                                                                                                            // value={removeTags(value)}
                                                                                                            value={value}
                                                                                                            onChange={event => handleChange(event, key, quesIndex, catIndex)}

                                                                                                        />
                                                                                                    </div>
                                                                                                ))
                                                                                            )}
                                                                                        </div>
                                                                                    ) : (
                                                                                        // <p>Data not available</p>
                                                                                        <div className="report-form">
                                                                                            <div className="form-group" >
                                                                                                <label>result</label>
                                                                                                <textarea
                                                                                                    type="text"
                                                                                                    // value={value}
                                                                                                    onChange={event => handleChange(event, "result", quesIndex, catIndex)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                    <div style={{marginLeft: "25%"}}>
                                                                                        <Button className="common-black-btn"
                                                                                            onClick={(e) => handleUpdate(e, quesIndex, catIndex, question.title, question.description, question.data)}
                                                                                        >
                                                                                            Create MileStone
                                                                                        </Button>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    </Box>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                            : ""}
                    </>}
            </div>
        </>
    );
};
export default CreateMilestoneReport;


