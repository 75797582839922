import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import GrainIcon from "@mui/icons-material/Grain";
import { useNavigate } from "react-router";
import CommonTable from "src/common/table/table";
import Box from "@mui/material/Box";
import moment from "moment";
import { callApi } from "src/action/RestClient";



const LastLoggedIn = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [userLoading, setUserLoading] = useState(false);
    const [page, setPage] = useState(0); // Current page index
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRecoard, setTotalRecoard] = useState(0);
    const [sortby, setSortBy] = useState(null);
    const [sort, setSort] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);



    const getAllData = async () => {
       setUserLoading(true)
        try {
          let options = {};
          if (page !== undefined) options.page = page + 1;
          if (rowsPerPage !== undefined) options.perpage = rowsPerPage;
          if (sort !== null) options.sort = sort;
          if (sortby !== null) options.sortBy = sortby;
          if (searchTerm != null) options.searchTerm = searchTerm;
          const response = await callApi('GET', '/last-login/users', null, options);
          const data = response?.data?.data?.data;
          setUserData(data);
          setTotalRecoard(data?.length);
          setUserLoading(false)
          return data;
        } catch (error) {
          console.error('Error:', error);
          setUserLoading(false)
          throw error;
        }
      };

    useEffect(() => {
        if (initialLoad) {
            getAllData();
            setInitialLoad(false);
        }
    }, [initialLoad]);


    useEffect(() => {
        if (!initialLoad) {
            getAllData();
        }
    }, [page, rowsPerPage, sort, sortby, searchTerm])


    const handleSort = (sortby, sort) => {
        setSortBy(sortby);
        setSort(sort);
    };

 
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPage(data);
        setPage(0);
    }


    const formatDateCell = (params) => {
        const dateString = params?.row?.last_login;
        const date = moment(dateString);
        const formattedDate = date.format('ddd, D MMM YYYY HH:mm');
        const timeAgo = date.fromNow();
        return (
            <div>
                <p className="fw-bold">{formattedDate} <br></br><small className="fw-normal">({timeAgo})</small></p>
            </div>
        );
    };


    const columns = [
        { field: 'name', label: 'USER NAME', width: 240, align: 'left' },
        { field: 'email', label: 'USER EMAIL', width: 240, align: 'left' },
        { field: 'last_login', label: 'LAST LOGIN DATE', width: 240, renderCell: formatDateCell, align: 'left' },
    ];


    return (
        <>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <div role="presentation" style={{ marginTop: '20px' }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link
                                            underline="hover"
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="inherit"
                                            href="/"
                                        >
                                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Users
                                        </Link>
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            color="text.primary"
                                        >
                                            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                            Latest Logged In
                                        </Typography>
                                    </Breadcrumbs>
                                </div>
                            </section>

                            <div className="iq-card position-relative rounded overflow-hidden">

                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>Latest Logged In</h4>
                                    </div>
                                </div>

                                <Box sx={{ height: 500, width: "100%", background: "white", paddingBottom: '41px' }}>
                                    <CommonTable
                                        data={userData}
                                        columns={columns}
                                        enablePagination
                                        type={"lastLoggedIn"}
                                        loading={userLoading}
                                        page={page}
                                        count={totalRecoard}
                                        rowsPerPage={rowsPerPage}
                                        handleSort={handleSort}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                    />
                                </Box>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </>
    );
};
export default LastLoggedIn;
