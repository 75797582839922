import React, { useEffect, useRef, useState } from 'react'
import { TextField, Button } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ToastContainer } from 'react-toastify'
import Box from "@mui/material/Box";
import { postApi, postData } from 'src/action/RestClient'
import { useNavigate } from 'react-router'
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { notify } from 'src/common/toastMesaage';
import CircularProgress from '@mui/material/CircularProgress';



const CreateManagePackages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState("create");
  const [updateId, setUpdateId] = useState();
  const [field, setField] = useState({ packageuid: "", title: "", description: "", allowWebsites: "", stripeProductId: "", packageOrder: 0 })
  const [image, setImage] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [status, setStatus] = useState(false);
  const [prices, setPrices] = useState([{ monthlyPrice: '', yearlyPrice: "" }]);
  const [imageUrl, setImsgeUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [error, setError] = useState({
    packageUIDError: false,
    titleError: false,
    allowWebsitesError: false,
    websitesError: false,
    descriptionError: false,
    monthPriceError: false,
    yearPriceError: false,
    packageOrderError: false,
  });

  //validation function for url check
  const isValidUrl = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };


  //fill inputfield function for error or field value
  const handleChangeField = (e) => {
    const { name, value } = e.target;
    setField({ ...field, [name]: value });
    setError((prevError) => ({
      ...prevError,
      packageUIDError:
        name === "packageuid" && value.length < 12,
    }));
    setError((prevError) => ({
      ...prevError,
      titleError: name === "title" && value.length < 3,
    }));
    // const isValid = isValidUrl(value);
    setError((prevError) => ({
      ...prevError,
      websitesError:
        name === "allowWebsites" &&
        value.length < 1,
    }));
    setError((prevError) => ({
      ...prevError,
      descriptionError:
        name === "description" &&
        value.length < 3,
    }));
    setError((prevError) => ({
      ...prevError,
      descriptionError:
        name === "packageOrder" &&
        value.length < 0,
    }));

  };


  // fill price input field and its error
  const handleChangePrice = (e) => {
    const { name, value } = e.target;
    // if (!prices.length) {
    //   // setPrices([{ monthlyPrice: '', yearlyPrice: "" }]);
    //   setPrices([{ monthlyPrice: '' }]);
    //   return;
    // }
    if (name === 'monthlyPrice') {
      const updatedPrices = prices.map((price, index) => {
        if (index === 0) {
          return { ...price, monthlyPrice: parseFloat(value) };
        } else {
          return price;
        }
      });
      setPrices(updatedPrices);
    } else if (name === 'yearlyPrice') {
      const updatedPrices = prices.map((price, index) => {
        if (index === 0) {
          return { ...price, yearlyPrice: parseFloat(value) };
        } else {
          return price;
        }
      });
      setPrices(updatedPrices);
    }
    setError((prevError) => ({
      ...prevError,
      monthPriceError:
        name === "monthlyPrice" &&
        value < 0,
    }));
    const isMonthlyPriceGreaterThanYearlyPrice = prices[0].monthlyPrice > value;
    setError((prevError) => ({ ...prevError, yearPriceError: name === "yearlyPrice" && isMonthlyPriceGreaterThanYearlyPrice }));
  };


  // bothChanged,
  // monthlyChanged,
  // yearlyChanged,
  // priceChanged,
  // stripeProductId,
  // bothSame

  const handleChangesValue = (prices, month, year) => {
    if (!prices || prices.length === 0) {
      return "";
    }
    const price = prices[0];
    if (price?.monthlyPrice == month && price?.yearlyPrice == year) {
      return "bothSame";
    } else if (price?.monthlyPrice == month && price?.yearlyPrice != year) {
      return "yearlyChanged";
    } else if (price?.monthlyPrice != month && price?.yearlyPrice == year) {
      return "monthlyChanged";
    } else {
      return "bothChanged";
    }
  };

  //On form submit when create and update 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let data = {}
      if (page == "create") {
        data = {
          title: field.title,
          description: field.description,
          packageUID: field.packageuid,
          packageOrder:field.packageOrder,
          allowWebsites: Number(field.allowWebsites),
          prices: [...prices]
        };
      } else {
        data = {
          title: field.title,
          description: field.description,
          packageUID: field.packageuid,
          stripeProductId: field.stripeProductId,
          packageOrder:field.packageOrder,
          allowWebsites: Number(field.allowWebsites),
          priceChanged: handleChangesValue(prices, month, year),
          prices: [...prices]
        }
      }
      const formData = new FormData(); // Create a new FormData object
      for (let key in data) {
        const value = data[key];
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            if (key === 'prices') {
              formData.append(`prices[monthlyPrice] `, item.monthlyPrice);
              formData.append(`prices[yearlyPrice]`, item.yearlyPrice);
            }
            else if (key === 'images') {
              formData.append(`${key}[${index}]`, item);
            }
          });
        }
        else {
          formData.append(key, value);
        }
      }
      if (page == "create") {
        const url = '/create/packages';
        setLoading(true);
        const response = await postApi(url, formData);
        setLoading(false);
        if (response?.status) {
          notify("Packages Created Sucessfully", "success");
          setField({ packageuid: "", title: "", description: "", allowWebsites: "" ,  packageOrder: ""});
          setPrices([{ monthlyPrice: '', yearlyPrice: "" }]);
        }
        else{
          notify(response?.error, "error");
        }
      }
      else {
        const url = `/package/update/${updateId}`;
        setLoading(true);
        const response = await postApi(url, formData);
        setLoading(false);
        if (response?.status) {
          notify("Packages Update Sucessfully", "success");
        }
        else{
          notify(response?.error, "error");
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };


  //change image fill value
  const handleImageChangeArray = (e) => {
    const files = e.target.files;
    const file = e.target.files[0];
    const fileArray = Array.from(files).map((file) => URL.createObjectURL(file));
    setImage([...image, ...fileArray]);
    setImsgeUrl([...imageUrl, ...files]); // Store all selected files
  };


  // add image on field value
  const handleAddImage = () => {
    fileInputRef.current.click();
  };

  //remove image from state
  const handleRemoveImage = async (index, imageUrl) => {
    const blobCheck = imageUrl.includes("blob")
    if (blobCheck) {
      setImage(image.filter((_, i) => i !== index));
      setImsgeUrl(imageUrl.filter((_, i) => i !== index));
    } else {
      const url = '/file/unlink';
      const data = { url: imageUrl };
      const response = await postData(url, data);
      if (response.status) {
        const stateData = response.data;
        setField({
          title: stateData.title || "",
          description: stateData.description || "",
          websites: stateData.websites || "",

        });
        setImage(stateData.images || []);
        setPrices([{ monthlyPrice: stateData.prices.monthlyPrice, yearlyPrice: stateData.prices.yearlyPrice }]);
      }
    }
  };


  //on update set all field value 
  useEffect(() => {
    if (location.state) {
      const stateData = location?.state?.data;
      setPage(location?.state?.type);
      setUpdateId(stateData._id)
      setField({
        packageuid: stateData.packageUID || "",
        title: stateData.title || "",
        description: stateData.description || "",
        allowWebsites: stateData.allowWebsites || "",
        stripeProductId: stateData.stripeProductId || "",
        packageOrder: stateData.packageOrder
      });
      setMonth(stateData.prices.monthlyPrice || "");
      setYear(stateData.prices.yearlyPrice || "");
      // setImage(stateData.images || []);
      setPrices([{ monthlyPrice: stateData.prices.monthlyPrice, yearlyPrice: stateData.prices.yearlyPrice }]);

      delete location.state;
    }

  }, [location.state]);


  return <>
    <div id="content-page" className="content-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
              <div role="presentation" style={{ marginTop: '20px' }}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/billingAndSubscriptions/managePackages"
                  >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Manage Packages
                  </Link>
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="text.primary"
                  >
                    <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {page == "view" ? "View Manage Packages" :
                      page == "update" ? "Update Manage Packages"
                        : "Create Manage Packages"}
                  </Typography>
                </Breadcrumbs>
              </div>
            </section>
            <div className="iq-card position-relative rounded overflow-hidden">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>  {page == "view" ? "View Manage Packages" : page == "update" ? "Update Manage Packages" : "Create Manage Packages"} </h4>
                </div>
              </div>
              <Box sx={{ width: "100%", background: "white", paddingBottom: '41px' }}>
                <div className="FormsAreaNew container">
                  <div className="tab-content ">
                    <form onSubmit={handleSubmit} >
                      <Box className='create_package_outer'
                        component="div"
                        noValidate
                        autoComplete="off"
                      >
                        <Box className='form-field-box flex-wrap d-flex'>
                          <TextField
                            placeholder="Enter your package UID"
                            label="Package UID"
                            name="packageuid"
                            type="number"
                            value={field.packageuid}
                            variant="outlined"
                            sx={{ flex: 1 }}
                            onChange={handleChangeField}
                            error={error.packageUIDError}
                            helperText={
                              error.packageUIDError
                                ? " Package UID must be at least 12 characters long"
                                : ""
                            }
                            disabled={page == "view" || page == "update"}
                          />
                          <TextField
                            // id="standard-multiline-flexible"
                            placeholder="Enter your package title"
                            label="Package title"
                            multiline
                            name="title"
                            value={field.title}
                            maxRows={4}
                            variant="outlined"
                            sx={{ flex: 1 }}
                            onChange={handleChangeField}
                            error={error.titleError}
                            helperText={
                              error.titleError
                                ? "Title must be at least 3 characters long"
                                : ""
                            }
                            disabled={page == "view"}
                          />
                          <TextField
                            // id="standard-textarea"
                            label="Package Description"
                            placeholder="Enter your package Description"
                            multiline
                            name="description"
                            value={field?.description}
                            variant="outlined"
                            sx={{ flex: 1 }}
                            onChange={handleChangeField}
                            error={error.descriptionError}
                            helperText={
                              error.descriptionError
                                ? "Please enter description"
                                : ""
                            }
                            disabled={page == "view"}
                          />
                          <TextField
                            id="standard-multiline-flexible"
                            placeholder="Enter you allowWebsites"
                            label="Allow Websites"
                            type="number"
                            name="allowWebsites"
                            value={field.allowWebsites}
                            maxRows={4}
                            variant="outlined"
                            sx={{ flex: 1 }}
                            onChange={handleChangeField}
                            error={error.allowWebsitesError}
                            helperText={
                              error.allowWebsitesError
                                ? "Please enter how many websites you allow"
                                : ""
                            }
                            disabled={page == "view"}
                          />
                          <TextField
                            placeholder="Enter your monthly price"
                            label="Package monthly price"
                            type="number"
                            name="monthlyPrice"
                            value={prices.length ? prices[0].monthlyPrice : ''}
                            maxRows={4}
                            variant="outlined"
                            sx={{ flex: 1 }}
                            onChange={handleChangePrice}
                            error={error.monthPriceError}
                            helperText={
                              error.monthPriceError
                                ? "Monthly price must be a positive value."
                                : ""
                            }
                            disabled={page == "view"}
                          />
                          <TextField
                            label="Package yearly price"
                            placeholder="Enter your package yearly price"
                            type="number"
                            name="yearlyPrice"
                            value={prices.length ? prices[0].yearlyPrice : ''}
                            variant="outlined"
                            sx={{ flex: 1 }}
                            onChange={handleChangePrice}
                            error={error.yearPriceError}
                            helperText={
                              error.yearPriceError
                                ? "Year price must be a positive value and not less then monthly price"
                                : ""
                            }
                            disabled={page == "view"}
                          />
                          <TextField
                            label="packageOrder"
                            placeholder="Enter your packageOrder"
                            type="number"
                            name="packageOrder"
                            value={field?.packageOrder}
                            variant="outlined"
                            sx={{ flex: 1 }}
                            onChange={handleChangeField}
                            error={error.packageOrderError}
                            helperText={
                              error.packageOrderError
                                ? "Please enter packageOrder"
                                : ""
                            }
                            disabled={page == "view"}
                          />


                        </Box>
                        <Box className='d-flex w-100 gap-2 align-items-center flex-wrap' >
                          {image.map((imageUrl, index) => (
                            <div key={index} className="add-image-box position-relative"  >
                              <img src={imageUrl} alt={`Image ${index}`} className='rounded img-fluid' />
                              {page != "view" ?
                                <span className='position-absolute bg-danger text-white rounded top-0 end-0'
                                  onClick={() => handleRemoveImage(index, imageUrl)}
                                ><CloseIcon /></span> : ""}
                            </div>
                          ))}
                          <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageChangeArray}
                            multiple
                            ref={fileInputRef}
                            disabled={page == "view"}
                          />
                          {/* <Button className='common-black-btn' onClick={handleAddImage}  disabled={page == "view"} >Add Image</Button> */}
                        </Box>
                        <div className='text-center pt-4'>
                          {loading ?
                            <Button
                              className="common-black-btn"
                              variant="contained"
                              type='button'
                            >
                              <Box gap-3 sx={{ color: "white", width: "100px" }}>
                                <CircularProgress color="inherit" size={18} />
                              </Box>
                            </Button>
                            :
                            <Button
                              className="common-black-btn"
                              variant="contained"
                              type='subit'
                              disabled={
                                !field.packageuid ||
                                !field.title ||
                                !field.description ||
                                !field.allowWebsites ||
                                // image.length < 1 ||
                                error.packageUIDError ||
                                error.titleError ||
                                error.descriptionError ||
                                // error.websitesError ||
                                error.allowWebsitesError ||
                                error.monthPriceError ||
                                error.yearPriceError ||
                                error.packageOrderError ||
                                page == "view"
                              }
                            >
                              {page == "view" ? "View Manage Packages" : page == "update" ? "Update Manage Packages" : "Create Manage Packages"}
                            </Button>
                          }
                        </div>
                      </Box>
                    </form>

                  </div>
                </div>

              </Box>
            </div >
          </div >
        </div >
      </div >
    </div >
    <ToastContainer />
  </>
}

export default CreateManagePackages
