import React, { useEffect, useState } from 'react';

import { postData } from 'src/action/RestClient';

import { Paper, Tooltip, IconButton, Box, Typography, CircularProgress } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useLocation } from 'react-router';
import CommonTable from 'src/common/table/table';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';

const InvoiceDetailsList = () => {
    const location = useLocation();
    const userId = location?.state?.userId;
    const [invoiceDetails, setInvoiceDetails] = useState([]);

    const [pageOfLinesOfInvoice, setPageOfLinesOfInvoice] = useState(0); // Current page index of Lines of invoices
    const [pageOfTaxesOfInvoice, setPageOfTaxesOfInvoice] = useState(0); // Current page index of texes of invoices
    const [rowsPerPageOfLinesOfInvoice, setRowsPerPageOfLinesOfInvoice] = useState(10);
    const [rowsPerPageOfTaxesOfInvoice, setRowsPerPageOfTaxesOfInvoice] = useState(10);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handlePageChangeOfLinesOfInvoice = (event, newPage) => {
        setPageOfLinesOfInvoice(newPage);
    };
    const handlePageChangeOfTaxesOfInvoice = (event, newPage) => {
        setPageOfTaxesOfInvoice(newPage);
    };

    const handleRowsPerPageChangeOfLinesOfInvoice = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPageOfLinesOfInvoice(data);
        setPageOfLinesOfInvoice(0);
    };

    const handleRowsPerPageChangeOfTaxesOfInvoice = (newRowsPerPage) => {
        const data = parseInt(newRowsPerPage, 10);
        setRowsPerPageOfTaxesOfInvoice(data);
        setPageOfTaxesOfInvoice(0);
    };

    const postAPICalls = async () => {
        const url = '/subscription-invoices/by-user';
        const data = { id: userId };
        try {
          const response = await postData(url, data);
          if (response?.status) {
            setInvoiceDetails(response.data);
          }
        } catch (error) {
          console.error("Error fetching invoice details:", error);
        } finally {
          setLoading(false);
        }
      
        console.log("response", response);
      };

    useEffect(() => {
        postAPICalls();
    }, []);


    const renderActionsCell = (params) => {
        const user = params.row;
        console.log("user", user)
        return (
            <div>
                <Tooltip
                    title={`Invoice Details`}
                    arrow
                    onClick={() => invoiceHandler(user.invoiceId)}
                >
                    <IconButton color="inherit" size="small">
                        <PreviewOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };


    const invoiceHandler = (id) => {
        navigate('/users/invoiceDetails', { state: { userId: id } });
        // navigate('/users/invoiceDetailsList', { state: { userId: userId } });
    };

    const column1 = [
        { field: 'amount', label: 'Amount', width: 240, align: 'left' },
        { field: `item`, label: 'Item', width: 240, align: 'left' },
    ];
    const column2 = [
        { field: 'invoiceId', label: 'invoice Id', width: 240, align: 'left' },
        { field: 'totalAmount', label: 'Subtotal Amount', width: 240, align: 'left' },
        { field: 'totalTax', label: 'Tax', width: 240, align: 'left' },
        { field: 'totalAmountWithTax', label: 'total Amount ', width: 240, align: 'left', },
        { field: 'status', label: 'Action', width: 240, renderCell: renderActionsCell, align: 'left' },
    ];

    const loader = () => {
        return (
            <div className='w-100'>
                <Box className="align-items-center justify-content-center py-5 d-flex table-loader" style={{ minHeight: "auto" }}>
                    <CircularProgress />
                </Box>
            </div>
        );
    };

    return (
        <>
            <div
                className="content-page"
                style={{ fontFamily: 'sans-serif', fontSize: '15px' }}
            >
                <Paper>
                    <div className="d-flex">
                        <div className="col p-2">
                            <Tooltip
                                className="me-2"
                                title={`Back`}
                                arrow
                                onClick={() => { navigate(`/users/userSubscriptionDetail`, { state: { userId: invoiceDetails[0]?.memberId } }) }}
                            >
                                <IconButton color="inherit" size="small">
                                    <ArrowBackOutlinedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="col text-center p-2">
                            <Typography variant="h5" gutterBottom fontSize="medium">
                                Invoices 
                            </Typography>
                        </div>
                        <div className="col"></div>
                    </div>
                </Paper>
                {loading ? (
                    loader()
                ) : invoiceDetails?.length === 0 ? (
                    <div>
                        <div colSpan={10}>
                            <h4 className="align-items-center justify-content-center py-5 d-flex ">
                                {'No Records Found'}
                            </h4>
                        </div>
                    </div>
                ) : (
                    <div className="row mt-3">
                        <div className="col">
                            {' '}
                            <Paper>
                                <>
                                    <div className="iq-card position-relative rounded overflow-hidden cardAreaTable">
                                        <Box
                                            sx={{
                                                height: 400,
                                                width: '100%',
                                                background: 'white',
                                                paddingBottom: '41px',
                                            }}
                                            className="shadow-none"
                                        >
                                            <CommonTable
                                                data={invoiceDetails}
                                                columns={column2}
                                                //   enablePagination
                                                type={'taxesOfInvoice'}
                                                page={pageOfTaxesOfInvoice || 1}
                                                count={invoiceDetails?.taxesOfInvoice?.length || 1}
                                                rowsPerPage={rowsPerPageOfTaxesOfInvoice}
                                                onPageChange={handlePageChangeOfTaxesOfInvoice}
                                                onRowsPerPageChange={
                                                    handleRowsPerPageChangeOfTaxesOfInvoice
                                                }
                                            />
                                        </Box>
                                    </div>
                                </>
                            </Paper>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default InvoiceDetailsList;
