import React, { useEffect, useRef, useState } from 'react'
import { TextField, Button, Select, MenuItem, FormControl } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ToastContainer } from 'react-toastify'
import Box from "@mui/material/Box";
import { callApi, postApi, postData } from 'src/action/RestClient'
import { useNavigate } from 'react-router'
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { notify } from 'src/common/toastMesaage';
import CircularProgress from '@mui/material/CircularProgress';



const AssignFreeUserPackage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userLoading, setUserLoading] = useState(false);
    const [userData, setuserData] = useState(location?.state?.userData ? location?.state?.userData : "")
    const [custonSubscription, setCustonSubscription] = useState();
    const [field, setField] = useState({ description: "", allowWebsites: "", })
    const [subscriptiontype, setSubscriptiontype] = useState("create");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        allowWebsitesError: false,
        descriptionError: false,
    });
    const [packages, setPackages] = useState([]);
    const [selectedPackageId, setSelectedPackageId] = useState('');
    const [selectedPackageTitle, setSelectedPackageTitle] = useState('');
   
    const handleChange = (event) => {
        const selectedId = event.target.value;
        const selectedPackage = packages.find(pkg => pkg.packageUID === selectedId);
        setSelectedPackageId(selectedId);
        setSelectedPackageTitle(selectedPackage ? selectedPackage.title : '');
        setField({ description: "", allowWebsites: "", });
    };


    //fill inputfield function for error or field value
    const handleChangeField = (e) => {
        const { name, value } = e.target;
        setField({ ...field, [name]: value });
        // setError((prevError) => ({ ...prevError, websitesError: name === "allowWebsites" && value < 1 }));
        if (name === "allowWebsites") {
            setError((prevError) => ({
                ...prevError,
                allowWebsitesError: value < 1 || value <= Number(custonSubscription?.numberOfwebsites)
              }));
        }
        setError((prevError) => ({
            ...prevError,
            descriptionError:
                name === "description" &&
                value.length < 3,
        }));
    };


    //On form submit when create and update 
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let data = {
                userId: userData?._id,
                packageUID: selectedPackageId,
                description: field.description,
                numberOfwebsites: Number(field.allowWebsites),
            };
            const formData = new FormData(); // Create a new FormData object
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            if (subscriptiontype === "create") {
                const url = '/assign-custom/subscription';
                setLoading(true);
                const response = await postApi(url, formData);
                setLoading(false);
                if (response?.status) {
                    notify("Packages Assign Sucessfully", "success");
                    getCustomSubscription(userData?._id);
                    setField({ description: "", allowWebsites: "", });
                }
                else {
                    notify(response?.error, "error");
                }

            } else {
                const url = '/update-custom/subscription';
                setLoading(true);
                const response = await postApi(url, formData);
                setLoading(false);
                if (response?.status) {
                    notify("Packages Assign Sucessfully", "success");
                    getCustomSubscription(userData?._id);
                    setField({ description: "", allowWebsites: "", });
                }
                else {
                    notify(response?.error, "error");
                }

            }

        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    const getAllCustomData = async () => {
        setUserLoading(true)
        try {
            const response = await callApi('GET', '/get/packages/custom', null, null);
            const data = response?.data?.data;
            setPackages(data);
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };


    const getCustomSubscription = async (id) => {
        setUserLoading(true)
        try {
            const response = await callApi('GET', `/get-custom/subscription/${id}`, null, null);
            const data = response?.data;
            if (data?.status) {
                setCustonSubscription(data?.data);
                setField({ description: data?.data?.description,  allowWebsites: "",});
                setSelectedPackageId(data?.data?.packageUID)
                setSubscriptiontype("update");
            } else {
                setSubscriptiontype("create");
            }
            setUserLoading(false)
            return data;
        } catch (error) {
            setUserLoading(false)
            console.error('Error:', error);
            throw error;
        }
    };
    useEffect(() => {
        getAllCustomData();
    }, []);

    useEffect(() => {
        if (userData?._id) {
            getCustomSubscription(userData?._id);
        }
    }, [userData?._id]);

    return <>
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <section className="breadcrum-section" style={{ marginTop: '20px', marginLeft: '20px' }}>
                            <div role="presentation" style={{ marginTop: '20px' }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        onClick={() => navigate(-1)}
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        users
                                    </Link>
                                </Breadcrumbs>
                            </div>
                        </section>
                        <div className="iq-card position-relative rounded overflow-hidden">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title mb-0 fw-bold" style={{ fontSize: "1.1rem" }}>  {userData?.name} </h4>
                                </div>

                            </div>
                           
                            <Box sx={{ width: "100%", background: "white", padding: '30px 0' }}>
                                <div className="FormsAreaNew container">
                                    <div className="assignheader-title">
                                        <div className="iq-header-title">
                                            <h4 className="card-title mb-2 fw-bold" style={{ fontSize: "1.1rem" }}>  Name: {userData?.name} </h4>
                                        </div>
                                        <div className="iq-header-title">
                                            <h4 className="card-title mb-2 fw-bold" style={{ fontSize: "1.1rem" }}>  Email: {userData?.email} </h4>
                                        </div>
                                        <div className="iq-header-title">
                                            <h4 className="card-title mb-2 fw-bold" style={{ fontSize: "1.1rem" }}>  Number of allowed websites: {custonSubscription?.numberOfwebsites ? custonSubscription?.numberOfwebsites : "0"} </h4>
                                        </div>
                                        <div className="iq-header-title">
                                            <h4 className="card-title mb-2 fw-bold" style={{ fontSize: "1.1rem" }}>  Selecte Package: {custonSubscription?.title ? custonSubscription?.title : "None"} </h4>
                                        </div>
                                    </div>
                                    <div className="tab-content ">
                                        <form onSubmit={handleSubmit} >
                                            <Box className='create_package_outer'
                                                component="div"
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <Box className='form-field-box flex-wrap d-flex'>
                                                    <FormControl
                                                        className="package-select-filter"
                                                    >
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectedPackageId}
                                                            displayEmpty
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            onChange={handleChange}
                                                            className="py-0 outline-none rounded"
                                                        >
                                                            <MenuItem value="" disabled>
                                                                Select a package
                                                            </MenuItem>
                                                            {packages.map((pkg) => (
                                                                <MenuItem key={pkg._id} value={pkg.packageUID}>
                                                                    {pkg.title}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <TextField
                                                        // id="standard-textarea"
                                                        label="Package Description"
                                                        placeholder="Enter your package Description"
                                                        multiline
                                                        name="description"
                                                        value={field?.description}
                                                        variant="outlined"
                                                        sx={{ flex: 1 }}
                                                        onChange={handleChangeField}
                                                        error={error.descriptionError}
                                                        helperText={
                                                            error.descriptionError
                                                                ? "Please enter description"
                                                                : ""
                                                        }
                                                        disabled={!selectedPackageId}
                                                    />
                                                    <TextField
                                                        id="standard-multiline-flexible"
                                                        placeholder="Enter you allowWebsites"
                                                        label="Allow Websites"
                                                        type="number"
                                                        name="allowWebsites"
                                                        value={field.allowWebsites}
                                                        maxRows={4}
                                                        variant="outlined"
                                                        sx={{ flex: 1 }}
                                                        onChange={handleChangeField}
                                                        error={error.allowWebsitesError}
                                                        helperText={
                                                            error.allowWebsitesError
                                                                ? "Allowed Websites must be greater than 0 and greater than the number of allowed websites."
                                                                : ""
                                                        }
                                                        disabled={!selectedPackageId}
                                                    />

                                                </Box>

                                                <div className='text-center pt-4'>
                                                    {loading ?
                                                        <Button
                                                            className="common-black-btn"
                                                            variant="contained"
                                                            type='button'
                                                        >
                                                            <Box gap-3 sx={{ color: "white", width: "100px" }}>
                                                                <CircularProgress color="inherit" size={18} />
                                                            </Box>
                                                        </Button>
                                                        :
                                                        <Button
                                                            className="common-black-btn"
                                                            variant="contained"
                                                            type='subit'
                                                            disabled={
                                                                !field.description ||
                                                                !field.allowWebsites ||
                                                                error.descriptionError ||
                                                                error.allowWebsitesError
                                                            }
                                                        >
                                                            {subscriptiontype === "update" ? "Update package" : "Assign packages"}
                                                        </Button>
                                                    }
                                                </div>
                                            </Box>
                                        </form>

                                    </div>
                                </div>

                            </Box>
                        </div >
                    </div >
                </div >
            </div >
        </div >
        <ToastContainer />
    </>
}

export default AssignFreeUserPackage
