import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles =({
  success: {
    backgroundColor: 'red',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'red',
      color: '#fff',
  },
},
error: {
  backgroundColor: '#4CBB17		',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#4CBB17',
    color: '#fff',
},
}
})

export default function DeleteDialogue({title,openDialogue,setopenDialogue,setAnswer,apiUrl,id}) {
  const token = localStorage.getItem('token') || '';
    const config = {
      headers: {
        'accept': '*/*',
        'Auth-Token': `${token}`,
      },
    };
    const handleClose = (response) => {
      if(response == true){
        
          axios.delete(`${apiUrl}${id}`, config).then((res) => {
            console.log("Data Deleted Successfully", res.data);
            setAnswer(response)
          }).catch((error) => {
            console.log("Error occurred in deleting the data", error);
            setAnswer(response)
          })
        }
      else{
        setAnswer(response)
      }
      setopenDialogue(false);
    };
  return (
    <div>
      <Dialog
        open={openDialogue}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to delete this ${title} ?`}
        </DialogTitle>
        <DialogActions>
        <Button sx={useStyles.error} onClick={()=>handleClose(false)}>No</Button>
        <Button  sx={useStyles.success} onClick={()=>handleClose(true)}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
